import React from 'react'

import AddressList from '@/components/addressList/index'

import Api from '@/common/api/index'

function InvoiceAddress () {
  const getInvoiceReceivingAddressList = (params) => {
    return Api.getInvoiceReceivingAddressList(params)
  }

  const saveAddInvoiceReceivingAddress = (params) => {
    return Api.saveAddInvoiceReceivingAddress(params)
  }

  const saveEditInvoiceReceivingAddress = (params) => {
    return Api.saveEditInvoiceReceivingAddress(params)
  }

  const deleteInvoiceReceivingAddress = (params) => {
    return Api.deleteInvoiceReceivingAddress(params)
  }

  return (
    <>
      <AddressList
        getList={(params) => getInvoiceReceivingAddressList(params)}
        onSaveAddress={(params) => saveAddInvoiceReceivingAddress(params)}
        onEditAddress={(params) => saveEditInvoiceReceivingAddress(params)}
        onDeleteAddress={(params) => deleteInvoiceReceivingAddress(params)}
      />
    </>
  )
}

export default InvoiceAddress

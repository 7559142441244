import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { setUserInfo } from '@/reducers/action'
import { Modal } from 'antd'

import AccountView from './accountView/index'
import SettingsForm from './settingsForm/index'

import Api from '@/common/api/index'
import { removeStore } from '@/common/utils/mUtils'

function AccountSettings (props) {
  const userInfo = useSelector(state => state.userInfo)
  const dispatch = useDispatch()
  const [step, setStep] = useState(0)

  // 保存修改密码
  const saveUpdatePassword = async (params) => {
    const res = await Api.saveUpdatePassword(params)
    const { code } = res
    if (+code === 10000) {
      Modal.success({
        title: '操作成功',
        content: '请重新登录',
        onOk: () => {
          handleLoginOut()
        }
      })
    }
  }

  // 发送短信验证码
  const sendSmsCaptchaAndValidate = (params) => {
    return Api.sendSmsCaptchaAndValidate(params)
  }

  const handleLoginOut = () => {
    dispatch(setUserInfo({ userInfo: {} }))
    removeStore('token')
    props.history.push({ pathname: '/login' })
  }

  return (
    <>
      {
        step === 0
          ? <AccountView
            userInfo={userInfo}
            onModifyPassword={() => setStep(1)}/>
          : null
      }

      {
        step === 1
          ? <SettingsForm
            userInfo={userInfo}
            onSendSms={(params) => sendSmsCaptchaAndValidate(params)}
            onConfirm={(params) => saveUpdatePassword(params)}
            onCancel={() => setStep(0)}
          />
          : null
      }

    </>
  )
}

AccountSettings.propTypes = {
  history: PropTypes.object
}

export default AccountSettings

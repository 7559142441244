import React, { useState, useEffect } from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import InvoiceModal from './invoiceModal/index'

import Api from '@/common/api/index'
import { renderInvoiceOpenedStatusLabel } from '@/common/utils/mUtils'
import { pageSizeOptions } from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts
const searchData = [
  {
    name: 'id',
    formType: 'Input',
    itemParams: {
      label: '开票申请编号'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off'
    }
  },
  {
    name: 'orderId',
    formType: 'Input',
    itemParams: {
      label: '订单编号'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off'
    }
  },
  {
    name: 'times',
    formType: 'RangePicker',
    itemParams: {
      label: '订单日期'
    },
    cptParams: {

    }
  }
]

export default function UnopenTable () {
  const [query, setSearchQuery] = useState({})
  const [pages, setPages] = useState({ total: 0, pageNo: 1, pageSize: 20 })
  const [tableData, setTableData] = useState([])
  const [invoiceModalDetails, setInvoiceModalDetails] = useState({ invoiceModal: false, id: null })

  // 获取未开票的订单列表
  const getPurchaseOrderInvoiceOpeningList = async () => {
    const res = await Api.getPurchaseOrderInvoiceOpeningList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data.list || [])
      setPages({ ...pages, total: data.total || 0 })
    }
  }

  const columns = [
    {
      title: '序号',
      key: 'unopenCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '开票申请编号',
      key: 'unopenCol-1',
      dataIndex: 'id'
    },
    {
      title: '订单编号',
      key: 'unopenCol-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单日期',
      key: 'unopenCol-3',
      dataIndex: 'orderDate',
      render: (text, record, index) => (
        <>{ record.orderDate ? moment(record.orderDate).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '开票金额',
      key: 'unopenCol-4',
      dataIndex: 'invoiceAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.invoiceAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '开票状态',
      key: 'unopenCol-5',
      dataIndex: 'invoiceOrderStatus',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.invoiceOrderStatus) }</>
      )
    },
    {
      title: '操作',
      key: 'unopenCol-6',
      render: (text, record, index) => (
        <Button type='link' size='small' onClick={() => setInvoiceModalDetails({ invoiceModal: true, id: record.id })}>查看详情</Button>
      )
    }
  ]

  // 点击搜索
  const handleSearch = (search = {}) => {
    if (search.times) {
      search.orderBeginDate = search.times.length === 2 ? search.times[0].format('YYYY-MM-DD') : undefined
      search.orderEndDate = search.times.length === 2 ? search.times[1].format('YYYY-MM-DD') : undefined
      delete search.times
    }

    setSearchQuery(search)
    setPages({ ...pages, pageNo: 1 })
  }

  const pageChange = (page, pageSize) => {
    setPages({ ...pages, pageNo: page })
  }

  const pageSizeChange = (current, size) => {
    setPages({ ...pages, pageNo: 1, pageSize: size })
  }

  useEffect(() => {
    getPurchaseOrderInvoiceOpeningList()
  }, [query, pages.pageNo, pages.pageSize])

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: pages.total,
    showTotal: (total) => { return `共 ${total} 条` },
    current: pages.pageNo,
    pageSize: pages.pageSize,
    pageSizeOptions: pageSizeOptions,
    onChange: pageChange,
    onShowSizeChange: pageSizeChange
  }

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <SearchForm dataSource={searchData} handleSubmit={(obj) => handleSearch(obj)} />
      </div>

      <Table rowKey='id' bordered
        columns={columns}
        dataSource={tableData}
        rowClassName={(record, index) => record.invoiceSource === 'AFTERSALE_INVOICE' ? 'redColor' : null }
        pagination={pagination}
      />

      {
        invoiceModalDetails.invoiceModal
          ? <InvoiceModal
            ids={[invoiceModalDetails.id]}
            onCancel={() => setInvoiceModalDetails({ invoiceModal: false, id: null })}
          />
          : null
      }
    </>
  )
}

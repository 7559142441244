import Ajax from '../utils/ajax'

export default {
  // 客户信息模糊查询
  getGuesteuUserLike (params) {
    return Ajax.post('/api/guest/list/page/euUser/like', params)
  },

  // 获取客户列表
  getGuestListBySeller (params) {
    return Ajax.post('/api/guest/supplier/endUser/guestListBySeller', params)
  },

  // 保存新增客户
  saveAddEnduser (params) {
    return Ajax.post('/api/guest/supplier/guest/euAdd', params)
  },

  // 保存客户编辑
  saveEditEnduser (params) {
    return Ajax.post('/api/guest/supplier/guest/euEdit', params)
  },

  // 保存客户导入
  saveBatchUploadEndUsers (params) {
    return Ajax.post('/api/guest/supplier/endUser/import', params)
  }
}

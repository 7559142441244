import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button, Icon } from 'antd'
import moment from 'moment'

import DetailsModal from '../detailsModal/index'

import { getCourierName } from '@/common/utils/mUtils'
import { pageSizeOptions } from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

function BillDetails (props) {
  const { pages, tableData = [], pageChange, pageSizeChange, onDownload } = props
  const [detailsObj, setDetailsObj] = useState({ visiable: false, detailInfo: {} })

  const columns = [
    {
      title: '序号',
      key: 'detailList-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '订单编号',
      key: 'detailList-1',
      dataIndex: 'orderId'
    },
    {
      title: '发货日期',
      key: 'detailList-2',
      width: 150,
      render: (text, record, index) => (
        <>
          { record.senderTime ? moment(record.senderTime).format('YYYY-MM-DD HH:mm:ss') : null }
        </>
      )
    },
    {
      title: '收货日期',
      key: 'detailList-3',
      width: 150,
      render: (text, record, index) => (
        <>
          { record.signedStatus === 'SIGNED' && record.receiverTime ? moment(record.receiverTime).format('YYYY-MM-DD HH:mm:ss') : null }
        </>
      )
    },
    {
      title: '运单号',
      key: 'detailList-4',
      dataIndex: 'mailNoList',
      render: (text, record, index) => (
        <>{ record.mailNoList && record.mailNoList.length > 0 ? record.mailNoList.join(',') : '----'}</>
      )
    },
    {
      title: '发货地址',
      key: 'detailList-5',
      ellipsis: true,
      width: 160,
      render: (text, record, index) => (
        <span>{record.senderProvince}&nbsp;{record.senderCity}&nbsp;{record.senderCounty}&nbsp;{record.senderAddress}</span>
      )
    },
    {
      title: '收货地址',
      key: 'detailList-6',
      ellipsis: true,
      width: 150,
      render: (text, record, index) => (
        <span>
          {record.receiverProvince}&nbsp;{record.receiverCity}&nbsp;{record.receiverCounty}&nbsp;{record.receiverAddress}
        </span>
      )
    },
    {
      title: '快递公司',
      key: 'detailList-7',
      render: (text, record, index) => (
        <>{ getCourierName(record.courierCode) }</>
      )
    },
    {
      title: '发货人',
      key: 'detailList-8',
      dataIndex: 'senderName'
    },
    {
      title: '收货人',
      key: 'detailList-9',
      dataIndex: 'receiverName'
    },
    {
      title: '货物名称',
      key: 'detailList-10',
      width: 80,
      render: (text, record, index) => (
        <a onClick={() => setDetailsObj({ visiable: true, detailInfo: record })}>工业品</a>
      )
    },
    {
      title: '件数',
      key: 'detailList-11',
      dataIndex: 'totalNumber'
    },
    {
      title: '重量(kg)',
      key: 'detailList-12',
      dataIndex: 'totalWeight'
    },
    {
      title: <>体积m<sup>3</sup></>,
      key: 'detailList-13',
      dataIndex: 'totalVolume'
    },
    {
      title: '运费金额',

      key: 'detailList-14',
      render: (text, record, index) => (
        <>&yen;{((record.totalPrice * 100 - record.transportPrice * 100 + record.merchantTransportPrice * 100) / 100).toFixed(2)}</>
      )
    }
  ]

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: pages.total,
    showTotal: (total) => { return `共 ${total} 条` },
    current: pages.pageNo,
    pageSize: pages.pageSize,
    pageSizeOptions: pageSizeOptions,
    onChange: pageChange,
    onShowSizeChange: pageSizeChange
  }

  return (
    <>
      <Card title='账单明细' headStyle={{ padding: '0px' }} bodyStyle={{ padding: '0px' }} bordered={false}
        extra={<Button type='primary' onClick={() => onDownload()}><Icon type='download' />月度明细</Button>}
      >
        <Table rowKey={(record, index) => { return `billDetailFlow-${record.orderId}-${index}` }} bordered
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
          scroll={{ x: 1200 }}
        />
      </Card>

      {
        detailsObj.visiable
          ? <DetailsModal
            detailInfo={detailsObj.detailInfo}
            onCancel={() => setDetailsObj({ visiable: false, detailInfo: {} })}
          />
          : null
      }
    </>
  )
}

BillDetails.propTypes = {
  pages: PropTypes.object,
  tableData: PropTypes.array,
  pageChange: PropTypes.func,
  pageSizeChange: PropTypes.func,
  onDownload: PropTypes.func
}

export default BillDetails

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Upload, message, Checkbox } from 'antd'
import $lodash from 'lodash'

import UploadApi from '@/common/api/uploadApi'
import { getStore, repLineBreak } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const { uploadManualSearchSku } = UploadApi
const FormItem = Form.Item
const { TextArea } = Input
const sourceMap = [
  { value: 'needCloudSpot', label: '云现货' },
  { value: 'needCloudFutures', label: '云期货' }
]

function Search (props) {
  const { form, str = '', handleChangeUploadSkuList, handleDownloadManualTemp, handleDisimg, onSearch } = props
  const { getFieldDecorator } = form

  useEffect(() => {
    props.form.setFieldsValue({
      keyword: str
    })
  }, [str])

  // 快速下单
  const hanldeGetSearch = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        const { source = [], keyword = '' } = values
        if (keyword === '') {
          return message.warning('请输入要搜索的型号或订货号')
        }

        const search = repLineBreak(keyword, '<br>').split('<br>')
        const unitList = $lodash.map(search, (item) => {
          if (item !== '') {
            const arr = item.split('==')
            return {
              itemNumberModel: arr.length > 0 ? arr[0] : '',
              quantity: arr[1] && +arr[1] > 0 ? +arr[1] : 1
            }
          }
        })
        const params = {
          unitList: unitList
        }

        $lodash.forEach(source, (key, values) => { params[key] = true })

        onSearch(params)
      }
    })
  }

  // 设置渠道参数
  const setUploadParams = (selectedSourceKeys) => {
    const obj = {}
    $lodash.forEach(selectedSourceKeys, (key) => {
      obj[key] = true
    })

    return obj
  }

  return (
    <div style={{ marginBottom: '24px' }}>
      <Form>
        <div className={`${styles.formitemRow}`}>
            <FormItem label='您需要哪种渠道' style={{ marginBottom: '2px' }}>
              {
                getFieldDecorator('source', {
                  initialValue: ['needCloudSpot'],
                  rules: [
                    { required: true, message: '请至少选择一种渠道' }
                  ]
                })(
                  <Checkbox.Group options={sourceMap} />
                )
              }
            </FormItem>
        </div>
        <FormItem style={{ marginBottom: '0px' }}>
          {
            getFieldDecorator('keyword', {
              rules: [
                { required: true, message: '请输入型号或订货号' }
              ]
            })(
              <TextArea className={`${styles.searchInput}`} autoComplete='off' rows={4}
                placeholder="请输入型号或订货号，并且使用双等号将数量隔开...例如：&#10;RXM2CB2BD==10&#10;10000498==5"
              />
            )
          }
        </FormItem>

        <FormItem style={{ marginTop: '12px', textAlign: 'right' }}>
          <Button onClick={handleDownloadManualTemp}>下载模板</Button>
          <Upload name='file' showUploadList={false}
            headers={{
              Token: getStore('token')
            }}
            data={form.getFieldValue('source') !== undefined ? setUploadParams(form.getFieldValue('source')) : {}}
            accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            action={uploadManualSearchSku}
            onChange={handleChangeUploadSkuList}
          >
            <Button style={{ marginLeft: '12px' }}>上传BOM单</Button>
          </Upload>
          <Button style={{ marginLeft: '12px' }} onClick={handleDisimg}>图文转换</Button>
          <Button type='primary' style={{ marginLeft: '12px' }} onClick={hanldeGetSearch}>快速下单</Button>
        </FormItem>
      </Form>
    </div>
  )
}

Search.propTypes =
{
  form: PropTypes.object,
  handleChangeUploadSkuList: PropTypes.func,
  handleDownloadManualTemp: PropTypes.func,
  handleDisimg: PropTypes.func,
  onSearch: PropTypes.func,
  str: PropTypes.string
}

const SearchForm = Form.create()(Search)
export default SearchForm

import { downloadFileByGet, downloadFileByPost } from '../utils/downloadFile'

export default {
  // 下载-下载通用模板
  downloadCommonTemplate (params) {
    return downloadFileByGet('/api/excel/template/download/common', params)
  },

  // 下载-客户导入模板
  downloadUploadEndUserTemplate (params) {
    return downloadFileByGet('/api/excel/download/endUserPurchaserImportTemplate', params)
  },

  // 下载-客户导入失败详情
  downloadUploadCustomerFailDetails (params) {
    return downloadFileByPost('/api/guest/supplier/endUser/export', params)
  },

  // 导出IIASaaS的德邦快递服务月度账单
  downIIASaaSDepponExpressBillByMonth (params) {
    return downloadFileByGet('/api/excel/data/download/logistics/deppon/fee', params)
  },

  // 快递记录导出
  downloadExpressServiceRecords (params) {
    return downloadFileByPost('/api/warehouse/get/distributorPickingInfo/export', params)
  },
  // 导出客户列表
  downloadEndUserList (params) {
    return downloadFileByPost('/api/excel/export/euUserInfo', params)
  },
  // 手工订单-下载导入模板
  downManualTemp (params) {
    return downloadFileByGet('/api/excel/download/handOrderTemplate', params)
  },
  // 购物车列表 -导出报价单
  downCartExport (params) {
    return downloadFileByPost('/api/just/purchase/shopping/cart/quotation/export', params)
  },

  // 询价单- 我的询价 > 导出询价单EXCEL
  downloadEnquiryOrderExcel (params) {
    return downloadFileByPost('/enquiry/order/manage/center/exportDetailData', params)
  }
}

/* 询价模块 */
import CreateEnquireOrder from '@/pages/enquireManage/createEnquireOrder/index'
import EnquireProcess from '@/pages/enquireManage/enquireProcess/index'
import EnquireOrderList from '@/pages/enquireManage/enquireOrderList/index'

export default [
  {
    path: '/enquire/createEnquireOrder',
    name: '询价提交',
    component: CreateEnquireOrder
  },
  {
    path: '/enquire/enquireProcess',
    name: '询价过程',
    component: EnquireProcess
  },
  {
    path: '/enquire/enquireOrderList',
    name: '我的询价',
    component: EnquireOrderList
  }
]

import React, { } from 'react'
import { Card } from 'antd'
import PropTypes from 'prop-types'

function InviceHeader (props) {
  const { bankInfo = {} } = props
  return (
    <div style={{ marginTop: '24px' }}>
      <Card title='发票抬头' headStyle={{ padding: '0px', borderBottom: 'none' }} bodyStyle={{ padding: '0px' }} bordered={false}>
        <p>增值税专用发票（企业）抬头：{bankInfo.merchantName || ''}</p>
        <p>纳税人识别号：{bankInfo.merchantCreditNo || ''}</p>
        <p>地址/电话：{bankInfo.address || ''}</p>
        <p>开户行及账号：{bankInfo.accountBank ? `${bankInfo.accountBank} ${bankInfo.account}` : ''}</p>
      </Card>
    </div>
  )
}
InviceHeader.propTypes = {
  bankInfo: PropTypes.object
}

export default InviceHeader

import React, { } from 'react'
import { Checkbox, InputNumber, Empty } from 'antd'
import ErrorRow from './../errorRow/index'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import $lodash from 'lodash'

import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

function TableList (props) {
  const { disabled, FootCkBox, tableList = [], FootCheckBox, handleGoodsCkB, supplyCheck, handlePonok, handleInValue, handleDelList } = props

  return (
    <div style={{ marginBottom: '24px' }}>
      <table className={styles.tabs} width="100%" >
        <thead>
          <tr className={styles.tabsthead}>
            <td className={`${styles.LtCK}`}><Checkbox disabled={disabled} checked={FootCkBox } onChange={(e) => { FootCheckBox(e) }} /></td>
            <td className={`${styles.tGoodsinfo}`}>商品信息</td>
            <td className={`${styles.tCK}`}>产品类型</td>
            <td className={`${styles.tGoodsprice}`}>单价</td>
            <td className={`${styles.tGoodsnum}`}>数量</td>
            <td className={`${styles.tGoodsmoney}`}>单品小计</td>
            <td className={`${styles.tGoodsOp}`}>操作</td>
          </tr>
        </thead>

        {
          tableList && tableList.length > 0
            ? <tbody>
              {
                tableList.map((item, i) => (
                  <tr key={i}>
                    <td className={`${styles.LtCK}`}>
                      {
                        item.quickOrderSearchResult === 'SUCCESS'
                          ? <Checkbox checked={item.checked} onChange={(e) => { handleGoodsCkB(e, i) }}/>
                          : null
                      }
                    </td>

                    <td className={`${styles.Lgoodsinfo}`}>
                      {
                        item.quickOrderSearchResult === 'SUCCESS'
                          ? <Link className={`${styles.linkText}`} to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${item.commoditySkuId}&skuGoodsType=${item.skuGoodsType}` }} target='_blank'>
                              <div className={`${styles.goodsBaseItem}`}>
                                <div className={`${styles.imgBox}`}>
                                  { item.imageUrl && item.imageUrl !== '' ? <img className={`${styles.pic}`} src={ item.imageUrl }/> : null }
                                </div>
                                <div>
                                  <span className={styles.borderstyles}>{ item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}` : item.brandName }</span>
                                  <div className={styles.ttitle}>{item.name}</div>

                                  <div className={styles.tfont}>型号：{item.model}</div>
                                  <div className={styles.tfont}>订货号：{item.itemNumber}</div>
                                </div>
                              </div>
                            </Link>
                          : null
                      }
                    </td>

                    <td className={`${styles.tdminH}`} style={{ textAlign: 'center' }}>
                      {
                        item.quickOrderSearchResult === 'SUCCESS'
                          ? <span className={`${styles.bstylesW} ${item.skuGoodsType === 'CLOUD_SPOT' ? styles.borderstyles : styles.borderstylesRed}`}>
                              { renderSkuGoodsTypeLabel(item.skuGoodsType) }
                             </span>
                          : null
                      }
                    </td>

                    <td className={`${styles.Lgoodsprice}`}>
                      {
                        item.quickOrderSearchResult === 'SUCCESS'
                          ? <>
                              {
                                item.supplyInfoList && item.supplyInfoList.map((itemm, ii) => (
                                  <div className={styles.supplyList} key={ii}>
                                    <span><Checkbox checked={itemm.checked} onChange={(e) => { supplyCheck(e, ii, i) }} /></span>
                                    <span className={styles.supplyListprice}>
                                      { +itemm.price > 0 ? <>&yen;{+itemm.price}</> : '面议' }
                                    </span>

                                    <span className={styles.supplyListstock}>
                                      { item.skuGoodsType === 'CLOUD_SPOT' ? `库存：${itemm.stock}` : `货期：${itemm.deliveryPeriodDays}工作日` }
                                    </span>
                                  </div>
                                ))
                              }
                            </>
                          : <ErrorRow
                              detail={item}
                              list={tableList}
                              i={i}
                              searchResult={item.quickOrderSearchResult}
                              onoK={(list) => { handlePonok(list) }}
                            />
                      }
                    </td>

                    <td className={`${styles.Lgoodsnum}`}>
                      {
                        item.quickOrderSearchResult === 'SUCCESS'
                          ? <>
                              {
                                item.supplyInfoList && item.supplyInfoList.map((itemm, ii) => (
                                  <div className={styles.supplyNum} key={ii}>
                                    {
                                      itemm.checked === true
                                        ? <>
                                          <InputNumber min={1} value={itemm.quantity} onChange={(value) => { handleInValue(value, ii, i) }} size='small' />
                                          {
                                          item.skuGoodsType === 'CLOUD_SPOT' && itemm.quantity > itemm.stock
                                            ? <span className={styles.stockErr}>库存不足</span>
                                            : null
                                          }

                                          {
                                            +itemm.quantity < +itemm.minOrder
                                              ? <span className={styles.stockErr}>最少购买{itemm.minOrder}</span>
                                              : null
                                          }
                                        </>
                                        : null
                                    }
                                  </div>
                                ))
                              }
                          </>
                          : null
                      }
                    </td>

                    <td className={`${styles.Lgoodsmoney}`}>
                      {
                        item.quickOrderSearchResult === 'SUCCESS'
                          ? <>
                            {
                              item.supplyInfoList && item.supplyInfoList.map((itemm, ii) => (
                                <div className={styles.supplyNum} key={ii}>
                                  { itemm.checked === true ? <>&yen;{$lodash.round(+itemm.quantity * +itemm.price * 100 / 100, 2)}</> : null }
                                </div>
                              ))
                            }
                            </>
                          : null
                      }
                    </td>

                    <td className={`${styles.LgoodsOp}`}>
                      {
                        item.quickOrderSearchResult === 'SUCCESS'
                          ? <div>
                            <Link to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${item.commoditySkuId}&skuGoodsType=${item.skuGoodsType}` }} target='_blank' style={{ marginLeft: '8px' }}>
                              产品详情
                            </Link>
                          </div>
                          : null
                      }

                      <a style={{ marginLeft: '10px' }} onClick={() => { handleDelList(i) }}>删除</a>
                    </td>
                  </tr>
                ))
              }
            </tbody>
            : <tbody>
                <tr>
                  <td className={styles.epmtd} colSpan={7}><Empty /></td>
                </tr>
              </tbody>
        }
      </table>
    </div>
  )
}

TableList.propTypes = {
  disabled: PropTypes.bool,
  FootCkBox: PropTypes.bool,
  tableList: PropTypes.array,
  FootCheckBox: PropTypes.func,
  handleGoodsCkB: PropTypes.func,
  supplyCheck: PropTypes.func,
  handlePonok: PropTypes.func,
  handleInValue: PropTypes.func,
  handleDelList: PropTypes.func
}

export default TableList

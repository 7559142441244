/* 常量集合 */
export default {
  SERIALWIDTH: 60, // 列表序号宽度
  ADDRESS_MAX_NUM: 10, // 添加地址最大数
  IMG_LT2M: 2, // 上传图片最大尺寸限制
  MAX_NUMBER: 2147483647, // 数字类型最大值
  MAX_PAGESIZE: 999, // 页数最大值
  REMARKS_MAX_NUM: 200, // 备注最大字数
  ENQUIRE_TYPE: 'MERCHANT' // 询价提交标识
}

import EnquireMatchSheet from '@/pages/public/enquireMatchSheet/index'
import EnquireMatchReport from '@/pages/public/enquireMatchReport/index'
import EnquireMatchDetails from '@/pages/public/enquireMatchDetails/index'
import SheetConfirm from '@/pages/public/sheetConfirm/index'

export default [
  {
    path: '/enquire/enquireMatchSheet',
    name: 'EXCEL确认',
    component: EnquireMatchSheet
  },
  {
    path: '/enquire/enquireMatchReport',
    name: '匹配结果简报',
    component: EnquireMatchReport
  },
  {
    path: '/enquire/enquireMatchDetails',
    name: '匹配结果详情',
    component: EnquireMatchDetails
  },
  {
    path: '/match/sheetConfirm',
    name: 'EXCEL确认',
    component: SheetConfirm
  }
]

import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import PropTypes from 'prop-types'
import Api from '@/common/api/index'
import LogisticsInfo from '@/components/logisticsTrail/index'
import styles from './logistics.module.scss'

export default class logisticsmodal extends Component {
  static propTypes ={
    onCancel: PropTypes.func,
    detailInfo: PropTypes.object
  }

  state={
    type: 0,
    logisticsTraces: [],
    logisticsOrderNo: '0'// 运单号
  }

  componentDidMount () {
    const { type } = this.state
    const { detailInfo } = this.props
    if (detailInfo && detailInfo.courierNumberList) {
      const logisticsOrderNo = detailInfo && detailInfo.courierNumberList ? detailInfo.courierNumberList[0] : ''
      this.handleChoose(logisticsOrderNo, type)
    }
  }

  // 选择
  handleChoose = (item, i) => {
    this.setState({
      type: i,
      logisticsOrderNo: item
    }, () => {
      this.getLogisticsTraceByMailNo()
    })
  }

  // 获取物流轨迹
  getLogisticsTraceByMailNo = async () => {
    const { detailInfo = {} } = this.props
    const { logisticsOrderNo } = this.state
    const params = {
      ShipperCode: detailInfo.courierCode,
      LogisticCode: logisticsOrderNo,
      CustomerName: detailInfo.mobile ? detailInfo.mobile.substr(-4) : ''
    }

    const res = await Api.getLogisticsTraceByMailNo(params)
    const { code, data } = res
    if (+code === 10000) {
      const _list = data.traces || []

      this.setState({
        logisticsTraces: _list.reverse()
      })
    }
  }

  render () {
    const { detailInfo, onCancel } = this.props
    const { type, logisticsTraces = [] } = this.state

    return (
      <Modal title="物流轨迹" width={1000} bodyStyle={{ padding: '16px' }}
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        footer={[
          <Button key='cancelBtn-0' onClick={() => onCancel()}>关闭</Button>
        ]}
      >
        {
          detailInfo && detailInfo.courierNumberList
            ? <div className={styles.main}>
              {
                detailInfo.courierNumberList.map((item, i) => (
                  <Button className={styles.btn} onClick={() => { this.handleChoose(item, i) }} type={type === i ? 'primary' : 'default'} key={i}>{`运单号${item}`}</Button>
                ))
              }
              </div>
            : null
        }

        <div style={{ marginTop: '16px' }}>
          <LogisticsInfo
            courierCode={detailInfo.courierCode}
            logisticsTraces={logisticsTraces}
          />
        </div>
      </Modal>
    )
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import moment from 'moment'

import { enquireMatchStatusMaps } from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

export default class QuotationPreviewTable extends Component {
  static propTypes = {
    title: PropTypes.object,
    descriptions: PropTypes.string,
    list: PropTypes.array
  }

  state = {}
  columns = [
    {
      title: '序号',
      key: 'quotationTableCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'quotationTableCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'quotationTableCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'quotationTableCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'quotationTableCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'quotationTableCol-5',
      width: 70,
      dataIndex: 'unit'
    },
    {
      title: '期望价格',
      key: 'quotationTableCol-6',
      dataIndex: 'expectPrice',
      render: (text, record, index) => (
        <>&yen;{+record.expectPrice}</>
      )
    },
    {
      title: '实际价格',
      key: 'quotationTableCol-7',
      render: (text, record, index) => (
        <>
          <span>&yen;{ +record.realPrice }</span>
          { this.renderContrastIconEl(+record.expectPrice, +record.realPrice) }
        </>
      )
    },
    {
      title: '期望数量',
      key: 'quotationTableCol-8',
      dataIndex: 'expectQuantity'
    },
    {
      title: '实际数量',
      key: 'quotationTableCol-9',
      dataIndex: 'realQuantity',
      render: (text, record, index) => (
        <>
          <span>{ +record.realQuantity }</span>
          { this.renderContrastIconEl(+record.expectQuantity, +record.realQuantity) }
        </>
      )
    },
    {
      title: '期望货期',
      key: 'quotationTableCol-10',
      width: 70,
      dataIndex: 'expectDeliveryPeriodDays',
      render: (text, record, index) => (
        <>{ +record.expectDeliveryPeriodDays === 0 ? '现货' : `${+record.expectDeliveryPeriodDays}工作日` }</>
      )
    },
    {
      title: '实际货期',
      key: 'quotationTableCol-11',
      width: 70,
      render: (text, record, index) => (
        <>
          <span>{ +record.realDeliveryPeriodDays === 0 ? '现货' : `${+record.realDeliveryPeriodDays}工作日` }</span>
          { this.renderContrastIconEl(+record.expectDeliveryPeriodDays, +record.realDeliveryPeriodDays) }
        </>
      )
    },
    {
      title: '是否承担运费',
      key: 'quotationTableCol-12',
      width: 90,
      render: (text, record, index) => (
        <>{ record.isUndertakeFreight === 'UNDERTAKE' ? '是' : '否' }</>
      )
    },
    {
      title: '报价有限期',
      key: 'quotationTableCol-13',
      render: (text, record, index) => (
        <>{ record.expiryDateTime ? moment(record.expiryDateTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '数据类型',
      key: 'quotationTableCol-14',
      render: (text, record, index) => (
        <>{ this.renderMatchingLabel(record.matchingState) }</>
      )
    },
    {
      title: '报价结果',
      key: 'quotationTableCol-15',
      width: 80,
      render: (text, record, index) => (
        <>{ record.collaborateState === 'HAS_QUOTATION' ? '已寻到' : '未寻到'}</>
      )
    }
  ]

  // 渲染数据类型名称
  renderMatchingLabel = (status) => {
    const obj = enquireMatchStatusMaps.find((o) => { return o.id === status })
    const label = obj !== undefined ? obj.name : ''

    return label
  }

  // 渲染图标
  renderContrastIconEl = (target, source) => {
    let el = null
    const val = source - target

    if (val > 0) {
      el = <Icon type='arrow-up' style={{ marginLeft: '4px', color: '#FF4B4A' }} />
    } else if (val === 0) {
      el = <Icon type='bulb' style={{ marginLeft: '4px', color: '#FF7900' }} />
    } else if (val < 0) {
      el = <Icon type='arrow-down' style={{ marginLeft: '4px', color: '#21BB7E' }} />
    }

    return el
  }

  render () {
    const { title, descriptions, list = [] } = this.props

    return (
      <>
        <h3>{ title || '报价单预览' }</h3>
        <p>{ descriptions }</p>
        <Table rowKey={(record, index) => { return record.enquiryDetailId || `quotationRow-${record.id}` }} bordered
          columns={this.columns}
          dataSource={list}
          pagination={false}
          scroll={{ x: 1800 }}
        />
      </>
    )
  }
}

import React, { useState, useEffect } from 'react'
import { Table, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import RecordsModal from './recordsModal/index'
import LogisticsModal from '@/pages/purchase/components/logistics-modal'

import Api from '@/common/api/index'
import { renderInvoiceTypeLabel, renderInvoiceOpenedStatusLabel } from '@/common/utils/mUtils'
import { pageSizeOptions, invoiceTypeMaps } from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts
const searchData = [
  {
    name: 'id',
    formType: 'Input',
    itemParams: {
      label: '开票申请编号'
    },
    cptParams: {
      placeholder: '请输入...'
    }
  },
  {
    name: 'orderId',
    formType: 'Input',
    itemParams: {
      label: '订单编号'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off'
    }
  },
  {
    name: 'orderTimes',
    formType: 'RangePicker',
    itemParams: {
      label: '订单日期'
    },
    cptParams: {

    }
  },
  {
    name: 'invoiceType',
    formType: 'Select',
    itemParams: {
      label: '发票类型'
    },
    cptParams: {
      placeholder: '请选择...'
    },
    options: [
      { id: 'ALL', name: '全部' },
      ...invoiceTypeMaps
    ]
  },
  {
    name: 'applyTimes',
    formType: 'RangePicker',
    itemParams: {
      label: '申请日期'
    },
    cptParams: {

    }
  }
]

function OpendTable (props) {
  const [tableData, setTableData] = useState([])
  const [recordsObj, setRecordsObj] = useState({ recordsModal: false, detailInfo: {} })
  const [logisticsObj, setLogisticsObj] = useState({ logisticsModal: false, detailInfo: {} })
  const [query, setSearchQuery] = useState({})
  const [pages, setPages] = useState({ total: 0, pageNo: 1, pageSize: 20 })
  const { pageNo, pageSize } = pages

  const columns = [
    {
      title: '序号',
      key: 'openedCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '开票申请编号',
      key: 'openedCol-1',
      dataIndex: 'id'
    },
    {
      title: '订单编号',
      key: 'openedCol-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单日期',
      key: 'openedCol-3',
      dataIndex: 'orderDate',
      render: (text, record, index) => (
        <>{ record.orderDate ? moment(record.orderDate).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '发票类型',
      key: 'openedCol-4',
      dataIndex: 'invoiceType',
      render: (text, record, index) => (
        <>{ renderInvoiceTypeLabel(record.invoiceType) }</>
      )
    },
    {
      title: '开票状态',
      key: 'openedCol-5',
      render: (text, record, index) => (
        <>{ renderInvoiceOpenedStatusLabel(record.invoiceOrderStatus) }</>
      )
    },
    {
      title: '申请日期',
      key: 'openedCol-6',
      render: (text, record, index) => (
        <>{record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}</>
      )
    },
    {
      title: '操作',
      key: 'openedCol-7',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => setRecordsObj({ recordsModal: true, detailInfo: record })}>查看详情</Button>
          <Button type='link' size='small' onClick={() => handleShowLogisticsModal(record)}>查看物流</Button>
        </>
      )
    }
  ]

  // 获取云采购-开票完成列表
  const getPurchaseOrderInvoiceOpenedList = async () => {
    const res = await Api.getPurchaseOrderInvoiceOpenedList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data.list || [])
      setPages({ ...pages, total: data.total || 0 })
    }
  }

  // 点击搜索
  const handleSearch = (search = {}) => {
    if (search.orderTimes) {
      search.orderBeginDate = search.orderTimes.length > 0 ? search.orderTimes[0].format('YYYY-MM-DD') : undefined
      search.orderEndDate = search.orderTimes.length > 0 ? search.orderTimes[1].format('YYYY-MM-DD') : undefined

      delete search.orderTimes
    }

    if (search.applyTimes) {
      search.invoiceBeginDate = search.applyTimes.length > 0 ? search.applyTimes[0].format('YYYY-MM-DD') : undefined
      search.invoiceEndDate = search.applyTimes.length > 0 ? search.applyTimes[1].format('YYYY-MM-DD') : undefined

      delete search.applyTimes
    }

    setSearchQuery(search)
    setPages({ ...pages, pageNo: 1 })
  }

  // 点击查看物流
  const handleShowLogisticsModal = (record = {}) => {
    const detailInfo = {
      courierCode: record.courierCode,
      courierNumberList: [record.courierNumber],
      mobile: record.phoneNumber || ''
    }

    setLogisticsObj({ logisticsModal: true, detailInfo: detailInfo })
  }

  const pageChange = (page, pageSize) => {
    setPages({ ...pages, pageNo: page })
  }

  const pageSizeChange = (current, size) => {
    setPages({ ...pages, pageNo: 1, pageSize: size })
  }

  useEffect(() => {
    getPurchaseOrderInvoiceOpenedList()
  }, [query, pageNo, pageSize])

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: pages.total,
    showTotal: (total) => { return `共 ${total} 条` },
    current: pageNo,
    pageSize: pageSize,
    pageSizeOptions: pageSizeOptions,
    onChange: pageChange,
    onShowSizeChange: pageSizeChange
  }

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <SearchForm dataSource={searchData} handleSubmit={(obj) => handleSearch(obj)} />
      </div>

      <Table rowKey='id' bordered
        columns={columns}
        dataSource={tableData}
        pagination={pagination}
      />

      {
        recordsObj.recordsModal
          ? <RecordsModal
            detailInfo={recordsObj.detailInfo}
            onCancel={() => setRecordsObj({ recordsModal: false, detailInfo: {} })}
          />
          : null
      }

      {
        logisticsObj.logisticsModal
          ? <LogisticsModal
              detailInfo={logisticsObj.detailInfo}
              onCancel={() => setLogisticsObj({ logisticsModal: false, detailInfo: {} })}
          />
          : null
      }
    </>
  )
}

export default OpendTable

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import $lodash from 'lodash'

import CorrectModal from '@/pages/enquireManage/createEnquireOrder/correctModal'

import styles from './index.module.scss'

const errorDescribeMaps = {
  ERROR: {
    type: 'danger',
    btnText: '纠错',
    btnClass: styles.correctBtn,
    desc: '您输入的产品订货号/型号可能有误，可点击下方按钮修正~~'
  },
  CHANGE_CHANNEL: {
    type: 'primary',
    btnText: '更换渠道',
    btnClass: styles.primaryBtn,
    desc: '您输入的产品其他渠道有货，需要更换么？可点击下方按钮更换~~'
  }
}

export default class ErrorRow extends Component {
  static propTypes={
    detail: PropTypes.object,
    list: PropTypes.array,
    i: PropTypes.number,
    searchResult: PropTypes.string,
    onoK: PropTypes.func
  }

  state={
    overModal: false,
    list: []
  }

  componentDidMount () {
    const { list = [] } = this.props
    this.setState({
      list: [...list]
    })
  }

  // 确定纠错
  handleConfirm = (sku) => {
    const { list } = this.state
    const { i } = this.props
    const n = $lodash.findIndex(list, (item) => { return item.commoditySkuId === sku.commoditySkuId })
    if (n > -1) {
      const { supplyInfoList } = list[n]
      const len = supplyInfoList.length
      if (supplyInfoList[len - 1].checked) {
        supplyInfoList[len - 1].quantity = supplyInfoList[len - 1].quantity + 1
      } else {
        supplyInfoList[len - 1].checked = true
        supplyInfoList[len - 1].quantity = 1
      }

      list.splice(i, 1)
    } else {
      sku.supplyInfoList = this.setLoginPriceCellData(sku)
      sku.checked = false
      sku.quickOrderSearchResult = 'SUCCESS'
      const obj = sku
      list[i] = obj
    }

    this.setState({
      list: list,
      overModal: false
    }, () => {
      this.props.onoK(this.state.list)
    })
  }

  // 设置价格数据格式
  setLoginPriceCellData = (record, i) => {
    const { supplyInfoList = [] } = record
    if (supplyInfoList && supplyInfoList.length > 0) {
      supplyInfoList[supplyInfoList.length - 1].checked = true
      supplyInfoList[supplyInfoList.length - 1].quantity = record.quantity ? record.quantity += record.quantity : 1
    }

    return supplyInfoList
  }

  // 监听纠错弹窗显示隐藏
  handleVisibleChange = (visible) => {
    this.setState({
      overModal: visible
    })
  }

  render () {
    const { searchResult, detail } = this.props
    const { overModal = false } = this.state

    return (
     <>
      <div className={styles.errorModal}>{detail.itemNumberModel}</div>
      <div className={styles.errorDesc}>{ errorDescribeMaps[searchResult].desc }</div>
      <div style={{ marginTop: '10px' }}>
        <Button type={errorDescribeMaps[searchResult].type} size='small' className={`${styles.btn} ${errorDescribeMaps[searchResult].btnText}`}
          onClick={() => this.handleVisibleChange(true)}
        >
          {errorDescribeMaps[searchResult].btnText}
        </Button>
      </div>

      {
        overModal
          ? <CorrectModal
              searchResultEnum={searchResult}
              keyword={detail.itemNumberModel}
              correctSource='ORDER_CORRECT'
              onConfirm={(sku) => this.handleConfirm(sku)}
              onCancel={() => this.handleVisibleChange(false)}
            />
          : null
      }
     </>
    )
  }
}

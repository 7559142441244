import Ajax from '../utils/ajax'

export default {
  // 云采购售后- 获取可售后的订单产品
  getCanSaledProductListByOrderId (params) {
    return Ajax.get('/backend/cloud/purchase/aftersale/query/goods/list', { params })
  },

  // 云采购订单提交售后申请
  savePurchaseOrderSaledApply (params) {
    return Ajax.post('/backend/cloud/purchase/aftersale/add', params)
  },

  // 获取云采购订单售后申请列表
  getPurchaseOrderSaledList (params) {
    return Ajax.post('/backend/cloud/purchase/aftersale/page/list', params)
  },

  // 获取云采购订单售后详情
  getPurchaseOrderSaledDetails (params) {
    return Ajax.get('/backend/cloud/purchase/aftersale/detail', { params })
  },

  // 云采购售后- 获取云采购售后进度详情
  getPurchaseOrderSaledProgressDetails (params) {
    return Ajax.get('/backend/cloud/purchase/aftersale/get/schedule', { params })
  }
}

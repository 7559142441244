/* 密码规则
*  6-12位字符 可包含数字，大小写字母
*/
export const passReg = /^[a-zA-Z0-9]{6,12}$/

/* 支付密码规则
*  6位数字
*/
export const paywordReg = /^\d{6}$/

/* 手机号码规则
 *
*/
export const phoneReg = /^1[3456789]\d{9}$/

/* 登录名规则
*  4-16位字符 可包含数字、大小写字母、下划线
*/
export const loginnameReg = /^[a-zA-Z0-9_]{4,16}$/

/**
 * 页面配置
 */
export const pageSizeOptions = ['10', '20', '50', '100', '200']

// 导入类型
export const importConfig = {
  cloudLibrarySetting: { sheetSourch: 'CLOUDLIBRARY_BATCHSEARCH_PRODUCT' } // 云库产品列表导入搜索
}

/**
 * 客户类型
 */
export const customerTypeMaps = [
  { id: '2', name: '企业用户' },
  { id: '1', name: '个人用户' }
]

// 订单支付状态
export const orderPaymentStatusMaps = [
  { id: 'NO', code: 10, name: '未付款' },
  { id: 'PART', code: 50, name: '已付首款' },
  { id: 'COMPLETE', code: 100, name: '全部付款' }
]

// 订单发货状态
export const orderDeliveryStatusMaps = [
  { id: 'NO', code: 10, name: '未发货' },
  { id: 'PART', code: 50, name: '部分发货' },
  { id: 'COMPLETE', code: 100, name: '全部发货' }
]

/**
 * 运费&保险 账单状态(德邦 顺丰 保险)
 */
export const billStatusMaps = [
  { id: 'INIT', name: '初始化', badge: 'processing' },
  { id: 'OVERDUE', name: '已逾期', badge: 'error' },
  { id: 'SETTLED', name: '已结算', badge: 'success' },
  { id: 'NOT_OVERDUE', name: '未逾期', badge: 'warning' }
]

// 云采购订单状态
export const cloudPurchOrderStatusMaps = [
  { id: 'INITIAL', name: '待EU用户付款' },
  { id: 'PAYING', name: '待付款' },
  { id: 'CANCEL_IN_PAYING', name: '未支付取消' },
  { id: 'CANCEL_OVERDUE', name: '超时取消' },
  { id: 'CANCEL_MANUAL', name: '手动取消' },
  { id: 'CANCEL_AFTER_PAYING', name: '已付首款取消' },
  { id: 'TAIL_PAYING', name: '待付尾款' },
  { id: 'WAIT_TO_SHIP', name: '待发货' },
  { id: 'WAIT_ORDER_GOODS', name: '待订货' },
  { id: 'WAIT_TO_RECEIVE', name: '已发货' },
  { id: 'ORDER_COMPLETE', name: '已完成' }
]

// IIASaaS快递服务公司
export const saasCouierCodeMaps = [
  {
    id: 'SAAS-DBL',
    courierCode: 'SAAS-DBL',
    courierName: 'IIASaaS服务（德邦）',
    aliasCode: 'DBL',
    aliasName: '德邦快递'
  },
  // {
  //   id: 'SAAS-SF',
  //   courierCode: 'SAAS-SF',
  //   courierName: 'IIASaaS服务（顺丰）',
  //   aliasCode: 'SF',
  //   aliasName: '顺丰速运'
  // },
  // {
  //   id: 'SAAS-JD',
  //   courierCode: 'SAAS-JD',
  //   courierName: 'IIASaaS服务（京东）',
  //   aliasCode: 'JD',
  //   aliasName: '京东快递'
  // },
  {
    id: 'SAAS-KYSY',
    courierCode: 'SAAS-KYSY',
    courierName: 'IIASaaS服务（跨越速运）',
    aliasCode: 'KYSY',
    aliasName: '跨越速运'
  }
]

// IIASaaS快递服务类型
export const expressTypeMaps = {
  'SAAS-DBL': [
    { id: 'PACKAGE', name: '标准快递', keysort: 'courierDiscount' },
    { id: 'RCP', name: '大件快递360', keysort: 'courierDiscount' },
    { id: 'JZKH', name: '精准卡航', keysort: '--' },
    { id: 'JZQY_LONG', name: '精准汽运', keysort: '--' },
    { id: 'NZBRH', name: '重包入户', keysort: 'logisticsDiscount' },
    { id: 'ZBTH', name: '重包特惠', keysort: 'logisticsDiscount' }
  ],
  'SAAS-SF': [
    { id: '1', name: '顺丰特快（空运）' },
    { id: '2', name: '顺丰标快（陆运）' },
    { id: '255', name: '顺丰卡航（物流）' },
    { id: '111', name: '顺丰干配' },
    { id: '231', name: '陆运包裹' },
    { id: '263', name: '同城半日达（快递）' }
  ],
  'SAAS-JD': [
    { id: '1', name: '特惠送' },
    { id: '6', name: '特快零担' },
    { id: '25', name: '特快重货' }
  ],
  'SAAS-KYSY': [
    { id: '20', name: '次日达' },
    { id: '30', name: '隔日达' },
    { id: '40', name: '陆运件' },
    { id: '50', name: '同城次日' },
    { id: '160', name: '省内次日' }
  ]
}

// IIASaaS快递服务- 签单返还枚举类
export const expressReceiptFlags = {
  'SAAS-DBL': [
    { id: 'DEPPON_0', name: '无需返单' },
    { id: 'DEPPON_1', name: '签收单原件返回' },
    { id: 'DEPPON_2', name: '电子签收单' }
  ],
  'SAAS-SF': [
    { id: 'SF_NO', name: '无需返单' },
    { id: 'SF_IN03', name: '纸质回单' },
    { id: 'SF_IN149', name: '电子回单' }
  ],
  'SAAS-JD': [
    { id: 'JD_0', name: '无签单' },
    { id: 'JD_1', name: '纸质签单' },
    { id: 'JD_3', name: '电子签单' },
    { id: 'JD_4', name: '纸质签单和电子签单' }
  ],
  'SAAS-KYSY': [
    { id: 'KY_20', name: '无需回单', key: '20' },
    { id: 'KY_10', name: '回单原件(含回单照片)', key: '10' },
    { id: 'KY_30', name: '回单照片', key: '30' },
    { id: 'KY_40', name: '电子回单', key: '40' }
  ]
}

// IIASaaS快递服务- 签收单返回类型枚举类
export const expressReceiptReturnRequirements = {
  'SAAS-DBL': [
    { id: 'R1', name: '签名' },
    { id: 'R2', name: '盖章' },
    { id: 'R3', name: '签身份证号' },
    { id: 'R4', name: '身份证复印件' },
    { id: 'R5', name: '仓库收货回执单' }
  ]
}

// IIASaaS快递服务- 木架包装方式枚举
export const expressPackWoodenFrames = {
  'SAAS-KYSY': [
    { id: '0', name: '不打木架' },
    { id: '10', name: '打卡板' },
    { id: '20', name: '打木架' },
    { id: '30', name: '打木箱' }
  ]
}

// 商品类型
export const skuGoodsTypeMaps = [
  { id: 'SPOT', name: '现货', aliasName: '现货' },
  { id: 'FUTURES', name: '期货', aliasName: '期货' },
  { id: 'CLOUD_SPOT', name: '云现货', aliasName: '现货调货' },
  { id: 'CLOUD_FUTURES', name: '云期货', aliasName: '期货调货' }
]

// 商品是否停产
export const skuStopFlagMaps = [
  { id: 'ON_SALE', name: '否' },
  { id: 'STOP_PRODUCTION', name: '是' }
]

// 询价单-数据类型枚举
export const enquireMatchStatusMaps = [
  { id: 'MATCHED', name: '已收录数据' },
  { id: 'UNMATCHED', name: '未收录数据' }
]

// 询价单-询价单提交提交
export const enquireOrderStateMaps = [
  { id: 'PLATFORM_DRAFT', name: '草稿' },
  { id: 'PLATFORM', name: '运营提交' },
  { id: 'MERCHANT', name: '商户提交' }
]

// 询价单-报价类型
export const answerEnquiredStatusMaps = [
  { id: 'NO', name: '暂未报价' },
  { id: 'RECEIVE', name: '同意报价' },
  { id: 'REFUSE', name: '拒绝报价' },
  { id: 'COMMUNICATE', name: '沟通报价' }
]

// 发票- 发票类型枚举
export const invoiceTypeMaps = [
  { id: 'ORDINARY_INVOICE', name: '增值税普通发票' },
  { id: 'SPECIAL_INVOICE', name: '增值税专用发票' }
]

// 发票- 开票状态枚举
export const invoiceOpenedStatusMaps = [
  { id: 'OPENING', name: '待开票' },
  { id: 'OPENED', name: '已开票' }
]

// 售后- 售后类型
export const saledTypeMaps = [
  { id: 'REFUND_RETURN_PRODUCTS', name: '退货退款' },
  { id: 'REFUND', name: '退款' },
  { id: 'CHANGE_PRODUCTS', name: '换货' },
  { id: 'ADD_DELIVER', name: '补寄' }
]

// 售后- 售后状态
export const saledStatusMaps = [
  { id: 'WAITING', name: '待受理' },
  { id: 'WAITING_AUDIT', name: '待审核' },
  { id: 'AUDIT_FAILED', name: '审核驳回' },
  { id: 'REJECT', name: '拒绝申请' },
  { id: 'WAITING_BACK', name: '待退货' },
  { id: 'WAITING_REFUND', name: '待退款' },
  { id: 'WAITING_DELIVER', name: '待发货' },
  { id: 'COMPLETE', name: '已完成' }
]

// 售后- 售后原因枚举
export const saledReasonTypeMaps = [
  { id: 'PRODUCT_FOR_PAGE_NOT_SAME', name: '商品与页面描述不符' },
  { id: 'SKU_CHOOSE_ERROR', name: '型号订货号选择错误' },
  { id: 'GOODS_MISSED', name: '少件/发错货/未收到货' },
  { id: 'GOODS_WAS_BAD', name: '商品破损/包装破损/序列号模糊' },
  { id: 'PRODUCTS_QUALITY_PROBLEM', name: '商品质量问题/存在故障' },
  { id: 'FAKE_OR_SECOND_HAND_GOODS', name: '商品疑似假货/二手商品' }
]

// 售后- 退款方式枚举
export const saledRefundTypeMaps = [
  { id: 'ORIGINAL_PAYMENT_TYPE', name: '原支付返还' }
]

// 售后- 退货方式
export const saledBackGoodsTypeMaps = [
  { id: 'IIASAAS', name: 'IIASaaS服务上门取件' },
  { id: 'SELF', name: '自行退货至IIASaaS' }
]

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Button } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import { pageSizeOptions, answerEnquiredStatusMaps } from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

export default class EnquireHistory extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'enquiryOrderId',
        formType: 'Input',
        itemParams: {
          label: '询价单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'enquiryTimes',
        formType: 'RangePicker',
        itemParams: {
          label: '询价日期'
        },
        cptParams: {}
      },
      {
        name: 'enquiryState',
        formType: 'Select',
        itemParams: {
          label: '报价状态'
        },
        cptParams: {

        },
        options: [
          { id: 'ALL', name: '全部' },
          ...answerEnquiredStatusMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    query: {},
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'enquireOrderCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '询价单编号',
      key: 'enquireOrderCol-1',
      dataIndex: 'enquiryOrderId'
    },
    {
      title: '询价日期',
      key: 'enquireOrderCol-2',
      render: (text, record, index) => (
        <>{ record.submitTime ? moment(record.submitTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '回复日期',
      key: 'enquireOrderCol-3',
      render: (text, record, index) => (
        <>{ record.replyTime ? moment(record.replyTime).format('YYYY-MM-DD HH:mm:ss') : null }</>
      )
    },
    {
      title: '查看报价单',
      key: 'enquireOrderCol-4',
      render: (text, record, index) => (
        <>{ record.enquiryState === 'NO' ? null : <Button type='link' size='small' onClick={() => this.handlePreviewQuotation(record)}>点击查看</Button> }</>
      )
    },
    {
      title: '询价状态',
      key: 'enquireOrderCol-5',
      render: (text, record, index) => (
        <>{ this.renderAnswerStatusLabel(record.enquiryState) }</>
      )
    },
    {
      title: '操作',
      key: 'enquireOrderCol-6',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleLinkToEnquireProcess(record)}>查看过程</Button>

          { record.enquiryState === 'NO' ? null : <Button type='link' size='small' onClick={() => this.handleDownload(record)}>导出文件</Button> }
        </>
      )
    }
  ]

  componentDidMount () {
    this.getEnquireOrderList()
  }

  // 获取询价单列表
  getEnquireOrderList = async () => {
    const { query = {}, pages } = this.state
    const res = await Api.getEnquireOrderList({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        tableData: data.list || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      })
    }
  }

  // 获取导出文件url
  getEnquireOrderDownloadUrl = async (params) => {
    const res = await Api.getEnquireOrderDownloadUrl(params)
    const { code, data } = res
    if (+code === 10000) {
      if (data && data !== '') {
        window.open(data, '_blank')
      }
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state
    if (search.enquiryTimes && search.enquiryTimes.length === 2) {
      search.enquiryBeginDate = moment(search.enquiryTimes[0]).format('YYYY-MM-DD')
      search.enquiryEndDate = moment(search.enquiryTimes[1]).format('YYYY-MM-DD')

      delete search.enquiryTimes
    }

    this.setState({
      query: search,
      pages: {
        ...pages,
        pageNo: 1
      }
    }, () => {
      this.getEnquireOrderList()
    })
  }

  // 点击查看报价单
  handlePreviewQuotation = (record) => {
    this.getEnquireOrderDownloadUrl({ orderId: record.orderId })
  }

  // 点击查看过程
  handleLinkToEnquireProcess = (record) => {
    this.props.history.push({ pathname: '/enquire/enquireProcess', state: { orderId: record.orderId, quotationNumber: record.quotationNumber } })
  }

  // 点击导出文件
  handleDownload = (record) => {
    DownloadApi.downloadEnquiryOrderExcel({ orderId: record.orderId })
  }

  // 渲染报价状态名称
  renderAnswerStatusLabel = (status) => {
    const obj = answerEnquiredStatusMaps.find((o) => { return o.id === status })
    const label = obj !== undefined ? obj.name : ''

    return label
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getEnquireOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getEnquireOrderList()
    })
  }

  render () {
    const { searchData = {}, pages, tableData = [] } = this.state

    const pagination = {
      showSizeChanger: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <Card bodyStyle={{ padding: '16px' }} bordered={false}>
        <div style={{ marginBottom: '20px' }}>
          <SearchForm
            {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <div style={{ marginBottom: '16px' }}>询价会在四个工作时内回复，若逾期未回复请联系我们19532535667</div>

        <Table rowKey='enquiryOrderId' bordered
          columns={this.columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </Card>
    )
  }
}

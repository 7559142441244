import Ajax from '../utils/ajax'

export default {
  // 云采购发票- 获取开票中数据列表
  getPurchaseOrderInvoiceOpeningList (params) {
    return Ajax.post('/api/merchantOrderInvoice/list/page/opening', params)
  },

  // 云采购发票- 获取开票中详情数据
  getPurchaseOrderInvoiceOpeningDetails (params) {
    return Ajax.post('/api/merchantOrderInvoice/get/merchantOrderInvoice/detail', params)
  },

  // 云采购发票- 获取开票完成数据列表
  getPurchaseOrderInvoiceOpenedList (params) {
    return Ajax.post('/api/merchantOrderInvoice/list/page/opened', params)
  },

  // 云采购发票- 获取开票完成详情数据
  getPurchaseOrderInvoiceOpenedDetails (params) {
    return Ajax.get('/api/merchantOrderInvoice/get/merchantInvoiceRecord/detail', { params })
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'
import { paywordReg } from '@/common/utils/filter'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 }
}

const tailFormItemLayout = {
  wrapperCol: { span: 16, offset: 4 }
}

function EditPaywordForm (props) {
  const { activeKey } = props
  const { getFieldDecorator, resetFields, validateFields } = props.form

  // 更新支付密码
  const updatePayPassword = async (params) => {
    const res = await Api.updatePayPassword(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      clearInterval()
      handleCancel()
    }
  }

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) { return false }
      const { oldPassword, newPassword } = values
      const jsencrypt = new JSEncrypt()
      jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])

      values.newPassword = jsencrypt.encrypt(newPassword)
      if (activeKey === 'EDIT') {
        values.oldPassword = jsencrypt.encrypt(oldPassword)
      }

      updatePayPassword(values)
    })
  }

  const handleCancel = () => {
    resetFields()
  }

  return (
    <>
      <div style={{ width: '650px' }}>
        <Form>
          <FormItem label='原支付密码' {...formItemLayout}>
            {
              getFieldDecorator('oldPassword', {
                getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                validate: [
                  {
                    trigger: 'onBlur',
                    rules: [
                      {
                        validator (rule, value, callback) {
                          if (value && !paywordReg.test(value)) {
                            /* eslint-disable */
                              callback('支付密码格式不正确')
                              /* eslint-enable */
                          } else {
                            callback()
                          }
                        }
                      }
                    ]
                  },
                  {
                    trigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                  }
                ]
              })(
                  <Input.Password />
              )
              }
          </FormItem>
          <FormItem label={'新支付密码'} extra={'支付密码为6位数字'} {...formItemLayout}>
            {
              getFieldDecorator('newPassword', {
                getValueFromEvent: event => event.target.value.replace(/\s+/g, ''),
                validate: [
                  {
                    trigger: 'onBlur',
                    rules: [
                      {
                        validator (rule, value, callback) {
                          if (value && !paywordReg.test(value)) {
                            /* eslint-disable */
                              callback('支付密码格式不正确')
                              /* eslint-enable */
                          } else {
                            callback()
                          }
                        }
                      }
                    ]
                  },
                  {
                    trigger: ['onChange', 'onBlur'],
                    rules: [{ required: true, message: '请您填写6位数字支付密码' }]
                  }
                ]
              })(
                  <Input.Password />
              )
              }
            </FormItem>
            <FormItem {...tailFormItemLayout} style={{ marginTop: '24px', marginBottom: '0px' }}>
              <Button type='primary' onClick={() => handleSubmit()}>保存</Button>
              <Button style={{ marginLeft: '24px' }} onClick={() => handleCancel()}>取消</Button>
            </FormItem>
          </Form>
        </div>
    </>
  )
}

EditPaywordForm.propTypes = {
  form: PropTypes.object,
  activeKey: PropTypes.string
}

const EditPayword = Form.create()(EditPaywordForm)

export default EditPayword

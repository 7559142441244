/* 快速下单管理路由 */
import PurchaseQuickOrder from '@/pages/purchase/quickOrder/index' // 快速下单
import ReceivingAddress from '@/pages/purchase/receivingAddress/index' // 收货地址
import Cart from '@/pages/purchase/cart/index'// 我的购物车
import OrderSubmit from '@/pages/purchase/orderSubmit/index'// 购物车结算
import OrderPay from '@/pages/purchase/orderPay/index' // 购物车支付
import OrderTransferDetails from '@/pages/purchase/orderTransferDetails/index' // 下单成功
import OrderSuccess from '@/pages/purchase/orderSuccess/index' // 支付成功
import CloudOrder from '@/pages/purchase/cloudOrder/index'// 云采购订单列表
import SaledOrder from '@/pages/purchase/purchaseSaled/index' // 售后管理
import CloudLibProducts from '@/pages/purchase/cloudLibProducts/index' // 云库产品列表

export default [
  {
    path: '/purchase/quickOrder',
    name: '快速下单',
    component: PurchaseQuickOrder
  },
  {
    path: '/purchase/receivingAddress',
    name: '收货地址',
    component: ReceivingAddress
  },
  {
    path: '/purchase/cart',
    name: '我的购物车',
    component: Cart
  },
  {
    path: '/purchase/orderSubmit',
    name: '结算',
    component: OrderSubmit
  },
  {
    path: '/purchase/orderPay',
    name: '支付订单',
    component: OrderPay
  },
  {
    path: '/purchase/orderTransferDetails',
    name: '下单成功',
    component: OrderTransferDetails
  },
  {
    path: '/purchase/orderSuccess',
    name: '下单成功',
    component: OrderSuccess
  },
  {
    path: '/purchase/CloudOrder',
    name: '云采购订单列表',
    component: CloudOrder
  },
  {
    path: '/purchase/saledOrder',
    name: '云采购售后管理',
    component: SaledOrder
  },
  {
    path: '/purchase/cloudLibProducts',
    name: '云库产品列表',
    component: CloudLibProducts
  }
]

import Ajax from '../utils/ajax'

export default {
  /* 根据类型获取所有品牌(用于品牌检索)
   * ALL_BRAND-全部品牌   MERCHANT_BRAND-商户的品牌  CLOUD_SUPPLY_BRAND-云供应品牌
  */
  getBrandList (params) {
    return Ajax.post('/api/brand/listBrandBaseInfo', params)
  },

  // 获取云采购- 分类列表
  getCloudCategoryList (params) {
    return Ajax.get('/api/cloud/procurement/listCloudClassification', { params })
  },

  // 获取云采购- 品牌列表
  getCloudBrandList (params) {
    return Ajax.get('/api/cloud/procurement/listCloudBrand', { params })
  },

  // 获取系列
  getSeriesList (params) {
    return Ajax.post('/api/cascade/series', params)
  },

  // 云库产品列表
  getCloudLibraryProductList (params) {
    return Ajax.post('/api/cloud/procurement/keyWordSearch', params)
  }
}

/* 账户管理路由 */
import PurchaseInvoice from '@/pages/finance/purchaseInvoice/index' // 云采购发票
import InvoiceAddress from '@/pages/finance/invoiceAddress/index' // 收票地址
import SetPayword from '@/pages/finance/setPayword/index' // 支付设置
import Balance from '@/pages/finance/balance/index' // 账户余额
import ExpressBill from '@/pages/finance/expressBill/index' // 运费账单

export default [
  {
    path: '/finance/purchaseInvoice',
    name: '云采购发票',
    component: PurchaseInvoice
  },
  {
    path: '/finance/invoiceAddress',
    name: '收票地址',
    component: InvoiceAddress
  },
  {
    path: '/finance/setPayword',
    name: '支付设置',
    component: SetPayword
  },
  {
    path: '/finance/balance',
    name: '账户余额',
    component: Balance
  },
  {
    path: '/finance/expressBill',
    name: '运费账单',
    component: ExpressBill
  }
]

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Card, Row, Col, Table, Radio, Button, message } from 'antd'
import $lodash from 'lodash'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import HandleModal from '@/components/handleModal/index'
import consts from '@/common/utils/consts'
import styles from './index.module.scss'

import SUCCESS_ICON from '@/assets/images/icon/success_before_icon.jpg'
import TOTAL_ICON from '@/assets/images/icon/total_before_icon.jpg'
import FAIL_ICON from '@/assets/images/icon/fail_before_icon.jpg'

const { downloadUploadCustomerFailDetails } = DownloadApi
const { SERIALWIDTH } = consts
const tabPaneMaps = [
  { id: 'ALL', name: '全部数据' },
  { id: 'SUCCESS', name: '匹配成功' },
  { id: 'FAIL', name: '需要修改' }
]

function UploadDetails (props) {
  const location = useLocation()
  const { datas = [] } = location.state
  const [tableData, setTableData] = useState([])
  const [activeKey, setActiveKey] = useState('ALL')
  const [hoverKey, setHoverKey] = useState('totalHover')
  const { history } = props
  const [handleModal, setHandleModel] = useState(false)
  const _letterArrMaps = ['客', '户', '导', '入', '中']

  // 保存客户导入
  const saveBatchUploadEndUsers = async (params) => {
    const res = await Api.saveBatchUploadEndUsers(params)
    const { code, data } = res
    if (+code === 10000) {
      setTimeout(() => {
        history.push({ pathname: '/customer/uploadResult', state: { total: datas.length, success: data } })
      }, 3000)
    }
  }

  // 点击切换tabPane
  const handleChangeTabPane = (key) => {
    let newdatas = []

    switch (key) {
      case 'SUCCESS':
        newdatas = $lodash.filter(datas, (o) => { return o.isSuccess })
        break
      case 'FAIL':
        newdatas = $lodash.filter(datas, (o) => { return !o.isSuccess })
        break
      default:
        newdatas = datas
    }

    setActiveKey(key)
    setTableData(newdatas)
  }

  // 点击导入数据
  const handleUploadDatas = () => {
    setHandleModel(true)
    const arr = [...datas]

    arr.forEach((item) => {
      item.password = '111111'
    })

    saveBatchUploadEndUsers({ euEnterprises: arr })
  }

  // 点击导出失败
  const handleDownloadFailDetails = () => {
    const faildatas = $lodash.filter(datas, (o) => { return !o.isSuccess })

    if (faildatas.length === 0) { return message.warning('无失败数据!') }

    downloadUploadCustomerFailDetails({ templates: faildatas })
  }

  // 点击取消匹配
  const handleCancel = () => {
    history.go(-1)
  }

  const columns = [
    {
      title: '序号',
      key: 'customer-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '真实姓名',
      width: 150,
      key: 'customer-3',
      dataIndex: 'adminName'
    },
    {
      title: '手机号码',
      key: 'customer-4',
      width: 150,
      dataIndex: 'adminPhoneNumber'
    },
    {
      title: '公司名称',
      width: 240,
      key: 'customer-5',
      dataIndex: 'name'
    },
    {
      title: '法人姓名',
      width: 150,
      key: 'customer-6',
      dataIndex: 'accountName'
    },
    {
      title: '注册地址',
      width: 240,
      key: 'customer-7',
      dataIndex: 'address'
    },
    {
      title: '统一社会信用代码',
      width: 240,
      key: 'customer-8',
      dataIndex: 'taxId'
    },
    {
      title: '开户银行',
      width: 240,
      key: 'customer-9',
      dataIndex: 'bank'
    },
    {
      title: '开票电话',
      width: 240,
      key: 'customer-10',
      dataIndex: 'telephone'
    },
    {
      title: '银行账号',
      width: 240,
      key: 'customer-11',
      dataIndex: 'bankAccount'
    },
    {
      title: '是否成功',
      width: 150,
      key: 'customer-12',
      render: (text, record, index) => {
        return (
          <span className={record.isSuccess === true ? styles.divgreen : styles.divred}>{record.isSuccess === true ? '是' : '否'}</span>
        )
      }
    },
    {
      title: '原因',
      width: 300,
      key: 'customer-13',
      dataIndex: 'failReason',
      render: (text, record, index) => {
        return (
          <span className={styles.divred}>{record.failReason}</span>
        )
      }
    }
  ]
  const handleMouseEnter = (key) => {
    setHoverKey(key)
  }

  const handleMouseLeave = (key) => {
    setHoverKey('')
  }
  useEffect(() => {
    setTableData(datas)
  }, [])

  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <Card bodyStyle={{ padding: '16px' }}>
          <Row className={styles.titlenum}>
            <div className={`${styles.flexbox} ${styles.total} ${hoverKey === 'totalHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => handleMouseEnter('totalHover')} onMouseLeave={() => handleMouseLeave('totalHover')}>
              <img className={`${styles.iconBox}`} src={TOTAL_ICON} />
              <div className={`${styles.flexboxBody}`}>
                <div style={{ fontSize: '28px', color: '#3E7BFA' }}>{datas.length}</div>
                <div style={{ fontWeight: '600', marginBottom: '4px' }}>总共</div>
              </div>
            </div>

            <div className={`${styles.flexbox} ${styles.success} ${hoverKey === 'successHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => handleMouseEnter('successHover')} onMouseLeave={() => handleMouseLeave('successHover')}>
              <img className={`${styles.iconBox}`} src={SUCCESS_ICON} />
              <div className={`${styles.flexboxBody}`}>
                <div style={{ fontSize: '28px', color: '#3E7BFA' }}>{$lodash.filter(datas, (o) => { return o.isSuccess }).length}</div>
                <div style={{ fontWeight: '600', marginBottom: '4px' }}>匹配成功</div>
              </div>
            </div>

            <div className={`${styles.flexbox} ${styles.fail} ${hoverKey === 'failHover' ? styles.backgoundHover : ''}`} onMouseEnter={() => handleMouseEnter('failHover')} onMouseLeave={() => handleMouseLeave('failHover')}>
              <img className={`${styles.iconBox}`} src={FAIL_ICON} />
              <div className={`${styles.flexboxBody}`}>
                <div style={{ fontSize: '28px', color: '#3E7BFA' }}>{$lodash.filter(datas, (o) => { return !o.isSuccess }).length}</div>
                <div style={{ fontWeight: '600', marginBottom: '4px' }}>需要修正</div>
              </div>
            </div>
          </Row>
        </Card>
      </div>

      <Card bodyStyle={{ padding: '15px' }} bordered={false}>
        <Row gutter={24} style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <Radio.Group buttonStyle='solid' defaultValue="ALL" onChange={(e) => handleChangeTabPane(e.target.value)}>
              {
                tabPaneMaps.map((item, index) => (
                  <Radio.Button value={item.id} key={`tabPane-${item.id}-${index}`}>{item.name}</Radio.Button>
                ))
              }
            </Radio.Group>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button onClick={() => handleCancel()}>取消匹配</Button>
            <Button style={{ marginLeft: '10px' }} onClick={() => handleDownloadFailDetails()} type='primary'>导出失败详情</Button>
            <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => handleUploadDatas()}>导入数据</Button>
          </Col>
        </Row>

        <Table rowKey={(record, index) => `rowKey-${activeKey}-${index}`} bordered
          columns={columns}
          dataSource={tableData}
          scroll={{ x: 1500 }}
        />
      </Card>

      {
        handleModal
          ? <HandleModal
            letterArr={_letterArrMaps}
          />
          : null
      }
    </>
  )
}

UploadDetails.propTypes = {
  history: PropTypes.object
}

export default UploadDetails

import React from 'react'
import PropTypes from 'prop-types'
import { Descriptions } from 'antd'

import { renderAddressLabel, formatBankAccount } from '@/common/utils/mUtils'
import styles from './index.module.scss'

function InvoiceAccount (props) {
  const { title, creditNo, tel, bankName, bankCard, province, city, county, address } = props

  return (
    <>
      <div className={`${styles.invoiceAmount}`}>
        <Descriptions bordered column={1}>
          <Descriptions.Item label='发票抬头（公司名称）'>{ title }</Descriptions.Item>
          <Descriptions.Item label='纳税人识别号'>{ creditNo }</Descriptions.Item>
          <Descriptions.Item label='单位地址、电话'>
            <p>{renderAddressLabel(province, city, county, address)}</p>
            <p>{ tel }</p>
          </Descriptions.Item>
          <Descriptions.Item label='开户行及账号'>
            <p>{ bankName }</p>
            <p>{ bankCard && bankCard !== '' ? formatBankAccount(bankCard) : null }</p>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </>
  )
}

InvoiceAccount.propTypes = {
  title: PropTypes.string,
  creditNo: PropTypes.string,
  tel: PropTypes.string,
  bankName: PropTypes.string,
  bankCard: PropTypes.string,
  province: PropTypes.number,
  city: PropTypes.number,
  county: PropTypes.number,
  address: PropTypes.string
}

export default InvoiceAccount

import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Icon, message } from 'antd'
import moment from 'moment'
import JSEncrypt from 'jsencrypt'

import Api from '@/common/api/index'

import PublickeyJson from '@/assets/json/publicKey.json'
import LOGOIMAGE from '@/assets/images/logo/logo.png'
import styles from './index.module.scss'

import { setStore } from '@/common/utils/mUtils'

function Login (props) {
  const { getFieldDecorator } = props.form

  const login = async (params) => {
    const res = await Api.login(params)
    const { code, data } = res
    if (+code === 10000) {
      setStore('token', data)

      props.history.push({ pathname: '/account/personal' })
    } else {
      message.warning(res.message)
    }
  }

  const handleLogin = () => {
    props.form.validateFields((err, values) => {
      if (err) { return false }
      const jsencrypt = new JSEncrypt()
      const { password } = values
      jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
      values.password = jsencrypt.encrypt(password)

      login(values)
    })
  }

  return (
    <>
      <div className={`${styles.loginWrap}`}>
        <div className={`${styles.header}`}>
          <img className={`${styles.logo}`} src={LOGOIMAGE} />
        </div>
        <div className={`${styles.content}`}>
          <div className={`${styles.bannerBox}`}>
            <h3 className={`${styles.bannerTitle}`}>Hi,你好！</h3>
            <p className={`${styles.bannerDesc}`}>欢迎进入IIASaaS采购平台 </p>
            <p className={`${styles.bannerExplain}`}>Welcome to IIASaaS purchasing platform</p>
          </div>

          <div className={`${styles.section}`}>
            <h3 className={`${styles.sectionTitle}`}>IIASaaS采购平台</h3>
            <Form>
              <Form.Item style={{ marginBottom: '38px' }}>
              {
                getFieldDecorator('username', {
                  rules: [
                    { required: true, message: '请输入用户名!' }
                  ]
                })(
                  <Input placeholder='请输入用户名' style={{ height: '56px' }}
                    prefix={<Icon type='user' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                  />
                )
              }
              </Form.Item>
              <Form.Item style={{ marginBottom: '38px' }}>
              {
                getFieldDecorator('password', {
                  rules: [
                    { required: true, message: '请输入密码!' }
                  ]
                })(
                  <Input.Password autoComplete='off' style={{ height: '56px' }}
                    placeholder='请输入密码'
                    prefix={<Icon type='lock' style={{ color: 'rgba(0, 0, 0, 0.3)' }} />}
                  />
                )
              }
              </Form.Item>
              <Form.Item style={{ marginBottom: '0px' }}>
                <Button type='primary' block className={`${styles.loginBtn}`} onClick={() => handleLogin() }>登录</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

      <div className={`${styles.footer}`}>
        CopyRight<Icon type="copyright" />IIASaaS&nbsp;2019-{ moment().format('YYYY') }&nbsp;Corporation All Rights Reserved&nbsp;
      <a href='https://beian.miit.gov.cn/' target='_blank' rel='noopener noreferrer'>沪ICP备20011366号-690</a>
      </div>
    </>
  )
}

Login.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object
}

export default Form.create()(Login)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Card } from 'antd'

import AccountSettings from './accountSettings/index'
import AccountBasic from './accountBasic/index'
// import styles from './index.module.scss'

const { TabPane } = Tabs
const accountMenuMaps = [
  { id: 'SETTINGS', name: '账户设置' },
  { id: 'BASIC', name: '基本信息' }
]

function AccountPersonal (props) {
  const [activeKey, setActiveKey] = useState('SETTINGS')

  return (
    <>
      <Card bodyStyle={{ padding: '24px 60px' }} bordered={false}>
        <div>
          <Tabs activeKey={activeKey} onChange={(value) => setActiveKey(value)}>
            {
              accountMenuMaps.map((item, i) => (
                <TabPane tab={item.name} key={item.id} />
              ))
            }
          </Tabs>
        </div>

        {
          activeKey === 'SETTINGS'
            ? <AccountSettings history={props.history}/>
            : null
        }

        {
          activeKey === 'BASIC'
            ? <AccountBasic />
            : null
        }
      </Card>
    </>
  )
}

AccountPersonal.propTypes = {
  history: PropTypes.object
}

export default AccountPersonal

/* 账户管理路由 */
import AccountPersonal from '@/pages/account/personal/index' // 个人中心

export default [
  {
    path: '/account/personal',
    name: '个人中心',
    component: AccountPersonal
  }
]

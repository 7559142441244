import React, { memo } from 'react'
import { Icon } from 'antd'

import styles from './index.module.scss'

function EmptyTraces (props) {
  return (
    <div className={styles.main}>
      <h1 className={styles.errortitle}>抱歉,此单号暂无轨迹</h1>
      <div className={styles.info}>
        <div className={styles.positon}>
          <p className={`${styles.icon} ${styles.iconmoen}`}> <Icon type='check' /></p>
          <p>运单号正确请稍后重试，可能是网络问题。</p>
          <p> 或点击右上角快递公司官网地址进行查询。</p>
        </div>

        <div className={styles.positon}>
          <p className={`${styles.erricon} ${styles.iconmoen}`}><Icon type='close' /></p>
          <p>可能是运单号错误，</p>
          <p> 请重新输入</p>
        </div>
      </div>
    </div>
  )
}

export default memo(EmptyTraces)

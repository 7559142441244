
export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_MENU = 'SET_MENU'
export const SET_SHOW_LOADING = 'SET_SHOW_LOADING'
export const SET_EDITENQUIRE_DETAILS = 'SET_EDITENQUIRE_DETAILS'

// 设置用户信息
export function setUserInfo (data) {
  return { type: SET_USER_INFO, data }
}

// 设置菜单
export function setMenus (data) {
  return { type: SET_MENU, data }
}

// 设置全局loading
export function setShowLoading (data) {
  return { type: SET_SHOW_LOADING, data }
}

// 询价单-设置新增、编辑询价单详情
export function setEditEnquireDetails (data) {
  return { type: SET_EDITENQUIRE_DETAILS, data }
}

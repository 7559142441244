import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Layout, Menu } from 'antd'

import Api from '@/common/api/index'
import styles from './index.module.scss'

const { Sider } = Layout
const { SubMenu } = Menu

function SiderMenu (props) {
  const [menus, setMenus] = useState([])
  const [openKeys, setOpenKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  // 获取菜单信息
  const getMenus = async () => {
    const res = await Api.getMenus({})
    const { code, data = [] } = res
    if (+code === 10000) {
      setMenus(data || [])
    }
  }

  // 点击跳转
  const handleLinkTo = (record) => {
    setSelectedKeys([record.path])

    props.history.push({ pathname: record.path })
  }

  // 展开菜单
  const handleOpenChange = (keys) => {
    const lastkeys = keys.find(key => { return openKeys.indexOf(key) === -1 })
    const i = menus.findIndex(item => { return item.code === lastkeys })

    setOpenKeys(i === -1 ? keys : (lastkeys ? [lastkeys] : []))
  }

  useEffect(() => {
    getMenus()
  }, [])

  return (
    <>
      <div style={{ width: '220px', overflow: 'hidden', flex: '0px 0px 220px', minWidth: '220px', maxWidth: '220px', minHeight: '100vh' }} />
      <Sider width='220' className={`${styles.leftSider}`}>
        <div className={`${styles.asideWrap}`}>
          <div className={`${styles.asideContent}`}>
            <Menu mode="inline"
              selectedKeys={selectedKeys}
              openKeys={openKeys}
              onOpenChange={(keys) => handleOpenChange(keys)}
            >
              {
                menus.map((menu, i) => (
                  <SubMenu key={menu.code} title={<><img className={`${styles.iconPic}`} src={menu.icon} width={20} height={20} /><span style={{ fontSize: '16px' }}>{menu.menuName}</span></>}>
                    {
                      menu.children && menu.children.map((item, n) => (
                        <Menu.Item key={item.path} onClick={() => handleLinkTo(item)}><div style={{ fontSize: '16px', paddingLeft: '6px' }}>{ item.menuName }</div></Menu.Item>
                      ))
                    }
                  </SubMenu>
                ))
              }
            </Menu>
          </div>
        </div>
      </Sider>
    </>
  )
}

SiderMenu.propTypes = {
  history: PropTypes.object
}

export default SiderMenu

import {
  SET_USER_INFO,
  SET_MENU,
  SET_SHOW_LOADING,
  SET_EDITENQUIRE_DETAILS
} from './action'

export default function counter (state = {}, action) {
  switch (action.type) {
    case SET_USER_INFO:
      return Object.assign({}, state, action.data)
    case SET_MENU:
      return Object.assign([], state, action.data)
    case SET_SHOW_LOADING:
      return Object.assign({}, state, action.data)
    case SET_EDITENQUIRE_DETAILS:
      return Object.assign({}, state, action.data)
    default:
      return state
  }
}

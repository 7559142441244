import React, {} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Checkbox, InputNumber, Badge } from 'antd'
import $lodash from 'lodash'

import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './index.module.scss'

function TableList (props) {
  const { disabled, FootCkBox, FootCheckBox, tableList = [], handleGoodsCkB, supplyCheck, handleInValue, handleDelList } = props

  return (
    <>
      <table className={styles.tabs} width="100%" >
        <thead>
          <tr className={styles.tabsthead}>
            <td className={`${styles.checkBox}`}><Checkbox disabled={disabled} checked={FootCkBox } onChange={(e) => { FootCheckBox(e) }} /></td>
            <td className={`${styles.tGoodsinfo}`}>商品信息</td>
            <td className={`${styles.tCK}`}>产品类型</td>
            <td className={`${styles.tGoodsprice}`}>单价</td>
            <td className={`${styles.tGoodsnum}`}>数量</td>
            <td className={`${styles.tGoodsmoney}`}>单品小计</td>
            <td className={`${styles.tGoodsOp}`}>操作</td>
          </tr>
        </thead>

        {
          tableList && tableList.length > 0
            ? <tbody>
              {
                tableList.map((record, i) => (
                  <tr key={i}>
                    <td className={`${styles.checkBox} ${record.onSaleStatus === 'DISABLE' ? 'disableBackground' : ''}`}>
                      <Checkbox checked={record.checked} disabled={record.onSaleStatus === 'DISABLE'} onChange={(e) => { handleGoodsCkB(e, i) }}/>
                    </td>

                    <td className={`${styles.Lgoodsinfo} ${record.onSaleStatus === 'DISABLE' ? 'disableBackground' : ''}`}>
                      {
                        record.onSaleStatus !== 'DISABLE'
                          ? <Link to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${record.commoditySkuId}&skuGoodsType=${record.skuGoodsType}` }} target='_blank'>
                              <div className={styles.GoodsInfo}>
                                <div className={styles.GoodsInfoimg}>
                                  { record.imageUrl && record.imageUrl !== '' ? <img className={styles.Goodsimgs} src={record.imageUrl} /> : null }
                                </div>

                                <div>
                                  <span className={styles.borderstyles}>
                                    { record.englishBrandName && record.englishBrandName !== '' ? `${record.brandName}/${record.englishBrandName}` : record.brandName }
                                  </span>
                                  <div className={styles.ttitle}>{record.name}</div>
                                  <div className={styles.tfont}>型号：{record.model}</div>
                                  <div className={styles.tfont}>订货号：{record.itemNumber}</div>
                                </div>
                              </div>
                            </Link>
                          : <div className={styles.GoodsInfo}>
                              <div className={styles.GoodsInfoimg}>
                                { record.imageUrl && record.imageUrl !== '' ? <img className={styles.Goodsimgs} src={record.imageUrl} /> : null }
                              </div>

                              <div>
                                <span className={styles.borderstyles}>
                                  { record.englishBrandName && record.englishBrandName !== '' ? `${record.brandName}/${record.englishBrandName}` : record.brandName }
                                </span>
                                <div className={styles.ttitle}>{record.name}</div>
                                <div className={styles.tfont}>型号：{record.model}</div>
                                <div className={styles.tfont}>订货号：{record.itemNumber}</div>
                              </div>
                            </div>
                        }
                    </td>

                    <td className={`${styles.tdminH} ${record.onSaleStatus === 'DISABLE' ? 'disableBackground' : ''}`} style={{ textAlign: 'center' }}>
                      {
                        record.quickOrderSearchResult !== 'ERROR'
                          ? <span className={`${styles.bstylesW} ${record.skuGoodsType === 'CLOUD_SPOT' ? styles.borderstyles : styles.borderstylesRed}`}>
                              { renderSkuGoodsTypeLabel(record.skuGoodsType) }
                            </span>
                          : null
                      }
                    </td>

                    <td className={`${styles.Lgoodsprice} ${record.onSaleStatus === 'DISABLE' ? 'disableBackground' : ''}`} colSpan={record.onSaleStatus === 'DISABLE' ? 3 : 1}>
                      {
                        record.onSaleStatus === 'DISABLE'
                          ? <p className='redColor'>该商品已失效</p>
                          : <>
                              {
                                record.supplyInfoList.map((item, n) => (
                                  <div className={styles.supplyList} key={n}>
                                    <span><Checkbox checked={item.checked} onChange={(e) => { supplyCheck(e, n, i) }} /></span>
                                    <span className={styles.supplyListprice}>
                                      <span style={{ verticalAlign: 'middle' }}>
                                        { +item.price > 0 ? <>&yen;{+item.price}</> : '面议' }
                                      </span>
                                      { item.special ? <Badge count={'特'} style={{ marginLeft: '4px' }}/> : null }
                                    </span>

                                    <span className={styles.supplyListstock}>
                                      {record.skuGoodsType === 'CLOUD_SPOT' ? `库存：${item.stock}` : `货期：${item.deliveryPeriodDays}个工作日`}
                                    </span>
                                  </div>
                                ))
                              }
                          </>
                      }
                    </td>

                    {
                      record.onSaleStatus !== 'DISABLE'
                        ? <td className={`${styles.Lgoodsnum} ${record.onSaleStatus === 'DISABLE' ? 'disableBackground' : ''}`}>
                            <>
                              {
                                record.supplyInfoList.map((item, k) => (
                                  <div className={styles.supplyNum} key={k}>
                                    {
                                      item.checked === true
                                        ? <>
                                            <InputNumber min={0} value={item.quantity} onChange={(value) => { handleInValue(value, k, i) }} size='small' />
                                            {
                                              record.skuGoodsType === 'CLOUD_SPOT' && item.quantity > item.stock
                                                ? <span className={styles.stockErr} >库存不足</span>
                                                : null
                                            }

                                            {
                                              +item.quantity < +item.minOrder
                                                ? <span className={styles.stockErr} >最少购买{item.minOrder}</span>
                                                : null
                                            }
                                          </>
                                        : null
                                    }
                                  </div>
                                ))
                              }
                            </>
                          </td>
                        : null
                    }

                    {
                      record.onSaleStatus !== 'DISABLE'
                        ? <td className={`${styles.Lgoodsmoney} ${record.onSaleStatus === 'DISABLE' ? 'disableBackground' : ''}`} colSpan={record.onSaleStatus === 'DISABLE' ? 0 : 1}>
                            {
                              record.supplyInfoList.map((item, j) => (
                                <div className={styles.supplyNum} key={j}>
                                  { item.checked === true ? <>&yen; {$lodash.round(+item.quantity * +item.price, 2)} </> : null }
                                </div>
                              ))
                            }
                          </td>
                        : null
                    }

                    <td className={`${styles.LgoodsOp} ${record.onSaleStatus === 'DISABLE' ? 'disableBackground' : ''}`}>
                      <div>
                        <a style={{ marginLeft: '10px' }} onClick={() => { handleDelList(i, record) }}>删除</a>
                      </div>
                    </td>
                  </tr>
                ))
              }
              </tbody>

            : <tbody className={styles.nulltb}>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td className={styles.epmt}>这里太空了~</td>
                </tr>
              </tbody>
        }
      </table>
    </>
  )
}

TableList.propTypes = {
  disabled: PropTypes.bool,
  FootCkBox: PropTypes.bool,
  tableList: PropTypes.array,
  FootCheckBox: PropTypes.func,
  handleGoodsCkB: PropTypes.func,
  supplyCheck: PropTypes.func,
  handleInValue: PropTypes.func,
  handleDelList: PropTypes.func

}
export default TableList

/* 客户管理路由 */
import CustomerList from '@/pages/customer/customerList/index' // 客户列表
import CustomerEdit from '@/pages/customer/customerEdit/index' // 客户编辑
import CustomerAddress from '@/pages/customer/customerAddress/index'

import UploadDetails from '@/pages/customer/uploadDetails/index' // 客户导入详情
import UploadResult from '@/pages/customer/uploadResult/index'

export default [
  {
    path: '/customer/list',
    name: '客户列表',
    component: CustomerList
  },
  {
    path: '/customer/edit',
    name: '客户信息',
    component: CustomerEdit
  },
  {
    path: '/customer/customerAddress',
    name: '客户地址',
    component: CustomerAddress
  },
  {
    path: '/customer/uploadDetails',
    name: '导入详情',
    component: UploadDetails
  },
  {
    path: '/customer/uploadResult',
    name: '导入结果',
    component: UploadResult
  }
]

import Ajax from '../utils/ajax'

export default {
  // 获取订单金额汇总
  getTradeOrderAmountSummary (params) {
    return Ajax.post('/api/order/getOrderAmountSum', params)
  },

  // 获取出库拣货单明细
  getStoreOutPickingDetail (params) {
    return Ajax.post('/api/warehouse/list/page/picking/batch/sku/info', params)
  },

  // 云采购订单- 获取云采购订单附件列表
  getPurchaseContractEnclosureList (params) {
    return Ajax.get('/api/cloud/procurementOrder/order/file/findContractByUserAndType', { params })
  },

  // 云采购订单- 云采购订单附件删除
  saveDeletePurchaseContractEnclosure (params) {
    return Ajax.get('/api/cloud/procurementOrder/file/delete', { params })
  }
}

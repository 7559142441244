import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Card, Table, Input, InputNumber, Button, Icon } from 'antd'

import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const { REMARKS_MAX_NUM } = consts
const { TextArea } = Input
const EditableContext = React.createContext()

class EditableCell extends Component {
  static propTypes = {
    form: PropTypes.object,
    editing: PropTypes.bool,
    dataIndex: PropTypes.string,
    title: PropTypes.string,
    inputType: PropTypes.string,
    record: PropTypes.object,
    index: PropTypes.number,
    children: PropTypes.array
  }

  getInput = () => {
    const { inputType } = this.props

    if (inputType === 'number') {
      return <InputNumber />
    }
    return <Input />
  }

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, ...restProps } = this.props

    return (
      <td {...restProps}>
        {editing
          ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(`goods-${record.key}[${dataIndex}]`, {
              rules: [
                { required: false, message: `请输入 ${title}!` }
              ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
            )
          : (
              children
            )}
      </td>
    )
  }

  render () {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
  }
}

class EditableTable extends Component {
  static propTypes = {
    form: PropTypes.object
  }

  state = {
    tableData: [
      {
        brandId: null,
        brandName: null,
        itemNumber: null,
        quantity: null,
        amount: null,
        key: 1
      }
    ],
    count: 1
  }

  columns = [
    {
      title: '品牌',
      key: 'expServiceCol-0',
      width: 200,
      editable: true,
      dataIndex: 'brandName'
    },
    {
      title: '型号/订货号',
      key: 'expServiceCol-1',
      dataIndex: 'keyword',
      editable: true
    },
    {
      title: '数量',
      key: 'expServiceCol-3',
      dataIndex: 'quantity',
      editable: true
    },
    {
      title: '货物金额',
      key: 'expServiceCol-4',
      dataIndex: 'amount',
      editable: true
    },
    {
      title: '',
      key: 'expServiceCol-5',
      render: (text, record, index) => {
        const { tableData = [] } = this.state

        return (
          <>
            <Button type='link' style={{ padding: '0px 6px' }} onClick={() => this.handleAddTableRow(index)}>
              <Icon type='plus' style={{ fontSize: '18px' }} />
            </Button>
            <Button type='link' style={{ padding: '0px 6px' }} disabled={tableData.length === 1} onClick={() => this.handleDeleteTableRow(index)}>
              <Icon type='minus' style={{ fontSize: '18px' }} />
            </Button>
          </>
        )
      }
    }
  ]

  componentDidMount () {}

  // 点击增加tableRow
  handleAddTableRow = (index) => {
    const { tableData = [], count = 1 } = this.state

    const newObj = {
      brandId: '',
      brandName: '',
      model: '',
      itemNumber: '',
      quantity: '',
      amount: '',
      key: count + 1
    }
    tableData.splice(index + 1, 0, newObj)

    this.setState({
      tableData,
      count: count + 1
    })
  }

  // 点击删除tableRow
  handleDeleteTableRow = (index) => {
    const { tableData = [] } = this.state

    if (tableData.length === 0) { return false }

    tableData.splice(index, 1)

    this.setState({
      tableData
    })
  }

  renderInputType = (dataIndex) => {
    let type = 'text'

    if (dataIndex === 'quantity' || dataIndex === 'amount') {
      type = 'number'
    }

    return type
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const components = {
      body: {
        cell: EditableCell
      }
    }

    const columns = this.columns.map((col, i) => {
      if (!col.editable) { return col }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.renderInputType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          index: i,
          editing: true
        })
      }
    })

    return (
      <div style={{ marginTop: '15px' }}>
        <Card title='发货明细' bodyStyle={{ padding: '16px' }} bordered={false}>
          <EditableContext.Provider value={form}>
            <Table bordered rowKey={(record, index) => `expressTableRow-${index}`}
              components={components}
              dataSource={this.state.tableData}
              columns={columns}
              pagination={false}
              footer={() => (
                <div className={`${styles.formitemRow}`}>
                  <Form.Item label='拣货单备注' style={{ marginBottom: '0px' }}>
                    {
                      getFieldDecorator('remarks', {

                      })(<TextArea placeholder={`请输入内容，不超过${REMARKS_MAX_NUM}字`} rows={4} maxLength={REMARKS_MAX_NUM} />)
                    }
                  </Form.Item>
                </div>
              )}
            />
          </EditableContext.Provider>
        </Card>
      </div>
    )
  }
}

const EditableFormTable = Form.create()(EditableTable)

export default EditableFormTable

import React, { } from 'react'
import PropTypes from 'prop-types'
import $lodash from 'lodash'

import styles from './index.module.scss'

function Supper (props) {
  const { info = {} } = props

  return (
    <ul className={styles.UsInfo}>
      <li>
        <div>现货金额</div>
        <p>&yen;{($lodash.round(+info.spotAmount, 2) * 100 / 100).toFixed(2)}</p>
      </li>
      <li>
        <div>期货定金</div>
        <p>&yen;{($lodash.round(+info.futuresFrontMoney, 2) * 100 / 100).toFixed(2)}</p>
      </li>

      <li>
        <div>期货尾款</div>
        <p>&yen;{($lodash.round(+info.futuresTailMoney, 2) * 100 / 100).toFixed(2)}</p>
      </li>

      <li>
        <div>本次应付</div>
        <p className={styles.UsInfoCRed}>
          &yen;{($lodash.round(info.spotAmount + info.futuresFrontMoney, 2) * 100 / 100).toFixed(2)}
        </p>
      </li>

      <li>
        <div>剩余应付</div>
        <p>&yen;{($lodash.round(+info.futuresTailMoney, 2) * 100 / 100).toFixed(2)}</p>
      </li>

      <li>
        <div>税额</div>
        <p>&yen;{ ($lodash.round(+info.tax, 2) * 100 / 100).toFixed(2) }</p>
      </li>

      <li>
        <div>商品含税总额</div>
        <p>&yen;{ ($lodash.round(+info.totalAmount, 2) * 100 / 100).toFixed(2) }</p>
      </li>
    </ul>
  )
}

Supper.propTypes =
{
  info: PropTypes.object
}
export default Supper

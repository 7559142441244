/* 询价单预览组件 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

export default class EnquirePreview extends Component {
  static propTypes = {
    list: PropTypes.array
  }

  state = {

  }

  columns = [
    {
      title: '序号',
      key: 'previewEnquireTableCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'previewEnquireTableCol-1',
      width: 200,
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'previewEnquireTableCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'previewEnquireTableCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品名称',
      key: 'previewEnquireTableCol-4',
      dataIndex: 'name'
    },
    {
      title: '单位',
      key: 'previewEnquireTableCol-5',
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'previewEnquireTableCol-6',
      dataIndex: 'quantity'

    },
    {
      title: '期望价格',
      key: 'previewEnquireTableCol-7',
      render: (text, record, index) => (
        <>{ record.expectPrice ? <>&yen;{record.expectPrice}</> : null }</>
      )
    },
    {
      title: '期望折扣',
      key: 'previewEnquireTableCol-8',
      dataIndex: 'expectDiscount',
      render: (text, record, index) => (
        <>{record.expectDiscount ? <>{(+record.expectDiscount * 100 / 100).toFixed(2)}%</> : null }</>
      )
    },
    {
      title: '期望货期',
      key: 'previewEnquireTableCol-9',
      dataIndex: 'expectDeliveryPeriodDays',
      render: (text, record, index) => (
        <>{record.expectDeliveryPeriodDays ? <>{ +record.expectDeliveryPeriodDays === 0 ? '现货' : `${+record.expectDeliveryPeriodDays}工作日` }</> : null}</>
      )
    },
    {
      title: '数据类型',
      key: 'previewEnquireTableCol-10',
      render: (text, record, index) => (
        <>{ this.renderMatchingLabel(record) }</>
      )
    }
  ]

  // 渲染数据类型名称
  renderMatchingLabel = (record) => {
    const label = record.dataType === 'WRITE' ? '手写数据' : (record.matchingState === 'MATCHED' ? '已收录数据' : '未收录数据')

    return label
  }

  render () {
    const { list = [] } = this.props

    return (
      <>
        <h3>询价单预览</h3>
        <Table bordered rowKey={(record, index) => { return record.id || record.rowKey }}
          columns={this.columns}
          dataSource={list}
          pagination={false}
        />
      </>
    )
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Modal } from 'antd'

import MatchSteps from '../components/matchSteps/index'
import SheetZone from './sheetZone/index'
import SheetTable from './sheetTable/index'

import Api from '@/common/api/index'

const { confirm } = Modal

export default class MatchSheet extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    pages: { total: 100, pageNo: 1, pageSize: 20, options: ['10', '20', '50', '100', '200'] },
    importId: null,
    excelName: '', // EXCEL名称
    sheetName: null,
    tableData: [], // 源数据
    activeSheetIndex: 0, // 当前选择的sheet
    activeSheetdatas: [] // 当前选择的sheet源数据
  }

  componentDidMount () {
    const { importId } = this.props.history.location.state

    this.setState({
      importId: importId
    }, () => {
      this.getUploadExcelAnalysisdatas()
    })
  }

  // 获取上传的EXCEL数据
  getUploadExcelAnalysisdatas = async () => {
    const { importId, pages } = this.state
    const res = await Api.getUploadExcelAnalysisdatas({ importId: importId, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        excelName: data.data.excelName || '',
        sheetName: data.data.sheetDTOList && data.data.sheetDTOList.length > 0 ? data.data.sheetDTOList[0].sheetName : null,
        tableData: data.data.sheetDTOList || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      }, () => {
        const { tableData = [] } = this.state
        if (tableData.length > 0) {
          this.handleChangeSheetIndex(0)
        }
      })
    }
  }

  // EXCEL解析数据匹配
  saveUploadExcelAnalysisMatch = async (params) => {
    const res = await Api.saveUploadExcelAnalysisMatch(params)
    const { code } = res
    if (+code === 10000) {
      const { importId } = this.state
      this.props.history.push({ pathname: '/enquire/enquireMatchReport', state: { importId: importId } })
    }
  }

  // 点击开始匹配按钮
  handleSubmit = () => {
    const { importId } = this.state
    const params = {
      importId: importId,
      importType: 'ENQUIRY',
      brandNameColNum: 0,
      modelColNum: 1,
      matchNoColNum: 2,
      itemNoColNum: 2,
      quantityColNum: 3
    }

    this.saveUploadExcelAnalysisMatch(params)
  }

  // 点击取消匹配按钮
  handleGoBack = () => {
    confirm({
      title: '确定取消？',
      content: '',
      onOk: () => {
        this.props.history.push({ pathname: '/enquire/createEnquireOrder' })
      }
    })
  }

  // 监听选择sheet列表
  handleChangeSheetIndex = (index) => {
    const { tableData = [] } = this.state

    this.setState({
      activeSheetIndex: index,
      activeSheetdatas: tableData[index].columnDTOList || []
    })
  }

  render () {
    const { excelName = '', sheetName = '', activeSheetIndex = 0, activeSheetdatas = [] } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '16px' }} bordered={false}>
          <MatchSteps
            activeStep={0}
            onCancel={() => this.handleGoBack()}
            onConfirm={() => this.handleSubmit()}
          />

          <SheetZone
            name={excelName}
            sheetNames={sheetName}
            activeSheetIndex={activeSheetIndex}
            onChangeSelect={(i) => this.handleChangeSheetIndex(i)}
          />

          <SheetTable list={activeSheetdatas} />
        </Card>
      </>
    )
  }
}

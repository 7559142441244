import React, { } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Button } from 'antd'

import styles from './index.module.scss'

function Foot (props) {
  const { disabled, totalValues = {}, FootCkBox, FootCheckBox, handleMdel, handleAddCarts, GoCarts } = props

  return (
    <>
      <div className={styles.footerBar}>
        <div className={`${styles.actions}`}>
          <Checkbox disabled={disabled} checked={FootCkBox} onChange={(e) => FootCheckBox(e)}>全选</Checkbox>
          <Button type='link' size='small' className={styles.removeMain} onClick={() => handleMdel()}>删除选中</Button>
        </div>

        <div className={styles.contents}>
          <span>已选中&nbsp;{totalValues.goods}&nbsp;种商品,共&nbsp;{totalValues.nums}&nbsp;件</span>
          <label className='label' style={{ marginLeft: '12px' }}>总价（不含运费）</label>
          <span className={styles.FootMoney}>&yen;{ (+totalValues.amount).toFixed(2) }</span>

          <Button type='primary' style={{ marginLeft: '32px' }} onClick={() => { handleAddCarts() }}>加入购物车</Button>
          <Button type='primary' style={{ marginLeft: '16px' }} onClick={() => { GoCarts() }}>去购物车结算</Button>
        </div>
      </div>
    </>
  )
}

Foot.propTypes =
{
  disabled: PropTypes.bool,
  totalValues: PropTypes.object,
  FootCkBox: PropTypes.bool,
  FootCheckBox: PropTypes.func,
  handleMdel: PropTypes.func,
  handleAddCarts: PropTypes.func,
  GoCarts: PropTypes.func

}

export default Foot

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'
import moment from 'moment'
import $lodash from 'lodash'

import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func,
    getDetail: PropTypes.func
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: []
  }

  columns = [
    {
      title: '序号',
      key: 'purchdetailCol-0',
      align: 'center',
      width: SERIALWIDTH,
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '品牌',
      key: 'purchdetailCol-1',
      dataIndex: 'brandName',
      ellipsis: true
    },
    {
      title: '型号',
      key: 'purchdetailCol-2',
      width: 140,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'purchdetailCol-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品',
      key: 'purchdetailCol-4',
      width: 150,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '产品类型',
      key: 'purchdetailCol-5',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ record.skuGoodsType === 'FUTURES' ? '云期货' : '云现货'}</>
      )
    },
    {
      title: '数量',
      key: 'purchdetailCol-6',
      width: 100,
      dataIndex: 'quantity'
    },
    {
      title: '货期',
      key: 'purchdetailCol-7',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => (
        <>
          {
            record.skuGoodsType === 'FUTURES'
              ? <>{ record.deliveryPeriodDays ? `${record.deliveryPeriodDays}个工作日` : '----' }</>
              : '当天发货'
          }
        </>
      )
    },
    {
      title: '预付比例',
      key: 'purchdetailCol-8',
      dataIndex: 'prepaymentRatio',
      render: (text, record, index) => (
        <>
          {
            ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType)
              ? <>{ (+record.prepaymentRatio * 100 / 100).toFixed(2) }%</>
              : '----'
          }
        </>
      )
    },
    {
      title: '交货日期',
      key: 'purchdetailCol-9',
      render: (text, record, index) => (
        <>{ record.deliveryPeriodTime ? moment(record.deliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '预计到货日期',
      key: 'purchdetailCol-10',
      render: (text, record, index) => (
        <>{ record.expectDeliveryPeriodTime ? moment(record.expectDeliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '实际到货日期',
      key: 'purchdetailCol-11',
      render: (text, record, index) => (
        <>{ record.actualDeliveryPeriodTime ? moment(record.actualDeliveryPeriodTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
      )
    },
    {
      title: '未税单价',
      key: 'purchdetailCol-12',
      width: 100,
      dataIndex: 'excludingTaxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.excludingTaxUnitPrice}</>
      )
    },
    {
      title: '含税单价',
      key: 'purchdetailCol-13',
      width: 100,
      dataIndex: 'taxUnitPrice',
      render: (text, record, index) => (
        <>&yen;{+record.taxUnitPrice}</>
      )
    },
    {
      title: '含税单品小计',
      key: 'purchdetailCol-14',
      width: 100,
      render: (text, record, index) => (
        <>&yen;{($lodash.round(+record.totalTaxUnitPrice, 2) * 100 / 100).toFixed(2)}</>
      )
    }
  ]

  componentDidMount () {
    this.getOrderDetailById()
  }

  getOrderDetailById = () => {
    const { getDetail, detailInfo } = this.props
    const { pages } = this.state

    const params = {
      orderId: detailInfo.orderId,
      pageNo: 1,
      pageSize: 99999
    }

    getDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getOrderDetailById()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getOrderDetailById()
    })
  }

  render () {
    const { tableData = [] } = this.state

    return (
      <Modal title='订单明细' width={1000} bodyStyle={{ padding: '15px' }}
        visible
        maskClosable={false}
        onOk={this.handleCancel}
        onCancel={this.handleCancel}
      >
        <Table rowKey={(record, index) => `${record.commoditySkuId}-${index}`} bordered
          dataSource={tableData}
          columns={this.columns}
          rowClassName={(record, index) => (+record.quantity < 0) || (+record.quantity === 0) ? 'redColor' : ''}
          pagination={false}
          scroll={{ x: 1800, y: 400 }}
        />
      </Modal>
    )
  }
}

import React from 'react'
import AddressList from '@/components/addressList/index'

import Api from '@/common/api/index'

function SendAddress (props) {
  const getSendAddressList = (params) => {
    return Api.getSendAddressList(params)
  }

  const saveAddSendAddress = (params) => {
    return Api.saveAddSendAddress(params)
  }

  const saveEditSendAddress = (params) => {
    return Api.saveEditSendAddress(params)
  }

  const deleteSendAddress = (params) => {
    return Api.deleteSendAddress(params)
  }

  return (
    <>
      <AddressList
        componentType='SEND'
        getList={(params) => getSendAddressList(params)}
        onSaveAddress={(params) => saveAddSendAddress(params)}
        onEditAddress={(params) => saveEditSendAddress(params)}
        onDeleteAddress={(params) => deleteSendAddress(params)}
      />
    </>
  )
}

export default SendAddress

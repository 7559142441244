import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Form, Modal, message } from 'antd'
import $lodash from 'lodash'

import DiscernModal from '@/components/discernModal/index'
import SearchForm from './searchForm' // 表单搜索
import FootBtn from './footBtn/index'//
import TableList from './tableList' // 表格数据

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

import { repLineBreak } from '@/common/utils/mUtils'
const { downManualTemp } = DownloadApi

class QuickOrder extends Component {
  static propTypes = {
    form: PropTypes.object,
    history: PropTypes.object
  }

  state={
    tableList: [],
    selectedTotalValues: { goods: 0, nums: 0, amount: 0 },
    FootCkBox: false,
    discernModal: false,
    str: ''
  }

  componentDidMount () {

  }

  // 快速下单
  hanldeGetSearch=() => {
    this.props.form.validateFields((errr, values) => {
      const unitList = []
      if (values.fromInfo && values.fromInfo !== '') {
        const search = repLineBreak(values.fromInfo, '<br>').split('<br>')
        search.forEach(item => {
          if (item !== '') {
            const _arr = item.split(',')
            unitList.push({
              itemNumberModel: _arr.length > 0 ? _arr[0] : '',
              quantity: _arr[1] && +_arr[1] > 0 ? +_arr[1] : 1
            })
          }
        })
        const param = {
          unitList: unitList,
          needCloudSpot: true
        }
        this.getPurChaseBatchSearch(param)
      } else {
        message.warning('请输入搜索的信息')
      }
    })
  }

  // 设置处理数据
  GetTabslist =(list) => {
    const { tableList } = this.state
    list.forEach((item, i) => {
      if (item.quickOrderSearchResult === 'SUCCESS') {
        const n = $lodash.findIndex(tableList, (record) => { return record.commoditySkuId === item.commoditySkuId && record.skuGoodsType === item.skuGoodsType })
        if (n > -1) {
          const { supplyInfoList = [] } = tableList[n]
          const len = supplyInfoList.length
          if (supplyInfoList[len - 1].checked) {
            supplyInfoList[len - 1].quantity += item.quantity
          } else {
            supplyInfoList[len - 1].checked = true
          }
          tableList[n].supplyInfoList = supplyInfoList
        } else {
          item.supplyInfoList = this.setLoginPriceCellData(item)
          item.checked = false
          tableList.push(item)
        }
      } else {
        tableList.push(item)
      }
    })
    this.setState({
      tableList: tableList
    }, () => {
      this.calcSelectedAmount()
    })
  }

  // 快速下单接口
  getPurChaseBatchSearch = (param) => {
    Api.getPurChaseBatchSearch(param).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const _list = data || []
        this.GetTabslist(_list)
      }
    })
  }

  // 设置价格数据格式
  setLoginPriceCellData = (record, i) => {
    const { supplyInfoList = [] } = record
    if (supplyInfoList && supplyInfoList.length > 0) {
      supplyInfoList[supplyInfoList.length - 1].checked = true
      supplyInfoList[supplyInfoList.length - 1].quantity = record.quantity
    }
    return supplyInfoList
  }

  // 单价选择
  supplyCheck = (checked, priceIndex, rowIndex) => {
    const { tableList } = this.state
    tableList[rowIndex].supplyInfoList[priceIndex].checked = checked
    tableList[rowIndex].supplyInfoList[priceIndex].quantity = tableList[rowIndex].supplyInfoList[priceIndex].quantity ? tableList[rowIndex].supplyInfoList[priceIndex].quantity : 1
    this.setState({
      tableList
    }, () => {
      this.calcSelectedAmount()
    })
  }

  // 监听数量变化
  handleInValue=(value, priceIndex, rowIndex) => {
    const { tableList } = this.state
    tableList[rowIndex].supplyInfoList[priceIndex].quantity = value

    this.setState({
      tableList
    }, () => {
      this.calcSelectedAmount()
    })
  }

  // 全选按钮
  FootCheckBox = (checked) => {
    const { tableList } = this.state
    tableList.forEach(item => {
      if (item.quickOrderSearchResult !== 'ERROR') {
        item.checked = checked
      }
    })
    this.setState({
      FootCkBox: checked,
      tableList: tableList
    }, () => { this.calcSelectedAmount() })
  }

  // 商品选择按钮
  handleGoodsCkB = (checked, rowIndex) => {
    const { tableList } = this.state
    tableList[rowIndex].checked = checked

    const n = $lodash.findIndex(tableList, item => { return item.checked === false })
    this.setState({
      tableList,
      FootCkBox: !(n > -1)
    }, () => {
      this.calcSelectedAmount()
    })
  }

  // 删除商品
  handleDelList = (i) => {
    Modal.confirm({
      title: '确认删除吗？',
      onOk: () => {
        const { tableList } = this.state
        tableList.splice(i, 1)
        this.setState({
          tableList
        }, () => {
          this.GetCheckBox()
        })
      }
    })
  }

  // 选中删除
  handleMdel = () => {
    const { tableList } = this.state
    const len = $lodash.filter(tableList, item => { return item.checked }).length
    if (len === 0) {
      Modal.warning({
        title: '至少选中一个哦!'
      })
    } else {
      Modal.confirm({
        title: '确定删除商品？',
        okText: '确认',
        onOk: () => { this.GetDelGoods() }
      })
    }
  }

  // 复选删除商品
  GetDelGoods = () => {
    const { tableList } = this.state
    const lenlist = $lodash.filter(tableList, item => { return item.checked === false || item.quickOrderSearchResult !== 'SUCCESS' })
    this.setState({
      tableList: lenlist
    }, () => { this.GetCheckBox() }
    )
  }

  // 复选按钮
  GetCheckBox = () => {
    const { tableList } = this.state
    let falt = tableList.length > 0
    let a = []
    if (falt === true) {
      a = $lodash.filter(tableList, item => { return item.checked === false || item.quickOrderSearchResult !== 'SUCCESS' })
      if (a.length > 0) {
        falt = false
      }
    }
    this.setState({
      FootCkBox: falt
    }, () => { this.calcSelectedAmount() })
  }

  // 计算选择商品数和总件数
  calcSelectedAmount = () => {
    const { tableList = [] } = this.state
    const obj = { goods: 0, nums: 0, amount: 0 }

    // 过滤未选择的产品
    const datas = $lodash.filter(tableList, (record) => { return record.checked })

    obj.goods = datas.length

    $lodash.forEach(datas, (record) => {
      const { supplyInfoList = [] } = record

      /* 过略掉未选中的价格和没填写数量的数据 */
      const newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })

      if (newArr.length > 0) {
        obj.nums += $lodash.sumBy(newArr, 'quantity')
        obj.amount += $lodash.sumBy(newArr, (atem) => { return +($lodash.round(+atem.price * +atem.quantity, 2)) })
      }
    })

    this.setState({
      selectedTotalValues: obj
    })
  }

  // 纠错确认
  handlePonok =(list) => {
    this.setState({
      tableList: list
    })
  }

  // 加入购物车
  handleAddCarts = () => {
    const { tableList } = this.state
    const eer = $lodash.filter(tableList, item => { return item.checked === true })
    if (tableList.length <= 0 || eer.length <= 0) {
      Modal.warning({
        title: '请先选择商品'
      })
      return
    }

    for (const record of eer) {
      const { supplyInfoList } = record
      const newArr = $lodash.filter(supplyInfoList, (item) => { return item.checked && item.quantity > 0 })
      if (newArr.length === 0) {
        Modal.warning({
          title: <>订货号:&nbsp;{record.itemNumber}&nbsp;请选择产品价格并且填写数量</>,
          maskClosable: true
        })

        return false
      }
      for (const row of newArr) {
        if (record.skuGoodsType === 'CLOUD_SPOT' && +row.quantity > +row.stock) {
          Modal.warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;现货库存不足</>,
            maskClosable: true
          })

          return false
        }
      }
      const rowTotal = $lodash.sumBy(newArr, 'quantity')

      if (rowTotal < +record.minOrder) {
        Modal.warning({
          title: <>订货号:&nbsp;{record.itemNumber}&nbsp;最少购买{+record.minOrder}件</>,
          maskClosable: true
        })

        return false
      }
    }

    const getList = []

    eer.forEach(item => {
      item.supplyInfoList.forEach(itemm => {
        if (item.checked === true && itemm.checked === true) {
          const obj = {
            commoditySkuId: item.commoditySkuId,
            goodsType: item.skuGoodsType,
            supplierId: itemm.supplierId,
            quantity: itemm.quantity
          }
          getList.push(obj)
        }
      })
    })

    if (getList.length > 0) {
      this.saveAddToCart({ cartList: getList })
    }
  }

  // 添加到购物车
  saveAddToCart = (params) => {
    Api.saveAddToCart(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '加入购物车成功~'
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  // 点击下载模板
  handleDownloadManualTemp = () => {
    downManualTemp({})
  }

  // 上传产品信息
  handleChangeUploadSkuList = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        this.GetTabslist(data || [])
      } else {
        message.error('上传失败')
      }
    }
  }

  // 图文转换
  handleDisimg = () => {
    this.setState({
      discernModal: true
    })
  }

  // 关闭弹窗
  closeModal = () => {
    this.setState({
      discernModal: false
    })
  }

  // 跳到购物车
  GoCarts = () => {
    this.props.history.push('/purchase/cart')
  }

  // 图文识别
  confirmIntelResult = (str) => {
    setTimeout(() => {
      this.setState({
        str: str
      })
      this.closeModal()
    }, 20)
  }

  render () {
    const { tableList, FootCkBox, selectedTotalValues = {}, discernModal = false, str } = this.state
    const a = $lodash.filter(tableList, item => { return item.quickOrderSearchResult === 'SUCCESS' })
    const disabled = !(a.length > 0)

    return (
      <Card bodyStyle={{ padding: '16px' }} bordered={false}>
        <SearchForm
          handleChangeUploadSkuList = {(info) => { this.handleChangeUploadSkuList(info) }}
          handleDownloadManualTemp = {() => { this.handleDownloadManualTemp() }}
          handleDisimg = {() => { this.handleDisimg() }}
          onSearch = {(values) => { this.getPurChaseBatchSearch(values) }}
          str = {str}
        />

        <TableList
          disabled={disabled}
          tableList={tableList}
          FootCkBox={FootCkBox}
          FootCheckBox={(e) => { this.FootCheckBox(e.target.checked) }}
          handleGoodsCkB={(e, i) => { this.handleGoodsCkB(e.target.checked, i) }}
          supplyCheck={(e, ii, i) => { this.supplyCheck(e.target.checked, ii, i) }}
          handlePonok={(item) => { this.handlePonok(item) }}
          handleInValue={(value, ii, i) => { this.handleInValue(value, ii, i) }}
          handleDelList={(i) => { this.handleDelList(i) }}
        />

        <FootBtn
          disabled={disabled}
          FootCkBox={FootCkBox}
          totalValues={selectedTotalValues}
          FootCheckBox={(e) => { this.FootCheckBox(e.target.checked) }}
          handleMdel = {() => { this.handleMdel() }}
          handleAddCarts = {() => { this.handleAddCarts() }}
          GoCarts= {() => { this.GoCarts() }}
        />

        {
          discernModal === true
            ? <DiscernModal
                onCancel={() => this.closeModal()}
                onConfirm={(str) => this.confirmIntelResult(str)}
              />
            : null
        }
      </Card>
    )
  }
}

const QuickOrderForm = Form.create()(QuickOrder)
export default QuickOrderForm

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Tabs, Button, Icon } from 'antd'

import EditPersonalForm from './editPersonalForm/index'
import EditPriseForm from './editPriseForm/index'

const { TabPane } = Tabs

const tabPaneMaps = [
  { id: '1', name: '个人用户' },
  { id: '2', name: '企业用户' }
]

export default class CustomerEdit extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    activeMenuKey: '1', // 1-个人用户 2-企业用户
    info: {},
    type: 'ADD'
  }

  componentDidMount () {
    const { detailInfo = {}, type = 'ADD' } = this.props.history.location.state

    this.setState({
      info: detailInfo,
      type: type,
      activeMenuKey: +detailInfo.enterpriseId > 0 ? '2' : '1'
    })
  }

  handleChangeMenu = (key) => {
    this.setState({
      activeMenuKey: key
    })
  }

  // 返回客户列表
  handleBack = () => {
    this.props.history.goBack()
  }

  render () {
    const { activeMenuKey, type, info } = this.state

    return (
      <>
        <Card bodyStyle={{ padding: '24px 60px' }} bordered={false}>
          <div style={{ marginBottom: '20px' }}>
            <Button type='primary' onClick={() => this.handleBack()}>
              <Icon type='left' />返回
            </Button>
          </div>

          <Tabs activeKey={activeMenuKey} onChange={(value) => this.handleChangeMenu(value)}>
            {
              tabPaneMaps.map((item) => (
                <TabPane tab={item.name} key={item.id} />
              ))
            }
          </Tabs>

          {
            +activeMenuKey === 1
              ? <EditPersonalForm
                  activeMenuKey={activeMenuKey}
                  type={type}
                  endUserInfo={info.endUser || {}}
                  onSuccess={() => this.handleBack()}
                />
              : null
          }

          {
            +activeMenuKey === 2
              ? <EditPriseForm
                  activeMenuKey={activeMenuKey}
                  type={type}
                  endUserInfo={info.endUser || {}}
                  euEnterprise={info.euEnterprise || {}}
                  onSuccess={() => this.handleBack()}
                />
              : null
          }
        </Card>
      </>
    )
  }
}

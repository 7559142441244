import React, { } from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { concat } from 'lodash'

function PayMoeny (props) {
  const { obj = {}, amount = 0 } = props

  return (
    <div className={styles.goodsAdress}>
    <div>
      <span style={{ marginLeft: '10px' }}>寄送至：</span>
      {
        obj
          ? <span>{concat(obj.addressRegion, obj.address, ' ', '收货人：', obj.receiver, ' ', obj.phoneNumber)}</span>
          : null
      }
    </div>
    <div className={styles.costTitle}>实付款：<span className={styles.costM}>&yen;{(+amount * 100 / 100).toFixed(2)}</span></div>
  </div>
  )
}

PayMoeny.propTypes = {
  obj: PropTypes.object,
  amount: PropTypes.number
}

export default PayMoeny

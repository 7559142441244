import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Table, Upload, Button, Icon, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import AddressModal from '@/components/saddress-modal/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'
import UploadApi from '@/common/api/uploadApi'
import consts from '@/common/utils/consts'
import { customerTypeMaps, pageSizeOptions } from '@/common/utils/filter'
import { getStore, removeStore } from '@/common/utils/mUtils'

const { SERIALWIDTH } = consts
const { downloadUploadEndUserTemplate, downloadEndUserList } = DownloadApi
const { beforeuploadEndUser } = UploadApi
const searchData = [
  {
    name: 'company',
    formType: 'Input',
    itemParams: {
      label: '公司名称'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off'
    }
  },
  {
    name: 'realName',
    formType: 'Input',
    itemParams: {
      label: '真实名称'
    },
    cptParams: {
      placeholder: '请输入...',
      autoComplete: 'off'
    }
  },
  {
    name: 'times',
    formType: 'RangePicker',
    itemParams: {
      label: '注册时间'
    },
    cptParams: {

    }
  },
  {
    name: 'userType',
    formType: 'Select',
    itemParams: {
      label: '客户类型'
    },
    cptParams: {

    },
    options: [
      { id: '', name: '全部' },
      ...customerTypeMaps
    ],
    rules: {
      initialValue: ''
    }
  }
]

function CustomerList (props) {
  const { history } = props
  const [query, setSearchQuery] = useState({})
  const [pages, setPages] = useState({ total: 0, pageNo: 1, pageSize: 20 })
  const [tableData, setTableData] = useState([])
  const [addressObj, setAddressObj] = useState({ type: 1, visiable: false, detailInfo: {}, endUserId: null })
  const { pageNo, pageSize } = pages

  // 获取客户列表
  const getGuestListBySeller = async () => {
    const res = await Api.getGuestListBySeller({ ...query, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data.list || [])
      setPages({ ...pages, total: data.total || 0 })
    }
  }

  // 保存地址
  const saveAddEndUserReceiveAddress = async (params) => {
    const res = await Api.saveAddEndUserReceiveAddress(params)
    const { code } = res
    if (+code === 10000) {
      setAddressObj({ type: 1, visiable: false, detailInfo: {}, endUserId: null })
      message.success('操作成功')
    }
  }

  // 导入客户
  const changeUploadEndUser = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        history.push({ pathname: '/customer/uploadDetails', state: { datas: data } })
      } else if (code === 'SUPPLIER_ERROR_00001') {
        removeStore('token')
        window.location.href = 'http://' + window.location.host + '/login'
      } else {
        message.error(`${info.file.name}上传失败`)
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name}上传失败`)
    }
  }

  // 点击搜索
  const handleSearch = (search = {}) => {
    if (search.times && search.times.length === 2) {
      search.beginDate = search.times[0].format('YYYY-MM-DD')
      search.endDate = search.times[1].format('YYYY-MM-DD')
      delete search.times
    }

    setSearchQuery(search)
    setPages({ ...pages, pageNo: 1 })
  }

  // 跳转到客户新增、编辑
  const linkToCustomerEdit = (record, type) => {
    history.push({ pathname: '/customer/edit', state: { detailInfo: record, type: type } })
  }

  // 跳转到地址
  const handleLinkToAddress = (record) => {
    history.push({ pathname: '/customer/customerAddress', state: { endUserId: record.euId } })
  }

  // 点击保存地址
  const handleSaveAddress = (values) => {
    const params = Object.assign({}, values)
    params.receiver = values.name
    params.phoneNumber = values.mobile
    params.category = values.status === 'ENABLE' ? 2 : 0

    saveAddEndUserReceiveAddress(params)
  }

  const pageChange = (page, pageSize) => {
    setPages({ ...pages, pageNo: page })
  }

  const pageSizeChange = (current, size) => {
    setPages({ ...pages, pageNo: 1, pageSize: size })
  }

  useEffect(() => {
    getGuestListBySeller()
  }, [query, pageNo, pageSize])

  const columns = [
    {
      title: '序号',
      key: 'customerListCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '公司名称',
      width: 240,
      key: 'customerListCol-1',
      render: (text, record, index) => (
        <span>
          { +record.enterpriseId > 0 ? record.company : '----' }
        </span>
      )
    },
    {
      title: '真实姓名',
      key: 'customerListCol-2',
      render: (text, record, index) => (
        <span>
          { record.endUser && record.endUser.realname ? record.endUser.realname : null }
        </span>
      )
    },
    {
      title: '手机号码',
      key: 'customerListCol-3',
      render: (text, record, index) => (
        <>
          {
            record.endUser && record.endUser.phoneNumber ? <span>{record.endUser.phoneNumber}</span> : null
          }
        </>
      )
    },
    {
      title: '客户类型',
      key: 'customerListCol-4',
      render: (text, record, index) => (
        <span>
          { +record.enterpriseId > 0 ? '企业用户' : '个人用户' }
        </span>
      )
    },
    {
      title: '注册时间',
      key: 'customerListCol-5',
      sorter: (a, b) => a.createTime - b.createTime,
      render: (text, record, index) => (
        <span>
          {record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----'}
        </span>
      )
    },
    {
      title: '操作',
      key: 'customerListCol-6',
      width: 250,
      fixed: 'right',
      render: (text, record, index) => (
        <div>
          <Button type='link' size='small' onClick={() => linkToCustomerEdit(record, 'EDIT')}>编辑</Button>

          <Button type='link' size='small' onClick={() => handleLinkToAddress(record)}>查看地址</Button>

          <Button type='link' size='small' onClick={() => setAddressObj({ type: 1, visiable: true, detailInfo: {}, endUserId: record.euId })}>添加地址</Button>
        </div>
      )
    }
  ]

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: pages.total,
    showTotal: (total) => { return `共 ${total} 条` },
    current: pages.pageNo,
    pageSize: pages.pageSize,
    pageSizeOptions: pageSizeOptions,
    onChange: pageChange,
    onShowSizeChange: pageSizeChange
  }

  return (
    <>
      <Card bodyStyle={{ padding: '15px' }} bordered={false}>
        <div style={{ marginBottom: '20px' }}>
          <SearchForm {...props}
            dataSource={searchData}
            handleSubmit={(obj) => handleSearch(obj)}
          />
        </div>

        <div style={{ marginBottom: '15px' }}>
          <Button type='primary' onClick={() => linkToCustomerEdit({}, 'ADD')}>
            <Icon type="plus" />新增客户
          </Button>

          <Button style={{ marginLeft: '10px' }} onClick={() => downloadUploadEndUserTemplate({})}>
            <Icon type="download" />下载模板
          </Button>

          <Upload name='file' showUploadList={false}
            headers={{
              Token: getStore('token')
            }}
            accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            action={beforeuploadEndUser}
            onChange={(info) => changeUploadEndUser(info)}
          >
            <Button style={{ marginLeft: '10px' }}><Icon type="upload" />导入客户</Button>
          </Upload>

          <Button style={{ marginLeft: '10px' }} onClick={() => downloadEndUserList({})}>
            <Icon type="download" />导出客户
          </Button>
        </div>

        <Table rowKey='euId' bordered
          columns={columns}
          dataSource={tableData}
          pagination={pagination}
        />
      </Card>

      {
        addressObj.visiable
          ? <AddressModal
            endUserId={addressObj.endUserId}
            type={addressObj.type}
            detailInfo={addressObj.detailInfo}
            onConfirm={(params) => handleSaveAddress(params)}
            onCancel={() => setAddressObj({ type: 1, visiable: false, detailInfo: {}, endUserId: null })}
          />
          : null
      }
    </>
  )
}

CustomerList.propTypes = {
  history: PropTypes.object
}

export default CustomerList

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Input, Button, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import Api from '@/common/api/index'

import styles from '../index.module.scss'

const FormItem = Form.Item

class EditPersonalForm extends Component {
  static propTypes = {
    form: PropTypes.object,
    activeMenuKey: PropTypes.string,
    type: PropTypes.string,
    endUserInfo: PropTypes.object,
    onSuccess: PropTypes.func
  }

  state = {}

  // 新增客户
  saveAddEnduser = async (params) => {
    const res = await Api.saveAddEnduser(params)
    const { code } = res
    if (+code === 10000) {
      message.success('保存成功')
      this.props.onSuccess()
    } else {
      message.warning(res.message)
    }
  }

  // 保存客户编辑
  saveEditEnduser = async (params) => {
    const res = await Api.saveEditEnduser(params)
    const { code } = res
    if (+code === 10000) {
      message.success('保存成功')
      this.props.onSuccess()
    }
  }

  // 点击保存
  handleSumit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) { return false }
      const { activeMenuKey = '1', type = 'ADD', endUserInfo = {} } = this.props
      const jsencrypt = new JSEncrypt()
      jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])

      values.password = jsencrypt.encrypt('111111')
      values.userType = activeMenuKey

      if (type === 'EDIT') {
        values.euId = endUserInfo.id || ''
        this.saveEditEnduser(values)
      } else {
        this.saveAddEnduser(values)
      }
    })
  }

  render () {
    const { form, endUserInfo = {} } = this.props
    const { getFieldDecorator } = form

    return (
      <>
        <h2 style={{ fontWeight: 'bold' }}>基本信息</h2>
        <Form>
          <Row gutter={180}>
            <Col span={10}>
              <FormItem label={<>真实姓名<span className={`${styles.formLabelDesc}`}>真实姓名只支持中文</span></>} colon={false}>
                {
                  getFieldDecorator('realName', {
                    initialValue: endUserInfo.realname || '',
                    rules: [
                      { required: true, message: '姓名必填' },
                      {
                        validator (rule, value, callback) {
                          if (value && !/^[\u4E00-\u9FA5]{2,4}$/.test(value)) {
                            /* eslint-disable */
                            callback('真实姓名必须为中文')
                            /* eslint-enable */
                          } else {
                            callback()
                          }
                        }
                      }
                    ]
                  })(<Input autoComplete='off' />)
                }
              </FormItem>
            </Col>

            <Col span={10}>
              <FormItem label={<>手机号码<span className={`${styles.formLabelDesc}`}>请输入一个可用于收取验证码的手机号码</span></>} colon={false} >
                {
                  getFieldDecorator('adminPhoneNumber', {
                    initialValue: endUserInfo.phoneNumber || '',
                    rules: [
                      {
                        validator (rule, value, callback) {
                          if (value && !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                            .test(value)) {
                            /* eslint-disable */
                               callback('手机号码格式不正确')
                            /* eslint-enable */
                          } else {
                            callback()
                          }
                        }
                      }
                    ]
                  })(<Input autoComplete='off' />)
                }
              </FormItem>
            </Col>
          </Row>

          <FormItem style={{ marginBottom: '0px' }}>
            <Button type='primary' onClick={() => this.handleSumit()}>保存</Button>
          </FormItem>
        </Form>
      </>
    )
  }
}

const EditPersonal = Form.create()(EditPersonalForm)

export default EditPersonal

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, message } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import VerifyModal from '@/components/verifyModal/index'
import { passReg } from '@/common/utils/filter'
import { hideMobile } from '@/common/utils/mUtils'
import styles from './index.module.scss'

const FormItem = Form.Item
let timer = null

function SettingsForm (props) {
  const { userInfo = {}, onSendSms, onConfirm, onCancel } = props
  const { getFieldDecorator, getFieldValue } = props.form
  const [verifyModal, setVerifyModal] = useState(false)
  const [time, setTime] = useState(60)
  const [btnDisabled, setBtndisabled] = useState(false)

  // 点击保存
  const handleSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) { return false }
      const { newPassword, confirmPassword } = values

      if (confirmPassword !== newPassword) {
        return message.warning('两次输入密码不一致！')
      }

      const jsencrypt = new JSEncrypt()
      jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])
      values.newPassword = jsencrypt.encrypt(newPassword)
      values.confirmPassword = jsencrypt.encrypt(confirmPassword)

      onConfirm(values)
    })
  }

  // 点击发送验证码
  const handleSendSms = () => {
    const phoneNumber = getFieldValue('phoneNumber')
    if (phoneNumber === undefined || phoneNumber === '') {
      return message.warning('未绑定手机， 无法发送验证码')
    }

    setVerifyModal(true)
  }

  // 滑块验证码验证成功
  const handleVerifySlideCheckSuccess = (params) => {
    setVerifyModal(false)
    onSendSms({ ...params, sendSmsCaptchaEnum: 'SMS_CAPTCHA_TO_ADMIN' }).then(res => {
      const { code } = res
      if (+code === 10000) {
        setSatrtInterval()
      }
    })
  }

  // 倒计时
  const countDown = () => {
    if (time === 1) {
      stopInterval()
    } else {
      setBtndisabled(true)
      setTime(t => --t)
    }
  }

  // 开始定时器
  const setSatrtInterval = () => {
    timer = setInterval(() => { countDown() }, 1000)
  }

  // 清除定时器
  const stopInterval = () => {
    clearInterval(timer)
    setBtndisabled(false)
    setTime(60)
  }

  // 点击取消
  const handleCancel = () => {
    props.form.resetFields()
    onCancel()
  }

  return (
    <div className={`${styles.settingsWrap}`}>
      <Form>
        <FormItem label='绑定手机号码' style={{ display: 'none' }}>
          {
            getFieldDecorator('phoneNumber', {
              initialValue: userInfo.loginUserPhone || ''
            })(<Input disabled autoComplete='off' />)
          }
        </FormItem>
        <div className={`${styles.land}`}>
          <div className={`${styles.content}`}>
            <label className={`${styles.label}`}>绑定手机号码为</label><span>{hideMobile(userInfo.loginUserPhone)}</span>
          </div>
          <div className={`${styles.action}`}>
            <Button className={`${styles.sendBtn}`} type='link' disabled={btnDisabled} onClick={() => handleSendSms()}>
              { !btnDisabled ? '获取验证码' : `${time} s 后重新发送` }
            </Button>
          </div>
        </div>
        <FormItem label='验证码' style={{ marginBottom: '0px' }}>
          {
            getFieldDecorator('randomCode', {
              rules: [
                { required: false, message: '请输入验证码' }
              ]
            })(
            <Input autoComplete='off' />
            )
          }
        </FormItem>
        <FormItem label='输入新密码' style={{ marginBottom: '0px' }}>
          {
            getFieldDecorator('newPassword', {
              rules: [
                { required: true, message: '请输入新密码' },
                {
                  validator (rule, value, callback) {
                    if (value && !passReg.test(value)) {
                    /* eslint-disable */
                      callback('登录密码格式不正确')
                      /* eslint-enable */
                    } else {
                      callback()
                    }
                  }
                }
              ]
            })(<Input.Password placeholder='密码长度为6-20位，英文字母或数字' autoComplete='off' />)
          }
        </FormItem>
        <FormItem label='确认新密码'>
          {
            getFieldDecorator('confirmPassword', {
              rules: [
                { required: true, message: '请确认密码' }
              ]
            })(<Input.Password placeholder='请确认密码' autoComplete='off'/>)
          }
        </FormItem>
        <FormItem>
          <Button type='primary' onClick={() => handleSubmit()}>保存</Button>
          <Button style={{ marginLeft: '15px' }} onClick={() => handleCancel()}>取消</Button>
        </FormItem>
      </Form>

      {
        verifyModal
          ? <VerifyModal
            onConfirm={(params) => handleVerifySlideCheckSuccess(params)}
            onCancel={() => setVerifyModal(false)}
          />
          : null
      }
    </div>
  )
}

SettingsForm.propTypes = {
  form: PropTypes.object,
  userInfo: PropTypes.object,
  onSendSms: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default Form.create()(SettingsForm)

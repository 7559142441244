import React, { useState, useEffect } from 'react'

import AddressList from '@/components/addressList/index'

import Api from '@/common/api/index'

function ReceivingAddress (props) {
  const [endUserId, setEndUserId] = useState(0)

  const getPurchaserEndUserId = async () => {
    const res = await Api.getPurchaserEndUserId({})
    const { code, data } = res
    if (+code === 10000) {
      setEndUserId(+data.id)
    }
  }

  // 获取EU客户收件地址列表
  const getEndUserReceivedAddressList = (params) => {
    return Api.getEndUserReceivedAddressList(params)
  }

  // 保存新增EU客户地址
  const saveAddEndUserReceiveAddress = (params) => {
    return Api.saveAddEndUserReceiveAddress(params)
  }

  const saveEditEndUserReceiveAddress = (params) => {
    return Api.saveEditEndUserReceiveAddress(params)
  }

  const deleteEndUserReceivedAddress = (params) => {
    return Api.deleteEndUserReceivedAddress(params)
  }

  useEffect(() => {
    getPurchaserEndUserId()
  }, [])

  return (
    <>
    {
      +endUserId > 0
        ? <AddressList
          ident={'EU'}
          endUserId={endUserId || 0}
          getList={(params) => getEndUserReceivedAddressList(params)}
          onSaveAddress={(params) => saveAddEndUserReceiveAddress(params)}
          onEditAddress={(params) => saveEditEndUserReceiveAddress(params)}
          onDeleteAddress={(params) => deleteEndUserReceivedAddress(params)}
        />
        : null
    }

    </>
  )
}

export default ReceivingAddress

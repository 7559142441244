import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Table, InputNumber, Input, Button, Icon, Tooltip } from 'antd'

import { setEditEnquireDetails } from '@/reducers/action'
import consts from '@/common/utils/consts'
import { manualItem } from '../localdatas'

const { MAX_NUMBER } = consts

class ManualEditableTable extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    editEnquireDetails: PropTypes.object
  }

  state = {}

  columns = [
    {
      title: '品牌',
      key: 'manualEditTableCol-0',
      dataIndex: 'brandName',
      render: (text, record, index) => (
        <>
          <Input autoComplete='off' value={record.brandName}
            onChange={(e) => this.handleChangeInput(e.target.value, index, 'brandName')}
          />
        </>
      )
    },
    {
      title: '型号',
      key: 'manualEditTableCol-1',
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <Input autoComplete='off' value={record.model}
            onChange={(e) => this.handleChangeInput(e.target.value, index, 'model')}
          />
        </>
      )
    },
    {
      title: '订货号',
      key: 'manualEditTableCol-2',
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <Input autoComplete='off' value={record.itemNumber}
            onChange={(e) => this.handleChangeInput(e.target.value, index, 'itemNumber')}
          />
        </>
      )
    },
    {
      title: '产品名称',
      key: 'manualEditTableCol-3',
      dataIndex: 'name',
      render: (text, record, index) => (
        <>
          <Input autoComplete='off' value={record.name}
            onChange={(e) => this.handleChangeInput(e.target.value, index, 'name')}
          />
        </>
      )
    },
    {
      title: '单位',
      key: 'manualEditTableCol-4',
      dataIndex: 'unit',
      render: (text, record, index) => (
        <>
          <Input autoComplete='off' value={record.unit}
            onChange={(e) => this.handleChangeInput(e.target.value, index, 'unit')}
          />
        </>
      )
    },
    {
      title: '数量',
      key: 'manualEditTableCol-5',
      dataIndex: 'quantity',
      render: (text, record, index) => (
        <>
          <InputNumber value={record.quantity} min={0} max={MAX_NUMBER}
            onChange={(value) => this.handleChangeInput(value, index, 'quantity')}
          />
        </>
      )
    },
    {
      title: '期望价格',
      key: 'manualEditTableCol-6',
      width: 130,
      dataIndex: 'expectPrice',
      filterDropdown: (<div />),
      filterIcon: <Tooltip title='此处价格为含税且含运价格'><Icon type='question-circle' style={{ color: '#1890FF' }} /></Tooltip>,
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: '4px' }}>&yen;</span>
          <InputNumber value={record.expectPrice} min={0} max={MAX_NUMBER}
            onChange={(value) => this.handleChangeInput(value, index, 'expectPrice')} />
        </>
      )
    },
    {
      title: '期望折扣',
      key: 'manualEditTableCol-7',
      width: 130,
      dataIndex: 'expectDiscount',
      render: (text, record, index) => (
        <>
          <InputNumber value={record.expectDiscount} min={0} max={100}
            onChange={(value) => this.handleChangeInput(value, index, 'expectDiscount')} />
          <span style={{ marginLeft: '4px' }}>%</span>
        </>
      )
    },
    {
      title: '期望货期',
      key: 'manualEditTableCol-8',
      width: 160,
      dataIndex: 'expectDeliveryPeriodDays',
      filterDropdown: (<div />),
      filterIcon: <Tooltip title='此处货期若填“0”或不填写则是现货'><Icon type='question-circle' style={{ color: '#1890FF' }} /></Tooltip>,
      render: (text, record, index) => (
        <>
          <InputNumber value={record.expectDeliveryPeriodDays} min={0} max={MAX_NUMBER}
            onChange={(value) => this.handleChangeInput(value, index, 'expectDeliveryPeriodDays')} />
          <span style={{ marginLeft: '4px' }}>工作日</span>
        </>
      )
    },
    {
      title: '操作',
      key: 'manualEditTableCol-9',
      width: 120,
      render: (text, record, index) => {
        const { editEnquireDetails = {} } = this.props
        const { manualEnquireProducts = [] } = editEnquireDetails

        return (
          <>
            <Button type='link' style={{ padding: '0px 6px' }} onClick={() => this.handleAddRow(index)}>
              <Icon type='plus' style={{ fontSize: '18px' }} />
            </Button>
            <Button type='link' style={{ padding: '0px 6px' }} disabled={manualEnquireProducts.length < 2} onClick={() => this.handldeDeleteRow(index)}>
              <Icon type='minus' style={{ fontSize: '18px' }} />
            </Button>
          </>
        )
      }
    }
  ]

  componentDidMount () { }

  // 新增手写区域
  handleAddRow = (index) => {
    const { editEnquireDetails = {} } = this.props
    const { manualEnquireProducts = [] } = editEnquireDetails

    manualEnquireProducts.splice(index + 1, 0, { rowKey: `WRITE-${new Date().getTime()}`, ...manualItem })

    this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, manualEnquireProducts: [...manualEnquireProducts] } }))
  }

  // 删除手写区域
  handldeDeleteRow = (index) => {
    const { editEnquireDetails = {} } = this.props
    const { manualEnquireProducts = [] } = editEnquireDetails

    if (manualEnquireProducts.length === 1) { return false }

    manualEnquireProducts.splice(index, 1)

    this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, manualEnquireProducts: [...manualEnquireProducts] } }))
  }

  // 监听输入框输入
  handleChangeInput = (value, index, dataIndex) => {
    const { editEnquireDetails = {} } = this.props
    const { manualEnquireProducts = [] } = editEnquireDetails

    manualEnquireProducts[index][dataIndex] = value

    this.props.dispatch(setEditEnquireDetails({
      editEnquireDetails: {
        ...editEnquireDetails,
        manualEnquireProducts: [...manualEnquireProducts]
      }
    }))
  }

  render () {
    const { editEnquireDetails = {} } = this.props
    const { manualEnquireProducts = [] } = editEnquireDetails

    return (
      <div style={{ marginBottom: '15px' }}>
        <Card title='手写区域' headStyle={{ padding: '0px', borderBottom: 'none' }} bodyStyle={{ padding: '0px' }} bordered={false}>
          <Table rowKey='rowKey' bordered
            dataSource={manualEnquireProducts}
            columns={this.columns}
            pagination={false}
          />
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    editEnquireDetails: state.editEnquireDetails
  }
}

export default connect(mapStateToProps)(ManualEditableTable)

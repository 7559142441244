import Ajax from '../utils/ajax'

export default {
  /* 滑动验证码START */
  // 获取验证图片信息
  getCaptchaVerifyInfo (params) {
    return Ajax.post('/api/captcha/get', params)
  },

  // 校验滑块验证码
  checkCaptchaVerify (params) {
    return Ajax.post('/api/captcha/check', params)
  },
  /* 滑动验证码END */

  // 发送短信验证码(新)
  sendSmsCaptchaAndValidate (params) {
    return Ajax.post('/api/sms/send', params)
  },

  // 企业列表模糊查询
  getFuzzySearchCompanyList (params) {
    return Ajax.post('/api/enterprise/info/fuzzySearch', params)
  },

  // 精确查找企业详细信息
  getCompanyDetailInfo (params) {
    return Ajax.post('/api/enterprise/info/getBasicInfo', params)
  }
}

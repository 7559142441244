import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import $lodash from 'lodash'

function InvoiceProduct (props) {
  const { tableData = [], totalAmount = 0 } = props

  const columns = [
    {
      title: '货物及应税劳资名称',
      key: 'invoiceProductCol-0',
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '规格型号',
      key: 'invoiceProductCol-1',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'invoiceProductCol-2',
      dataIndex: 'itemNumber'
    },
    {
      title: '单位',
      key: 'invoiceProductCol-3',
      width: 80,
      dataIndex: 'unit'
    },
    {
      title: '数量',
      key: 'invoiceProductCol-4',
      dataIndex: 'quantity'
    },
    {
      title: '含税单价',
      key: 'invoiceProductCol-5',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{+record.price}</>
      )
    },
    {
      title: '含税金额',
      key: 'invoiceProductCol-6',
      dataIndex: 'totalPrice',
      render: (text, record, index) => (
        <>&yen;{ ($lodash.round(record.totalPrice, 2) * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '订单编号',
      key: 'invoiceProductCol-7',
      dataIndex: 'orderId'
    }
  ]

  return (
    <Table rowKey={(record, index) => `${record.commoditySkuId}-${index}`} bordered
      columns={columns}
      dataSource={tableData}
      rowClassName={(record, index) => (+record.quantity < 0) ? 'redColor' : null}
      pagination={false}
      footer={() => <div style={{ textAlign: 'right' }}><label className={'label'}>价税合计（小写）</label>&yen;{($lodash.round(+totalAmount, 2) * 100 / 100).toFixed(2)}</div>}
    />
  )
}

InvoiceProduct.propTypes = {
  totalAmount: PropTypes.number,
  tableData: PropTypes.array
}

export default InvoiceProduct

import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Row, Col, Statistic } from 'antd'

export default function SupplyStatistic (props) {
  const { totalAmount = 0, paidAmount = 0, unPaidAmount = 0 } = props

  return (
    <div style={{ marginBottom: '15px' }}>
      <Alert type='info' message={
        <Row gutter={24}>
          <Col span={6}>
            <Statistic title='订单总金额' value={totalAmount}
              precision={2}
              prefix={<>&yen;</>}
              valueStyle={{ color: '#269c42' }}
            />
          </Col>

          <Col span={6}>
            <Statistic title='已付总金额' value={paidAmount}
              precision={2}
              prefix={<>&yen;</>}
              valueStyle={{ color: '#269c42' }}
            />
          </Col>

          <Col span={6}>
            <Statistic title='未付总金额' value={unPaidAmount}
              precision={2}
              prefix={<>&yen;</>}
              valueStyle={{ color: '#269c42' }}
            />
          </Col>
        </Row>
      } />
    </div>
  )
}

SupplyStatistic.propTypes = {
  totalAmount: PropTypes.number,
  paidAmount: PropTypes.number,
  unPaidAmount: PropTypes.number
}

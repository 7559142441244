import React, { useState } from 'react'
import { Card, Tabs } from 'antd'

import UnopenTable from './unopenTable/index'
import OpendTable from './opendTable/index' // 开票历史
// import styles from './index.module.scss'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'INVOICE_OPENING', name: '开票中' },
  { id: 'INVOICE_OPENED', name: '开票完成' }
]

export default function PurchaseInvoice () {
  const [activeKey, setActiveKey] = useState('INVOICE_OPENING')

  return (
    <Card bodyStyle={{ padding: '0px 16px 16px 16px' }} bordered={false}>
      <Tabs onChange={(key) => setActiveKey(key)}>
        {
          tabPaneMaps.map((item) => (
            <TabPane tab={item.name} key={item.id} />
          ))
        }
      </Tabs>

      {
        activeKey === 'INVOICE_OPENING'
          ? <UnopenTable />
          : null
      }

      {
        activeKey === 'INVOICE_OPENED'
          ? <OpendTable />
          : null
      }
    </Card>
  )
}

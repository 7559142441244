import React, { Component } from 'react'
import { Input } from 'antd'
import styles from './balanceland.module.scss'
import PropTypes from 'prop-types'

export default class balanceLand extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    selected: PropTypes.bool,
    type: PropTypes.number,
    onChangepayword: PropTypes.func,
    onSelectbalance: PropTypes.func
  }

  // 监听余额密码
  changeBalancePayWord = (val) => {
    this.setState({
      payword: val
    }, () => {
      this.props.onChangepayword({ password: val })
    })
  }

  render () {
    const { detailInfo = {}, selected = false, type } = this.props
    return (
      <div className={styles.main}>
        <h1>余额支付:</h1>
        <div className={styles.info}>
          <div className={`${styles.useBalance} ${selected ? styles.selected : ''}`}
            onClick={() => this.props.onSelectbalance(selected, +type === 0 ? 'useBalance' : 'useCredit')}
          >
            <span>余额支付</span>{selected ? <i /> : null}
          </div>

          {
            selected
              ? <div className={`${styles.passWrap}`}>
                  <Input.Password className={`${styles.passInput}`}
                    placeholder='请输入6位数字支付密码'
                    autoComplete='off'
                    onChange={(e) => this.props.onChangepayword(e.target.value)}
                  />
                </div>
              : null
          }
        </div>

        {
          selected && +type === 0
            ? <div className={`${styles.landTips}`}>{'余额抵扣'}<span>&yen;{(+detailInfo.deduction * 100 / 100).toFixed(2)}</span>&nbsp;&nbsp;应付<span>&yen;{(+detailInfo.paidAmount * 100 / 100).toFixed(2)}</span>
             </div>
            : null
        }
      </div>
    )
  }
}

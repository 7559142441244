import accountRouter from './accountRouter'
import customerRouter from './customerRouter'
import financeRouter from './financeRouter'
import expressRouter from './expressRouter'
import puechaseRouter from './purchaseRouter'
import enquireRouter from './enquireRouter'
import matchsRouter from './matchsRouter'

export const routes = [
  ...accountRouter,
  ...customerRouter,
  ...financeRouter,
  ...expressRouter,
  ...puechaseRouter,
  ...enquireRouter,
  ...matchsRouter
]

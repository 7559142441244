import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Button, message, Card, Modal } from 'antd'
import $lodash from 'lodash'

import EnquireSearch from './enquireSearch/index' // 搜索栏模块
import AutoEditableTable from './autoEditTable/index' // 自动区域模块
import ManualEditableTable from './manualEditTable/index' // 手写区域模块
import RemarksForm from '../components/formRemarks/index' // 备注
import PreviewDrawer from './previewDrawer/index'
import DeliverySetModal from './deliverySetModal/index' // 设置货期弹窗
import CorrectModal from './correctModal/index' // 纠错弹窗

import Api from '@/common/api/index'
import { setEditEnquireDetails } from '@/reducers/action'
import { manualItem } from './localdatas'
import consts from '@/common/utils/consts'

const { confirm, warning } = Modal
const { ENQUIRE_TYPE } = consts

class CreateEnquireForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    history: PropTypes.object,
    form: PropTypes.object,
    editEnquireDetails: PropTypes.object
  }

  state = {
    drawerModal: false, // 预览报价单弹窗
    deliveryModal: false, // 设置货期弹窗
    correctModal: false, // 纠错弹窗
    errorKeyword: null, // 纠错关键字
    searchResultEnum: null, // 纠错状态
    activeCorrectKey: null // 当前纠错的rowKey
  }

  componentDidMount () {
    const { history, editEnquireDetails = {} } = this.props
    const { state } = history.location

    if (state && state.pageFrom && state.pageFrom === 'enquireMatchDetails') {
      const { manualEnquireProducts = [] } = editEnquireDetails
      const i = manualEnquireProducts.findIndex((o) => { return o.rowKey === 'WRITE-0' })

      if (i === -1) {
        this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, manualEnquireProducts: [...manualEnquireProducts, { rowKey: 'WRITE-0', ...manualItem }] } }))
      }
    } else {
      this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { autoEnquireProducts: [], manualEnquireProducts: [{ rowKey: 'WRITE-0', ...manualItem }] } }))
    }
  }

  /* 搜索产品
   * 重复搜索 - 1.若已收录 则合并数据 数量+1 2-若未收录 则列表增加一行-纠错
   * 重新创建rowKey
  */
  getEnquireSearchProductList = async (params) => {
    const res = await Api.getEnquireSearchProductList(params)
    const { code, data = [] } = res
    if (+code === 10000) {
      const { editEnquireDetails = {} } = this.props
      const { autoEnquireProducts = [] } = editEnquireDetails

      if (data && data.length > 0) {
        data.forEach((item) => {
          const n = $lodash.findIndex(autoEnquireProducts, (o) => { return item.searchResultEnum === 'SUCCESS' && item.commoditySkuId === o.commoditySkuId })

          if (n > -1) {
            autoEnquireProducts[n].quantity += item.quantity
          } else {
            autoEnquireProducts.push({
              ...item,
              dataType: 'SEARCH',
              matchingState: item.searchResultEnum === 'SUCCESS' ? 'MATCHED' : 'UNMATCHED'
            })
          }
        })
      }

      autoEnquireProducts.forEach((item, i) => {
        item.rowKey = item.commoditySkuId && +item.commoditySkuId > 0 ? `SEARCH-${item.commoditySkuId}` : `SEARCH-${item.model}-${i}`
      })

      this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, autoEnquireProducts } }))
    }
  }

  // 保存新增询价单
  saveAddEnquireOrder = async (params) => {
    const res = await Api.saveAddEnquireOrder(params)
    const { code } = res
    if (+code === 10000) {
      this.closeModal()

      setTimeout(() => {
        this.props.form.resetFields()
        this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { manualEnquireProducts: [{ rowKey: 'WRITE-0', ...manualItem }] } }))
      }, 20)

      this.countDown()
    }
  }

  // 更新询价单
  updateEnquireOrder = async (params) => {
    const res = await Api.updateEnquireOrder(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')

      this.closeModal()
    }
  }

  // 点击保存草稿、预览询价单
  handlePreviewEnquire = (ident) => {
    const { form, editEnquireDetails = {} } = this.props
    const { autoEnquireProducts = [], manualEnquireProducts = [] } = editEnquireDetails

    form.validateFields((err, values) => {
      if (!err) {
        if (ident === ENQUIRE_TYPE) { // 预览询价单
          const newEnquireProducts = $lodash.filter([...autoEnquireProducts, ...manualEnquireProducts], (o) => { return o.brandName || o.model || o.itemNumber || o.name || o.unit || o.quantity || o.expectPrice || o.expectDiscount || o.expectDeliveryPeriodDays })
          if (newEnquireProducts.length === 0) {
            warning({
              title: '请至少添加一条有效数据~~'
            })

            return false
          }

          this.props.dispatch(setEditEnquireDetails({
            editEnquireDetails: {
              ...editEnquireDetails,
              remarks: values.remarks,
              distributorId: values.distributorId
            }
          }))

          setTimeout(() => {
            this.setState({
              drawerModal: true
            })
          }, 30)
        }
      }
    })
  }

  // 点击设置货期规则弹窗
  showDeliveryModal = () => {
    this.setState({
      deliveryModal: true
    })
  }

  // 设置货期规则
  handleSetDeliveryPeriodDays = (days) => {
    const { editEnquireDetails = {} } = this.props
    const { autoEnquireProducts = [], manualEnquireProducts = [] } = editEnquireDetails

    autoEnquireProducts.forEach((item) => { item.expectDeliveryPeriodDays = days })
    manualEnquireProducts.forEach((item) => { item.expectDeliveryPeriodDays = days })

    this.props.dispatch(setEditEnquireDetails({
      editEnquireDetails: {
        ...editEnquireDetails,
        autoEnquireProducts: [...autoEnquireProducts],
        manualEnquireProducts: [...manualEnquireProducts]
      }
    }))

    this.closeModal()
  }

  // 点击纠错按钮
  showCorrectModal = (record) => {
    this.setState({
      errorKeyword: record.model,
      searchResultEnum: record.searchResultEnum,
      activeCorrectKey: record.rowKey,
      correctModal: true
    })
  }

  // 点击确定纠错
  handleConfirmCorrectModal = (values) => {
    const { editEnquireDetails = {} } = this.props
    const { activeCorrectKey = null } = this.state
    const { autoEnquireProducts } = editEnquireDetails

    const i = autoEnquireProducts.findIndex((o) => { return o.rowKey === activeCorrectKey })

    if (i > -1) {
      autoEnquireProducts[i] = { rowKey: activeCorrectKey, ...values, dataType: 'SEARCH', matchingState: 'MATCHED' }
    }

    this.setState({
      activeCorrectKey: null,
      errorKeyword: null,
      searchResultEnum: null,
      correctModal: false
    }, () => {
      this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: { ...editEnquireDetails, autoEnquireProducts: [...autoEnquireProducts] } }))
    })
  }

  // 点击取消编辑询价单
  handleCancel = () => {
    confirm({
      title: '确定取消？',
      onOk: () => {
        this.props.dispatch(setEditEnquireDetails({ editEnquireDetails: {} }))

        setTimeout(() => {
          this.props.history.push({ pathname: '/enquire/enquireOrderList' })
        }, 20)
      }
    })
  }

  // 倒计时
  countDown = () => {
    let secondsToGo = 5

    const modal = Modal.success({
      title: '操作成功',
      content: `${secondsToGo}s后将返回列表页`,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        this.props.history.push({ pathname: '/enquire/enquireOrderList' })
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: `${secondsToGo}s后将返回列表页`
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      this.props.history.push({ pathname: '/enquire/enquireOrderList' })
    }, secondsToGo * 1000)
  }

  closeModal = () => {
    this.setState({
      drawerModal: false,
      deliveryModal: false,
      correctModal: false
    })
  }

  render () {
    const { drawerModal = false, deliveryModal = false, correctModal = false, errorKeyword = '', searchResultEnum = null } = this.state

    return (
      <Card bodyStyle={{ padding: '16px' }} bordered={false}>
        <EnquireSearch {...this.props}
          onSearch={(params) => this.getEnquireSearchProductList(params)}
        />

        <AutoEditableTable
          onShowDeliveryModal={() => this.showDeliveryModal()}
          onCorrectModal={(record) => this.showCorrectModal(record)}
        />

        <ManualEditableTable />

        <div>
          <RemarksForm {...this.props} />
        </div>

        <div style={{ marginTop: '24px', textAlign: 'center' }}>
          <Button type='danger' size='large' onClick={() => this.handleCancel()}>取消</Button>
          <Button type='primary' size='large' style={{ marginLeft: '16px' }} onClick={() => this.handlePreviewEnquire(ENQUIRE_TYPE)}>预览询价</Button>
        </div>

        <PreviewDrawer
          visible={drawerModal}
          onCancel={() => this.closeModal()}
          onConfirm={(params) => this.saveAddEnquireOrder(params)}
        />

        {
          deliveryModal
            ? <DeliverySetModal
              onConfirm={(day) => this.handleSetDeliveryPeriodDays(day)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          correctModal
            ? <CorrectModal
              searchResultEnum={searchResultEnum}
              keyword={errorKeyword}
              correctSource='ENQUIRE_CORRECT'
              onCancel={() => this.closeModal()}
              onConfirm={(record) => this.handleConfirmCorrectModal(record)}
            />
            : null
        }
      </Card>
    )
  }
}

const CreateEnquireOrder = Form.create()(CreateEnquireForm)

const mapStateToProps = (state) => {
  return {
    editEnquireDetails: state.editEnquireDetails
  }
}

export default connect(mapStateToProps)(CreateEnquireOrder)

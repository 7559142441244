import Ajax from '../utils/ajax'

export default {
  // 企业账户余额
  getEnterpriseBalance (params) {
    return Ajax.get('/api/finance/get/distributor/balance', { params })
  },

  // 获取企业余额流水
  getEnterpriseBalanceFlow (params) {
    return Ajax.post('/api/finance/list/page/distributor/balance/flow', params)
  },

  // 企业余额提现申请
  saveCashoutApply (params) {
    return Ajax.post('/api/finance/pay/cashOut', params)
  },

  // 获取IIASaaS快递服务运费对账单
  getTransportBill (params) {
    return Ajax.post('/api/logistics/deppon/bill/listPage', params)
  },

  // 查询IIASaaS快递服务运费流水
  getTransportBillDetail (params) {
    return Ajax.post('/api/logistics/deppon/bill/listPageFlow', params)
  }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Modal, message } from 'antd'
import $lodash from 'lodash'

import CartSteps from '../components/cartSteps/index'
import PayMoeny from './payMoeny'
import TableList from './tableList'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

const { downCartExport } = DownloadApi

class Cart extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state = {
    tableList: [],
    selectedTotalValues: { goods: 0, nums: 0, amount: 0 },
    FootCkBox: false,
    discernModal: false
  }

  componentDidMount () {
    this.getCartList()
  }

  // 获取购物车列表
  getCartList = async () => {
    const res = await Api.getCartList({})
    const { code, data } = res
    if (+code === 10000) {
      data.forEach(item => {
        item.checked = false
        item.supplyInfoList.forEach(obj => {
          obj.checked = true
        })
      })

      this.setState({
        tableList: data || []
      }, () => { this.calcSelectedAmount() })
    }
  }

  // 单价选择
  supplyCheck = (e, ii, i) => {
    const { tableList } = this.state
    tableList[i].supplyInfoList[ii].checked = e.target.checked
    tableList[i].supplyInfoList[ii].quantity = tableList[i].supplyInfoList[ii].quantity ? tableList[i].supplyInfoList[ii].quantity : 1

    this.setState({
      tableList
    }, () => {
      this.calcSelectedAmount()
    })
  }

  // 监听数量变化
  handleInValue=(value, ii, i) => {
    const { tableList } = this.state
    tableList[i].supplyInfoList[ii].quantity = value

    this.setState({
      tableList
    }, () => {
      this.calcSelectedAmount()
    })
  }

  // 商品选择按钮
  handleGoodsCkB=(e, i) => {
    const { tableList } = this.state
    tableList[i].checked = e.target.checked
    const a = $lodash.findIndex(tableList, item => { return item.checked === false })

    this.setState({
      tableList,
      FootCkBox: !(a > -1)
    }, () => {
      this.calcSelectedAmount()
    })
  }

  // 全选按钮
  FootCheckBox = (e) => {
    const { tableList } = this.state
    tableList.forEach(record => {
      if (record.onSaleStatus !== 'DISABLE') {
        record.checked = e.target.checked
      }
    })

    this.setState({
      FootCkBox: e.target.checked,
      tableList: tableList
    }, () => { this.calcSelectedAmount() })
  }

  // 全选选中删除
  handleMdel = () => {
    const { tableList } = this.state
    const cartIdList = []

    tableList.forEach(item => {
      if (item.checked === true) {
        item.supplyInfoList.forEach(item => {
          cartIdList.push(item.cartId)
        })
      }
    })

    if (cartIdList.length === 0) {
      Modal.warning({
        title: '至少选中一个哦!'
      })

      return false
    }

    Modal.confirm({
      title: '确定删除商品？',
      okText: '确认',
      onOk: () => { this.GetDelGoods({ cartIdList: cartIdList }) }
    })
  }

  // 全选删除产品
  GetDelGoods = (params) => {
    const { tableList } = this.state
    const lenlist = $lodash.filter(tableList, item => { return item.checked === false })

    this.getCartListremove(params, lenlist, 1)
  }

  // 单个删除商品
  handleDelList = (i, record) => {
    const cartIdList = []
    record.supplyInfoList.forEach(item => {
      cartIdList.push(item.cartId)
    })

    if (cartIdList.length > 0) {
      Modal.confirm({
        title: '确认删除吗？',
        onOk: () => {
          this.getCartListremove({ cartIdList: cartIdList }, i, 0)
        }
      })
    }
  }

  // 删除产品
  getCartListremove = (param, list, type) => {
    const { tableList } = this.state
    Api.getCartListremove(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        if (type === 0) {
          tableList.splice(list, 1)
        }
        this.setState({
          tableList: type === 0 ? tableList : list
        }, () => {
          this.calcSelectedAmount()
          message.success('删除成功')
        })
      } else {
        message.success('删除成功')
      }
    })
  }

  // 计算选择商品数和总件数
  calcSelectedAmount = () => {
    const { tableList = [] } = this.state
    const obj = { goods: 0, nums: 0, amount: 0 }

    // 过滤未选择的产品
    const datas = $lodash.filter(tableList, (record) => { return record.checked })

    obj.goods = datas.length

    $lodash.forEach(datas, (record) => {
      const { supplyInfoList = [] } = record

      /* 过略掉未选中的价格和没填写数量的数据 */
      const newArr = $lodash.filter(supplyInfoList, (stem) => { return stem.checked && +stem.quantity > 0 })

      if (newArr.length > 0) {
        obj.nums += $lodash.sumBy(newArr, 'quantity')
        obj.amount += $lodash.sumBy(newArr, (atem) => { return +($lodash.round(+atem.price * +atem.quantity, 2)) })
      }
    })

    this.setState({
      selectedTotalValues: obj
    })
  }

  // 结算
  GoCartsResult = () => {
    const { tableList } = this.state
    const eer = $lodash.filter(tableList, item => { return item.checked === true })

    if (tableList.length <= 0 || eer.length <= 0) {
      Modal.warning({
        title: '请先选择商品'
      })
      return false
    }

    for (const record of eer) {
      const { supplyInfoList } = record
      const newArr = $lodash.filter(supplyInfoList, (item) => { return item.checked && item.quantity > 0 })

      if (newArr.length === 0) {
        Modal.warning({
          title: <>订货号:&nbsp;{record.itemNumber}&nbsp;请选择产品价格并且填写数量</>,
          maskClosable: true
        })

        return false
      }
      for (const row of newArr) {
        if (+row.price === 0) {
          Modal.warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;为价格面议产品，详情请咨询客服</>,
            maskClosable: true
          })

          return false
        }

        if (record.skuGoodsType === 'CLOUD_SPOT' && +row.quantity > +row.stock) {
          Modal.warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;现货库存不足</>,
            maskClosable: true
          })
          return false
        }

        if (record.skuGoodsType === 'CLOUD_SPOT' && +row.quantity < +row.minOrder) {
          Modal.warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;最少购买{+row.minOrder}件</>,
            maskClosable: true
          })

          return false
        }
      }
    }

    const paramlist = []
    eer.forEach(item => {
      const paramObj = {
        commoditySkuId: item.commoditySkuId,
        skuGoodsType: item.skuGoodsType,
        quantityInfoList: this.Getitem(item.supplyInfoList)
      }
      paramlist.push(paramObj)
    })
    const goodsInfoList = $lodash.filter(paramlist, item => { return item.quantityInfoList.length !== 0 })
    if (goodsInfoList.length > 0) {
      this.getCartListpreview(paramlist)
    } else {
      Modal.warning({
        title: '请先选择商品'
      })
    }
  }

  Getitem = (supplyInfoList) => {
    const list = []
    supplyInfoList.forEach(item => {
      if (item.checked === true) {
        const obj = {
          cartId: item.cartId,
          supplierId: item.supplierId,
          quantity: item.quantity
        }
        list.push(obj)
      }
    })
    return list
  }

  getCartListpreview = (goodsInfoList) => {
    Api.getCartListpreview({ goodsInfoList }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.props.history.push({ pathname: '/purchase/orderSubmit', state: { data } })
      }
    })
  }

  // 导出报价单
  handleCartsExport = () => {
    const { tableList = [] } = this.state
    const newdatas = $lodash.filter(tableList, item => { return item.checked === true })

    if (newdatas.length === 0) {
      Modal.warning({
        title: '至少选中一种商品哦!'
      })

      return false
    }

    const skuIdQuantityList = []
    for (const record of newdatas) {
      const { supplyInfoList } = record
      const newArr = $lodash.filter(supplyInfoList, (item) => { return item.checked && +item.quantity > 0 })
      if (newArr.length === 0) {
        Modal.warning({
          title: <>订货号:&nbsp;{record.itemNumber}&nbsp;请选择产品价格并且填写有效数量</>,
          maskClosable: true
        })
        return false
      }

      for (const row of newArr) {
        if (record.skuGoodsType === 'CLOUD_SPOT' && +row.quantity > +row.stock) {
          Modal.warning({
            title: <>订货号:&nbsp;{record.itemNumber}&nbsp;现货库存不足</>,
            maskClosable: true
          })

          return false
        }
      }
    }

    newdatas.forEach((item, i) => {
      item.supplyInfoList.forEach((obj, i) => {
        if (obj.checked === true) {
          const a = {
            commoditySkuId: item.commoditySkuId,
            quantity: obj.quantity,
            price: obj.price,
            skuGoodsType: item.skuGoodsType,
            supplierId: obj.supplierId,
            period: item.skuGoodsType === 'CLOUD_FUTURES' ? `${obj.deliveryPeriodDays}工作日` : '当天发货',
            deliveryPeriodDays: item.skuGoodsType === 'CLOUD_FUTURES' ? `${obj.deliveryPeriodDays}工作日` : '当天发货',
            prepaymentRatio: +obj.prepaymentRatio // 预付比例
          }

          skuIdQuantityList.push(a)
        }
      })
    })
    downCartExport({ skuIdQuantityList: skuIdQuantityList })
  }

  render () {
    const { FootCkBox, tableList, selectedTotalValues = {} } = this.state
    const disabled = !(tableList.length > 0)

    return (
      <Card bodyStyle={{ padding: '16px' }} bordered={false}>
        <CartSteps type={1} />

        {/* 表格数据 */}
        <div style={{ marginTop: '24px' }}>
          <TableList
            disabled={disabled}
            FootCkBox={FootCkBox}
            tableList={tableList}
            FootCheckBox={(e) => { this.FootCheckBox(e) }}
            handleGoodsCkB={(e, i) => { this.handleGoodsCkB(e, i) }}
            supplyCheck={(e, ii, i) => { this.supplyCheck(e, ii, i) }}
            handleInValue={(value, ii, i) => { this.handleInValue(value, ii, i) }}
            handleDelList={(i, item) => { this.handleDelList(i, item) }}
          />
        </div>

        {/* 结算 */}
        <PayMoeny
          disabled={disabled}
          FootCkBox={FootCkBox}
          totalValues={selectedTotalValues}
          FootCheckBox={(e) => { this.FootCheckBox(e) }}
          handleMdel={() => { this.handleMdel() }}
          GoCartsResult={() => { this.GoCartsResult() }}
          handleCartsExport = {() => { this.handleCartsExport() }}
        />
      </Card>
    )
  }
}

export default Cart

import React from 'react'
import PropTypes from 'prop-types'
import { Table, Badge } from 'antd'

import { billStatusMaps } from '@/common/utils/filter'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

function BillTable (props) {
  const { tableData = [] } = props

  const renderInsuranceStatusLabel = (status) => {
    const obj = billStatusMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? <Badge status={obj.badge} text={<span style={{ fontSize: '12px' }}>{obj.name}</span>} /> : '----'

    return label
  }

  const columns = [
    {
      title: '序号',
      key: 'paninsuranceBill-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '账单日期',
      key: 'paninsuranceBill-1',
      dataIndex: 'dueMonth'
    },
    {
      title: '账单状态',
      key: 'paninsuranceBill-2',
      render: (text, record, index) => (
        <>{ renderInsuranceStatusLabel(record.status) } </>
      )
    },
    {
      title: '应付金额',
      key: 'paninsuranceBill-3',
      render: (text, record, index) => (
        <>&yen;{ (+record.totalAmount * 100 / 100).toFixed(2) }</>
      )
    },
    {
      title: '已付金额',
      key: 'paninsuranceBill-4',
      render: (text, record, index) => (
        <>&yen;{(+record.payAmount * 100 / 100).toFixed(2)} </>
      )
    },
    {
      title: '未付金额',
      key: 'paninsuranceBill-5',
      render: (text, record, index) => (
        <>&yen;{ ((+record.totalAmount - (+record.payAmount)) * 100 / 100).toFixed(2) }</>
      )
    }
  ]

  return (
    <>
      <Table rowKey='id' bordered
        columns={columns}
        dataSource={tableData}
        pagination={false}
      />
    </>
  )
}

BillTable.propTypes = {
  tableData: PropTypes.array
}

export default BillTable

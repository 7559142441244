import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, message, Modal } from 'antd'
import JSEncrypt from 'jsencrypt'
import PublickeyJson from '@/assets/json/publicKey.json'

import CartSteps from '../components/cartSteps/index'
import BalanceLand from '../components/balanceLand'
import BankLand from '../components/bankLand'

import Api from '@/common/api/index'
import styles from './index.module.scss'

export default class index extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    totalPayAmount: 0, // 商品总金额
    balanceInfo: {}, // 账户余额信息
    hasPayword: false, // 是否设置支付密码
    payword: '', // 支付密码
    useBalance: false // 是否使用余额支付
  }

  componentDidMount () {
    const { orderId, prepaidAmount = 0 } = this.props.history.location.state

    this.setState({
      orderId: orderId,
      totalPayAmount: prepaidAmount
    }, () => {
      this.getEnterpriseBalance()
      this.getHasPayPassword()
    })
  }

  // 获取账户余额信息
  getEnterpriseBalance = () => {
    Api.getEnterpriseBalance({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          balanceInfo: data
        })
      }
    })
  }

  // 查询是否有支付密码
  getHasPayPassword = () => {
    Api.getHasPayPassword({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          hasPayword: data || false
        })
      }
    })
  }

  // 获取输入的支付密码
  onChangePayword = (password) => {
    this.setState({
      payword: password
    })
  }

  // 动态计算应支付
  calcPayAmount = () => {
    const { useBalance, totalPayAmount = 0, balanceInfo = {} } = this.state
    let total = 0

    if (+balanceInfo.totalBalance > 0 && useBalance) {
      total = balanceInfo.totalBalance > totalPayAmount ? 0 : totalPayAmount - balanceInfo.totalBalance
    } else {
      total = totalPayAmount
    }

    return total
  }

  // 计算余额抵扣
  calcBalanceDeduction = () => {
    const { useBalance = false, totalPayAmount = 0, balanceInfo = {} } = this.state
    let deduction = 0

    if (+balanceInfo.totalBalance > 0 && useBalance) {
      deduction = +balanceInfo.totalBalance > (+totalPayAmount) ? totalPayAmount : totalPayAmount - +balanceInfo.totalBalance
    }

    return deduction
  }

  // 点击确认
  handleSubmit = () => {
    const { orderId, payword, useBalance = false, totalPayAmount = 0 } = this.state

    if (useBalance && payword === '') { return message.warning('请输入支付密码') }

    const jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(PublickeyJson[PATH_ENV])

    if (useBalance === true) { // 使用余额且余额能抵扣本次应付金额
      const params = {
        amount: totalPayAmount,
        password: jsencrypt.encrypt(payword),
        orderId: orderId
      }
      this.balancePayPurchOrder(params)
    } else {
      this.props.history.push({ pathname: '/purchase/orderTransferDetails', state: { paidAmount: this.calcPayAmount(), orderId: orderId } })
    }
  }

  // 余额支付云采购订单
  balancePayPurchOrder = (params) => {
    const { orderId } = this.state

    Api.balancePayPurchOrder(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        message.success('支付成功')
        this.props.history.push({ pathname: '/purchase/orderTransferDetails', state: { paidAmount: this.calcPayAmount(), orderId: orderId } })
      }
    })
  }

  /* 点击下载合同
  *  判断是否有合同编号 如果有直接下载
  *  2- 没有合同 弹窗 是否自定义
  */
  handleDownloadOpen = () => {
    const { orderId } = this.state
    this.submitCustractNumber({ orderId: orderId })
  }

  /* 监听使用余额支付
   *   是否设置支付密码
   *   1- 有密码 输入支付密码
   *   2- 无密码 弹窗提示
  */
  onSelectUseBalance = (status = false, type) => {
    const { hasPayword = false } = this.state

    if (status && !hasPayword) {
      Modal.confirm({
        title: '温馨提示',
        content: (<div className={`${styles.messPop}`}>
          <p>您还没有设置支付密码</p>
        </div>)

      })
      return false
    }

    this.setState({
      [type]: !status
    })
  }

  // 生成合同
  submitCustractNumber = (params) => {
    Api.submitCustractNumber(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (!data || data === '') {
          return message.warning('暂无可下载的合同链接')
        }

        window.open(data)
      } else {
        message.warning(res.message)
      }
    })
  }

  render () {
    const { totalPayAmount = 0, balanceInfo, useBalance } = this.state
    const { state } = this.props.history.location

    return (
      <Card bodyStyle={{ padding: '16px' }} bordered={false}>
        <CartSteps type={3} />

        <div style={{ marginTop: '24px' }}>
          {/* 提示 */}
          <div className={styles.tips}>
            <div>订单提交成功！请尽快完成支付。</div>
             <div>请在<span className={styles.title}>48小时</span>内完成支付，超时后将取消订单 </div>
           </div>

         {/* 账户余额 */}
          <div className={styles.costmoney}>
            <div className={styles.costLeft}>
              <div className={styles.leftR} />
              <div className={styles.leftM}>
                <div style={{ fontSize: '16px', color: '#333333', fontWeight: '400', fontFamily: 'Microsoft YaHei' }}>账户余额</div>
                <div style={{ fontFamily: 'Arial', fontSize: '16px', fontWeight: 'bold' }}>&yen;{(+balanceInfo.totalBalance).toFixed(2)}</div>
              </div>
            </div>

            <div className={styles.costRight}>
              <div>应付金额：<span className={styles.Rightspan}>&yen;{(+totalPayAmount * 100 / 100).toFixed(2)}</span></div>
              <div>已付金额：<span className={styles.Rightspan}>&yen;0.00</span></div>
            </div>
          </div>

          {/* 选择支付方式 */
            +balanceInfo.totalBalance > 0
              ? <BalanceLand
                type={0}
                detailInfo={{ deduction: this.calcBalanceDeduction(), paidAmount: this.calcPayAmount() }}
                selected={useBalance}
                onChangepayword={(password) => this.onChangePayword(password)}
                onSelectbalance={(status, type) => this.onSelectUseBalance(status, type)}
              />
              : null
          }

          {/* 银行支付 */}
          <BankLand />

          <div className={styles.footBtn}>
            <Button onClick={() => this.handleDownloadOpen(state.orderId)}>下载合同</Button>
            <Button type='primary' onClick={() => { this.handleSubmit() }}>确认</Button>
          </div>
        </div>
      </Card>
    )
  }
}

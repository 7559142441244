import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { Spin } from 'antd'
import { getStore } from './common/utils/mUtils'

import Login from './pages/login/index.jsx'
import BasicLayout from './components/basicLayout/index.jsx'
import CommodityDetail from './pages/purchase/cloudproduct-detail/index'

function App () {
  return (
    <>
      <Router>
        <Switch>
          <Route
            exact
            path='/'
            component={props => {
              const token = getStore('token')
              return token ? <Redirect to='/account/personal' /> : <Login {...props} />
            }}
          />
          <Route path='/commodity-detail' component={CommodityDetail} />
          <Route path='/'
            component={props => {
              const token = getStore('token')
              return token ? <BasicLayout {...props} /> : <Redirect to='/' />
            }}
          />
          <Route path='/login' component={Login} />
        </Switch>
      </Router>

      <div className={'loading-wrapper'} id='loadingWrapper'>
        <Spin size='large' />
      </div>
    </>
  )
}

export default App

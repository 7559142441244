import React, { } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Button } from 'antd'

import styles from './index.module.scss'

function PayMoeny (props) {
  const { disabled, FootCkBox, totalValues = {}, handleCartsExport, FootCheckBox, handleMdel, GoCartsResult } = props

  return (
    <div className={styles.GoodsAccount}>
      <span>
        <span className={`${styles.FootCK}`} ><Checkbox disabled={disabled} checked={FootCkBox } onChange={(e) => { FootCheckBox(e) }} /></span>
        <span className={styles.FootM}>全选</span>
      <span className={styles.removeMain} onClick={() => { handleMdel() }}>删除选中</span>
      </span>
      <span className={styles.FootOp}>
        <span>已选中{ totalValues.goods }种商品,共{ totalValues.nums }件</span>
        <span>总价（不含运费）</span>
        <span className={styles.FootMoney}>&yen;{ (+totalValues.amount).toFixed(2) }</span>
        <span className={styles.FootBtn}>
          <span style={{ margin: '0px 10px' }}><Button type='primary' onClick={() => { handleCartsExport() }}>导出报价单</Button></span>
          <span><Button type='primary' onClick={() => { GoCartsResult() }}>结算</Button></span>
        </span>
      </span>

    </div>
  )
}

PayMoeny.propTypes = {
  disabled: PropTypes.bool,
  FootCkBox: PropTypes.bool,
  totalValues: PropTypes.object,
  FootCheckBox: PropTypes.func,
  handleMdel: PropTypes.func,
  handleCartsExport: PropTypes.func,
  GoCartsResult: PropTypes.func
}

export default PayMoeny

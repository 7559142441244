import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, Button, Table, Modal, Row, message } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import PurchdetailModal from '../components/purchdetail-modal'
import PaymentModal from '../components/payment-modal'
import DetailModal from './detailModal/index'
import SaledDrawer from './saledDrawer/index'
import OrderStatistic from './orderStatistic/index'
import OrderEnclosureDrawer from '../components/orderEnclosureDrawer/index'

import Api from '@/common/api/index'

import { pageSizeOptions, orderPaymentStatusMaps, orderDeliveryStatusMaps, cloudPurchOrderStatusMaps } from '@/common/utils/filter'
import { renderOrderPaymentStatusLabel, renderOrderDeliveryStatusLabel, renderAddressLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

export default class cloudOrder extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  state={
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    summary: {}, // 统计信息
    purchDetailModal: false,
    rowDetail: {},
    expandedRowKeys: [],
    balanceInfo: {}, // 企业账户余额信息
    paymentModal: false,
    detailModal: false,
    saledModal: false, // 申请售后弹窗
    enclosureModal: false,
    searchData: [
      {
        name: 'orderTime',
        formType: 'RangePicker',
        itemParams: {
          label: '订单日期'
        },
        cptParams: {

        }
      },
      {
        name: 'orderId',
        formType: 'Input',
        itemParams: {
          label: '订单编号'
        },
        cptParams: {
          placeholder: '请输入...',
          autoComplete: 'off'
        }
      },
      {
        name: 'paymentStatus',
        formType: 'Select',
        itemParams: {
          label: '付款状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderPaymentStatusMaps
        ]
      },
      {
        name: 'deliveryStatus',
        formType: 'Select',
        itemParams: {
          label: '发货状态'
        },
        cptParams: {
          placeholder: '请选择...'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...orderDeliveryStatusMaps
        ]
      },
      {
        name: 'orderStatusList',
        formType: 'Select',
        itemParams: {
          label: '订单状态'
        },
        cptParams: {
          placeholder: '请选择...',
          mode: 'multiple'
        },
        options: [
          { id: 'ALL', name: '全部' },
          ...cloudPurchOrderStatusMaps
        ]
      }
    ]
  }

  columns = [
    {
      title: '序号',
      key: 'cloudpurchaseOrderCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '订单日期',
      key: 'cloudpurchaseOrderCol-1',
      dataIndex: 'orderTime',
      render: (text, record, index) => (
        <>
          {
            record.orderTime
              ? <>{moment(record.orderTime).format('YYYY-MM-DD HH:mm:ss')}</>
              : null
          }

        </>
      )
    },
    {
      title: '订单编号',
      key: 'cloudpurchaseOrderCol-2',
      dataIndex: 'orderId'
    },
    {
      title: '订单金额',
      key: 'cloudpurchaseOrderCol-3',
      dataIndex: 'payableAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.payableAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '已付金额',
      key: 'cloudpurchaseOrderCol-4',
      dataIndex: 'paidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.paidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '未付金额',
      key: 'cloudpurchaseOrderCol-5',
      dataIndex: 'unpaidAmount',
      render: (text, record, index) => (
        <>&yen;{(+record.unpaidAmount * 100 / 100).toFixed(2)}</>
      )
    },
    {
      title: '付款状态',
      key: 'cloudpurchaseOrderCol-6',
      dataIndex: 'paymentStatus',
      render: (text, record, index) => (
        <>{ renderOrderPaymentStatusLabel(record.paymentStatus) }</>
      )
    },
    {
      title: '发货状态',
      key: 'cloudpurchaseOrderCol-7',
      dataIndex: 'deliveryStatus',
      render: (text, record, index) => (
        <>{ renderOrderDeliveryStatusLabel(record.deliveryStatus) }</>
      )
    },
    {
      title: '订单状态',
      key: 'cloudpurchaseOrderCol-8',
      dataIndex: 'orderStatus',
      render: (text, record, index) => (
        this.renderOrderStatusLabel(record.orderStatus)
      )
    },
    {
      title: '订单附件',
      key: 'cloudpurchaseOrderCol-9',
      render: (text, record, index) => (
        <>
          <Button type='primary' size='small' onClick={() => this.showOrderEnclosureModal(record)}>点击查看</Button>
        </>
      )
    },
    {
      title: '操作',
      key: 'cloudpurchaseOrderCol-10',
      width: 300,
      render: (text, record, index) => {
        return (
          <div>
            {
              record.orderStatus === 'WAIT_TO_RECEIVE'
                ? <Button type='link' size='small' onClick={() => this.handleGoodsConfirm(record.orderId)}>确认收货</Button>
                : null
            }

            <Button type='link' size='small' onClick={() => this.handleShowPurchDetail(record)}>查看明细</Button>

            {
              record.orderStatus === 'PAYING' || record.orderStatus === 'TAIL_PAYING'
                ? <Button type='link' size='small' onClick={() => this.handleFinancePay(record)}>付款</Button>
                : null
            }

            <Button type='link' size='small' onClick={() => this.handleDownloadOpen(record)}>
              下载合同
            </Button>

            {
              record.orderStatus === 'ORDER_COMPLETE'
                ? <Button type='link' size='small' disabled={!record.afterSaleDetail.isCompleted} onClick={() => this.showSaledFormModal(record)}>
                  {
                    record.afterSaleDetail && record.afterSaleDetail.isCompleted
                      ? <>申请售后</>
                      : <>售后中</>
                  }
                </Button>
                : null
            }

          </div>
        )
      }
    }
  ]

  componentDidMount () {
    this.getCloudPurchOrderList()
    this.getTradeOrderAmountSummary()
    this.getEnterpriseBalance()
  }

  // 获取企业账户余额
  getEnterpriseBalance = () => {
    Api.getEnterpriseBalance({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          balanceInfo: data || {}
        })
      }
    })
  }

  // 获取云采购订单列表
  getCloudPurchOrderList = () => {
    const { query = {}, pages } = this.state

    const params = {
      ...query,
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getCloudPurchOrderList(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || [],
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 获取订单金额汇总
  getTradeOrderAmountSummary = () => {
    const { pages } = this.state
    const params = {
      orderType: 'MERCHANT_ORDER',
      pageNo: pages.pageNo,
      pageSize: pages.pageSize
    }

    Api.getTradeOrderAmountSummary(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          summary: data || {}
        })
      }
    })
  }

  /* 点击下载合同
  *  判断是否有合同编号 如果有直接下载
  *  2- 没有合同 弹窗 是否自定义
  */
  handleDownloadOpen = async (record = {}) => {
    this.submitCustractNumber({ orderId: record.orderId })
  }

  // 确人收货
  handleGoodsConfirm = (orderId) => {
    Modal.confirm({
      title: '货物确认已收到',
      onOk: () => { this.getCloudOrderConfirm({ orderId: orderId }) }
    })
  }

  getCloudOrderConfirm = (param) => {
    Api.getCloudOrderConfirm(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        this.getCloudPurchOrderList()
      }
    })
  }

  /* 财务点击付款按钮
   * 如果有余额则弹出选择支付方式，没有余额则直接跳转到线下付款界面
  */
  handleFinancePay = (record = {}) => {
    const { balanceInfo = {} } = this.state

    if (+balanceInfo.totalBalance > 0) {
      this.setState({
        paymentModal: true,
        rowDetail: record
      })
    } else {
      this.props.history.push({ pathname: '/purchase/orderTransferDetails', state: { orderId: record.orderId, paidAmount: record.currentPayAmount } })
    }
  }

  // 选择的支付方式
  selectedPaymethod = (params, type = 1) => {
    // type: 1- 线下转账 2-使用余额，支付成功 3-使用余额且需要线下转账
    if (type === 1) {
      this.closeModal()
      this.props.history.push({ pathname: '/purchase/orderTransferDetails', state: { orderId: params.orderId, paidAmount: params.currentPayAmount } })
    } else {
      this.balancePayPurchOrder(params, type)
    }
  }

  // 跳转到付款
  jumpToOrderPay = (record) => {
    this.getOfflinePayInfo(record)
  }

  // 余额支付云采购订单
  balancePayPurchOrder = async (params, type) => {
    const res = await Api.balancePayPurchOrder(params)
    const { code } = res
    if (+code === 10000) {
      this.closeModal()
      if (type === 2) {
        Modal.success({
          title: '采购订单支付成功',
          content: '',
          onOk: () => {
            this.getCloudPurchOrderList()
          }
        })
      } else {
        this.props.history.push({ pathname: '/purchase/orderTransferDetails', state: { orderId: params.orderId, paidAmount: params.currentPayAmount } })
      }
    }
  }

  // 提交申请售后
  savePurchaseOrderSaledApply = async (params) => {
    const res = await Api.savePurchaseOrderSaledApply(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      this.closeModal()
      this.getCloudPurchOrderList()
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    if (search.orderTime) {
      search.beginDate = search.orderTime.length > 0 ? search.orderTime[0].format('YYYY-MM-DD') : ''
      search.endDate = search.orderTime.length > 0 ? search.orderTime[1].format('YYYY-MM-DD') : ''

      delete search.orderTime
    }

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      query: search,
      expandedRowKeys: []
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  // 根据订单状态渲染文字
  renderOrderStatusLabel = (status) => {
    const obj = cloudPurchOrderStatusMaps.find(item => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  handleShowPurchDetail = (record) => {
    this.setState({
      purchDetailModal: true,
      rowDetail: record || {}
    })
  }

  // 点击申请售后
  showSaledFormModal = (record) => {
    this.setState({
      rowDetail: record,
      saledModal: true
    })
  }

  // 获取订单明细
  getCloudPurchOrderDetail = (params) => {
    return Api.getCloudPurchOrderDetail(params)
  }

  // 生成合同
  submitCustractNumber = (params) => {
    Api.submitCustractNumber(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        if (!data || data === '') {
          return message.warning('暂无可下载的合同链接')
        }

        window.open(data)
      } else {
        message.warning(res.message)
      }
    })
  }

  // 获取发货批次列表
  getSendedBatchList = (expanded, record) => {
    const { tableData, expandedRowKeys } = this.state
    if (expanded) {
      Api.getlistPickingOrderBatchInfo({ orderId: record.orderId }).then(res => {
        const { code, data } = res
        if (+code === 10000) {
          const i = tableData.findIndex((item) => { return item.orderId === record.orderId })
          tableData[i].batchList = data || []
          this.setState({
            tableData,
            expandedRowKeys: [...expandedRowKeys, record.orderId]
          })
        }
      })
    } else {
      const index = expandedRowKeys.findIndex(item => { return item === record.orderId })
      const newexpandedRowKeys = [...expandedRowKeys]
      newexpandedRowKeys.splice(index, 1)
      this.setState({
        expandedRowKeys: newexpandedRowKeys
      })
    }
  }

  // 渲染展开子节点内容
  renderExpandRow = (row) => {
    const rowColumns = [
      {
        title: '发货批次',
        key: 'expandRow-0',
        dataIndex: 'batchNum'
      },
      {
        title: '快递公司',
        key: `expandRow-${row.pickingId}-2`,
        render: (text, record, index) => (
          <div>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <>{ record.courierName && record.courierName !== '' ? record.courierName : '----' }</>
            }
          </div>
        )
      },
      {
        title: '运单号',
        key: `expandRow-${row.pickingId}-3`,
        render: (text, record, index) => (
          <div>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <>{ record.courierNumberList && record.courierNumberList.length > 0 ? record.courierNumberList.join(',') : '----' }</>
            }
          </div>
        )
      },
      {
        title: '发货时间',
        key: `expandRow-${row.pickingId}-4`,
        render: (text, record, index) => (
          <div>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <>{ record.createTime ? moment(record.createTime).format('YYYY-MM-DD HH:mm:ss') : '----' }</>
            }
          </div>
        )
      },
      {
        title: '收货地址',
        key: `expandRow-${row.pickingId}-6`,
        ellipsis: true,
        render: (text, record, index) => (
          <>
            {
              row.orderType !== 'ORDER_CLOUD_STOCK_PROCUREMENT' && record.logisticsOrderStatus === 'CANCEL'
                ? '----'
                : <div title={renderAddressLabel(record.province, record.city, record.county, record.address)}>
                  { renderAddressLabel(record.province, record.city, record.county, record.address) }
                </div>
            }

          </>
        )
      },
      {
        title: '操作',
        key: `expandRow-${row.pickingId}-5`,
        render: (text, record, index) => (
          <>
            <Button type='link' size='small' onClick={() => this.handleShowDetailModal({ ...record, ...row })}>
              发货详情
            </Button>
          </>
        )
      }
    ]

    return (
      <Row style={{ padding: '2px 5px' }}>
        <Table rowKey={(item, i) => { return i }}
          columns={rowColumns}
          dataSource={row.batchList}
          pagination={false}
        />
      </Row>
    )
  }

  // 查看物流轨迹
  handleShowDetailModal = (record) => {
    this.setState({
      rowDetail: record,
      detailModal: true
    })
  }

  // 点击查看订单附件
  showOrderEnclosureModal = (record) => {
    this.setState({
      rowDetail: record,
      enclosureModal: true
    })
  }

  closeModal = () => {
    this.setState({
      purchDetailModal: false,
      paymentModal: false,
      detailModal: false,
      saledModal: false,
      enclosureModal: false,
      rowDetail: {}
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudPurchOrderList()
    })
  }

  render () {
    const {
      searchData, pages, balanceInfo = {}, expandedRowKeys = [], summary = {}, tableData, detailModal = false, paymentModal = false, purchDetailModal = false,
      saledModal = false, enclosureModal = false, rowDetail = {}
    } = this.state

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }
    return (
      <Card bodyStyle={{ padding: '16px' }} bordered={false}>
        <div style={{ marginBottom: '20px' }}>
          <SearchForm {...this.props}
            dataSource={searchData}
            handleSubmit={(obj) => this.handleSearch(obj)}
          />
        </div>

        <OrderStatistic totalAmount={summary.payableAmount } paidAmount={summary.paidAmount} unPaidAmount={summary.payableAmount - summary.paidAmount} />

        <Table rowKey='orderId' bordered
          pagination={pagination}
          columns={this.columns}
          dataSource={tableData}
          expandedRowKeys={expandedRowKeys}
          onExpand={(expanded, record) => this.getSendedBatchList(expanded, record)}
          expandedRowRender={(record) => this.renderExpandRow(record)}
        />

        {
          purchDetailModal
            ? <PurchdetailModal
              detailInfo={rowDetail}
              onCancel={this.closeModal}
              getDetail={(params) => this.getCloudPurchOrderDetail(params)}
            />
            : null
        }

        {
          paymentModal
            ? <PaymentModal
              detailInfo={rowDetail}
              balanceInfo={balanceInfo}
              onCancel={() => this.closeModal()}
              onConfirm={(params, type) => this.selectedPaymethod(params, type)}
            />
            : null
        }

        {
          detailModal
            ? <DetailModal
                detailInfo={rowDetail}
                onCancel={() => this.closeModal()}
              />
            : null
        }

        <SaledDrawer visible={saledModal}
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
          onConfirm={(params) => this.savePurchaseOrderSaledApply(params)}
        />

        {/* 订单附件弹窗 */}
        <OrderEnclosureDrawer
          visible={enclosureModal}
          name='PURCHASE_ENCLOSURE'
          detailInfo={rowDetail}
          onCancel={() => this.closeModal()}
        />
      </Card>
    )
  }
}

import toolsApi from './toolsApi'
import loginApi from './loginApi'
import userApi from './userApi'
import financeApi from './financeApi'
import invoiceApi from './invoiceApi'
import addressApi from './addressApi'
import customerApi from './customerApi'
import purchase from './purchase'
import orderApi from './orderApi'
import expressApi from './expressApi'
import enquireApi from './enquireApi'
import matchApi from './matchApi'
import saledApi from './saledApi'
import productApi from './productApi'

export default {
  ...toolsApi,
  ...userApi,
  ...loginApi,
  ...financeApi,
  ...invoiceApi,
  ...addressApi,
  ...customerApi,
  ...purchase,
  ...orderApi,
  ...expressApi,
  ...enquireApi,
  ...matchApi,
  ...saledApi,
  ...productApi
}

import config from '@/common/utils/config'

const { host } = config

// 上传图片-不需要水印的图片
const uploadOrdinaryImageUrl = `${host}/api/image/uploadByOthers`

// 上传图片-需要水印的图片
const uploadNeedLogoImageUrl = `${host}/api/image/uploadByProduct`

// 客户导入
const beforeuploadEndUser = host + '/api/guest/supplier/endUser/preImport'

// 图文识别-上传图片
const uploadIntelDiscern = host + '/api/image/text/parse'

// 手工订单-上传产品
const uploadManualSearchSku = host + '/api/just/purchase/search/excelImport'

// EXCEL上传匹配sheet
const uploadMatchExcelUrl = host + '/api/excel/import/getSheetNames'

// 订单管理-云采购订单- 上传订单附件
const uploadPurchaseOrderEnclosure = host + '/api/cloud/procurementOrder/file/upload'

export default {
  uploadOrdinaryImageUrl,
  uploadNeedLogoImageUrl,
  beforeuploadEndUser,
  uploadIntelDiscern,
  uploadManualSearchSku,
  uploadMatchExcelUrl,
  uploadPurchaseOrderEnclosure
}

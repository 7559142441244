import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table, Row, Tabs, Button } from 'antd'

import Api from '@/common/api/index'
import LogisticsDetails from '../logisticsDetails/index'
import consts from '@/common/utils/consts'

const { TabPane } = Tabs
const { SERIALWIDTH } = consts
const tabMaps = [
  { code: 'DETAIL', label: '产品明细' },
  { code: 'TRAIL', label: '物流跟踪' }
]

export default class DetailModal extends Component {
  static propTypes = {
    detailInfo: PropTypes.object,
    onCancel: PropTypes.func
  }

  state = {
    tableData: [],
    selectedTabkey: 'DETAIL' // DETAIL-产品明细 TRAIL-物流追踪
  }

  columns = [
    {
      title: '序号',
      key: 'orderdetailCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'orderdetailCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'orderdetailCol-2',
      width: 140,
      dataIndex: 'model',
      ellipsis: true
    },
    {
      title: '订货号',
      key: 'orderdetailCol-3',
      width: 130,
      dataIndex: 'itemNumber',
      ellipsis: true
    },
    {
      title: '产品',
      key: 'orderdetailCol-4',
      width: 150,
      dataIndex: 'name',
      ellipsis: true
    },
    {
      title: '数量',
      key: 'orderdetailCol-5',
      dataIndex: 'quantity'
    },
    {
      title: '已发货数量',
      key: 'orderdetailCol-6',
      dataIndex: 'batchQuantity'
    },
    {
      title: '未发货数量',
      key: 'orderdetailCol-7',
      render: (text, record, index) => (
        <>{ record.quantity - record.batchQuantity }</>
      )
    },
    {
      title: '单位',
      key: 'orderdetailCol-8',
      width: 80,
      dataIndex: 'unit'
    }
  ]

  componentDidMount () {
    this.getStoreOutPickingDetail()
  }

  // 获取订单产品明细
  getStoreOutPickingDetail = () => {
    const { detailInfo } = this.props
    const params = {
      pickingId: detailInfo.pickingId,
      batchNum: detailInfo.batchNum,
      pageNo: 1,
      pageSize: 999
    }

    Api.getStoreOutPickingDetail(params).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          tableData: data.list || []
        })
      }
    })
  }

  // 点击切换tab
  handleChangeTab = (key) => {
    this.setState({
      selectedTabkey: key
    })
  }

  // 渲染弹窗底部按钮
  renderFooterInner = (key) => {
    const arr = [
      <Button onClick={this.props.onCancel} key={'cancelBtn'}>关闭</Button>
    ]

    return arr
  }

  render () {
    const { detailInfo = {}, onCancel } = this.props
    const { tableData = [], selectedTabkey = 'DETAIL' } = this.state

    return (
      <>
        <Modal title='订单明细' width={1000} bodyStyle={{ padding: '16px', maxHeight: '500px', overflowY: 'auto' }}
          visible
          maskClosable={false}
          onCancel={() => onCancel()}
          footer={this.renderFooterInner(selectedTabkey)}
        >
          <Row>
            <Tabs onChange={this.handleChangeTab} size='small'>
              {
                tabMaps.map((item, i) => (
                  <TabPane tab={item.label} key={item.code} />
                ))
              }
            </Tabs>
          </Row>

          {
            selectedTabkey === 'DETAIL'
              ? <>
                <Table rowKey='commoditySkuId'
                  pagination={false}
                  columns={this.columns}
                  dataSource={tableData}
                  scroll={{ y: 500 }}
                />
              </>
              : null
          }

          {
            selectedTabkey === 'TRAIL'
              ? <LogisticsDetails detailInfo={detailInfo}
              courierNumberList={detailInfo.courierNumberList || []}
              />
              : null
          }
        </Modal>
      </>
    )
  }
}

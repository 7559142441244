/* 快递管理路由 */
import ExpressService from '../pages/express/expressService/index' // 快递服务
import ExpressRecords from '@/pages/express/expressRecords/index' // 快递记录
import SendAddress from '@/pages/express/sendAddress/index' // 个人中心

export default [
  {
    path: '/express/expressService',
    name: '快递服务',
    component: ExpressService
  },
  {
    path: '/express/expressRecords',
    name: '快递记录',
    component: ExpressRecords
  },
  {
    path: '/express/sendAddress',
    name: '发货地址',
    component: SendAddress
  }
]

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Card, Form, Table, Button, Pagination, Badge, message, Icon, Modal, Tag, Alert } from 'antd'
import $lodash from 'lodash'

import SearchForm from '@/components/tool-form/index'
import AffixMenu from './affixMenu/index'
import SearchLand from './searchLand/index'

import Api from '@/common/api/index'
import { renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'
import { pageSizeOptions, skuGoodsTypeMaps } from '@/common/utils/filter'
import consts from '@/common/utils/consts'

import styles from './index.module.scss'

const { SERIALWIDTH } = consts

const _skuGoodsTypeMaps = $lodash.filter(skuGoodsTypeMaps, (o) => { return $lodash.includes(o.id, 'CLOUD') })

class CloudLibProductsForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object
  }

  state = {
    searchData: [
      {
        name: 'classificationId',
        formType: 'Select',
        itemParams: {
          label: '分类'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (value) => {
            this.handleFilter(value, 'classificationIdList')
          }
        },
        options: [],
        optionValue: ['classificationId', 'classificationName']
      },
      {
        name: 'brandId',
        formType: 'Select',
        itemParams: {
          label: '品牌'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (value) => {
            this.getSeriesList(value)
            this.handleFilter(value, 'brandIdList')
          }
        },
        options: [],
        optionValue: ['brandId', 'showLabel']
      },
      {
        name: 'seriesId',
        formType: 'Select',
        itemParams: {
          label: '系列'
        },
        cptParams: {
          placeholder: '请选择...',
          showSearch: true,
          optionFilterProp: 'children',
          onChange: (value) => {
            this.handleFilter(value, 'seriesIdList')
          }
        },
        options: [],
        optionValue: ['seriesId', 'seriesName']
      },
      {
        name: 'skuGoodsType',
        formType: 'Select',
        itemParams: {
          label: '产品类型'
        },
        cptParams: {
          placeholder: '请选择...',
          onChange: (value) => {
            this.handleFilter(value, 'skuGoodsType')
          }
        },
        options: [
          { id: 'ALL', name: '全部' },
          ..._skuGoodsTypeMaps
        ]
      }
    ],
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    tableData: [],
    aggregationResult: '', // 搜索结果简述
    query: {},
    filterQuery: {}, // 筛选参数
    selectedRowKeys: [],
    selectedRows: [],
    collapseOpen: false
  }

  columns = [
    {
      title: '序号',
      key: 'cloudLibraryCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: '分类',
      key: 'cloudLibraryCol-1',
      dataIndex: 'classificationName'
    },
    {
      title: '品牌',
      key: 'cloudLibraryCol-2',
      dataIndex: 'brandName'
    },
    {
      title: '系列',
      key: 'cloudLibraryCol-3',
      dataIndex: 'series'
    },
    {
      title: '型号',
      key: 'cloudLibraryCol-4',
      dataIndex: 'model',
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.model }} />
          { record.modelExactMatch ? <Tag color="red" style={{ marginLeft: '6px' }}>完全匹配</Tag> : null }
        </>
      )
    },
    {
      title: '订货号',
      key: 'cloudLibraryCol-5',
      dataIndex: 'itemNumber',
      render: (text, record, index) => (
        <>
          <span dangerouslySetInnerHTML={{ __html: record.itemNumber }} />
          { record.itemNumberExactMatch ? <Tag color="red" style={{ marginLeft: '6px' }}>完全匹配</Tag> : null }
        </>
      )
    },
    {
      title: '产品名称',
      key: 'cloudLibraryCol-6',
      dataIndex: 'name',
      ellipsis: true,
      render: (text, record, index) => (
        <span dangerouslySetInnerHTML={{ __html: record.name }} />
      )
    },
    {
      title: '产品类型',
      key: 'cloudLibraryCol-7',
      dataIndex: 'skuGoodsType',
      render: (text, record, index) => (
        <>{ renderSkuGoodsTypeLabel(record.skuGoodsType) }</>
      )
    },
    {
      title: '厂家表价',
      key: 'cloudLibraryCol-8',
      dataIndex: 'price',
      render: (text, record, index) => (
        <>&yen;{ +record.price }</>
      )
    },
    {
      title: '云仓价格',
      key: 'cloudLibraryCol-9',
      width: 240,
      render: (text, record, index) => (
        <>
          {
            record.supplierList && record.supplierList.length > 0
              ? <>
                {
                  record.supplierList.map((supptem, suppidnx) => (
                    <div key={`priceCell-${suppidnx}`} style={{ height: '20px', lineHeight: '20px' }}>
                      <span>&yen;{+supptem.platformPrice}</span>

                      { supptem.special ? <Badge count={'特'} style={{ marginTop: '-4px', marginLeft: '4px' }} /> : null }

                      { ['FUTURES', 'CLOUD_FUTURES'].includes(record.skuGoodsType) ? <span style={{ marginLeft: '10px' }}>预付比例：{(+supptem.prepaymentRatio * 100 / 100).toFixed(2)}%</span> : null }
                    </div>
                  ))
                }
              </>
              : '----'
          }
        </>
      )
    },
    {
      title: '库存数量',
      key: 'cloudLibraryCol-10',
      width: 140,
      render: (text, record, index) => {
        const { skuGoodsType = 'SPOT', supplierList = [] } = record

        return ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)
          ? (
            <span style={{ fontSize: '16px' }}>&infin;</span>
            )
          : (
            <div>
              {
                supplierList && supplierList.length > 0
                  ? <>
                    {
                      supplierList.map((supptem, suppidnx) => (
                        <div key={`stockCell-${suppidnx}`} style={{ height: '20px', lineHeight: '20px' }}>{supptem.stock}</div>
                      ))
                    }
                  </>
                  : '----'
              }
            </div>
            )
      }
    },
    {
      title: '货期',
      key: 'cloudLibraryCol-11',
      dataIndex: 'deliveryPeriodDays',
      render: (text, record, index) => {
        const { supplierList = [], skuGoodsType } = record

        return ['FUTURES', 'CLOUD_FUTURES'].includes(skuGoodsType)
          ? <>
          {
            supplierList && supplierList.map((item, n) => (
              <div key={`${record.commoditySkuId}-${item.deliveryPeriodDays}-${index}-${n}`}>
                { +item.deliveryPeriodDays > 0 ? `${+item.deliveryPeriodDays}个工作日` : '当天发货' }
              </div>
            ))
          }
        </>
          : '当天发货'
      }
    },
    {
      title: '操作',
      key: 'cloudLibraryCol-12',
      render: (text, record, index) => (
        <>
          <Button type='link' size='small' onClick={() => this.handleAddToCart([{ ...record }])}>加入购物车</Button>
          <Link to={{ pathname: '/commodity-detail', search: `?commoditySkuId=${record.commoditySkuId}&skuGoodsType=${record.skuGoodsType}&detailsHasCart=${true}` }} target='_blank'>
            <Button type='link' size='small'>查看详情</Button>
          </Link>
        </>
      )
    }
  ]

  componentDidMount () {
    const { form, history } = this.props
    const { state } = history.location

    this.getCloudCategoryList()
    this.getCloudBrandList()

    if (state && state.pageSource && state.pageSource === 'UPLOAD_SEARCH') { // 从导入页面返回
      form.resetFields()
      this.setState({
        query: {
          keywordList: state.keywordList,
          brandNameList: state.brandNameList
        }
      }, () => {
        history.replace({ pathname: '/purchase/cloudLibProducts', state: null })
        this.getCloudLibraryProductList()
      })
    } else {
      this.getCloudLibraryProductList()
    }
  }

  // 获取分类列表
  getCloudCategoryList = () => {
    Api.getCloudCategoryList({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        const { searchData = {} } = this.state
        searchData[0].options = data && data.length > 0 ? [{ classificationId: 0, classificationName: '全部' }, ...data] : []

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 获取品牌
  getCloudBrandList = () => {
    Api.getCloudBrandList({}).then(res => {
      const { code, data } = res

      if (+code === 10000 && data && data.length > 0) {
        const { searchData } = this.state
        data.forEach(item => {
          item.showLabel = item.englishBrandName && item.englishBrandName !== '' ? `${item.brandName}/${item.englishBrandName}  ${item.count}` : `${item.brandName}  ${item.count}`
        })

        searchData[1].options = $lodash.concat([{ brandId: 0, showLabel: '全部' }], data)

        this.setState({
          searchData: [...searchData]
        })
      }
    })
  }

  // 获取系列
  getSeriesList = async (brandId) => {
    const res = await Api.getSeriesList({ brandId: brandId, pageNo: 1, pageSize: 999 })
    const { code, data } = res
    if (+code === 10000) {
      const { searchData } = this.state

      searchData[2].options = data && data.list && data.list.length > 0 ? [{ seriesId: 0, seriesName: '全部' }, ...data.list] : []

      this.setState({
        searchData: [...searchData]
      })
    }
  }

  // 获取云仓产品列表
  getCloudLibraryProductList = () => {
    const { pages, query = {}, filterQuery = {} } = this.state
    const params = {
      pageNo: pages.pageNo,
      pageSize: pages.pageSize,
      ...query,
      ...filterQuery
    }

    Api.getCloudLibraryProductList(params).then(res => {
      const { code, data } = res

      if (+code === 10000) {
        const _list = data.list || []
        if (_list.length > 0) {
          _list.forEach(item => {
            if (item.supplyInfoList && item.supplyInfoList.length > 0) {
              const { supplyInfoList = [] } = item
              item.supplierList = this.setSupplierListRowSpan(supplyInfoList)
            }
          })
        }

        this.setState({
          tableData: _list,
          aggregationResult: data.aggregationResult || '',
          pages: {
            ...pages,
            total: data.total || 0
          }
        })
      }
    })
  }

  // 添加到购物车
  saveAddToCart = async (params) => {
    const res = await Api.saveAddToCart(params)
    const { code } = res
    if (+code === 10000) {
      Modal.success({
        title: '加入购物车成功~'
      })
    } else {
      message.warning(res.message)
    }
  }

  // 保存新增询价单
  saveAddEnquireOrder = async (params) => {
    const res = await Api.saveAddEnquireOrder(params)
    const { code } = res
    if (+code === 10000) {
      Modal.success({
        title: '操作成功',
        content: '请到我的询价中查看具体进度'
      })
    }
  }

  // 点击搜索
  handleSearch = (search = {}) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1
      },
      filterQuery: {},
      query: search
    }, () => {
      this.props.form.resetFields()
      this.getCloudLibraryProductList()
    })
  }

  // 点击重置
  handleReset = () => {
    this.props.form.resetFields()

    this.setState({
      filterQuery: {},
      query: {},
      pages: {
        pageNo: 1,
        pageSize: 20,
        total: 0
      }
    }, () => {
      this.getCloudLibraryProductList()
    })
  }

  // 点击筛选
  handleFilter = (value, key) => {
    const { filterQuery = {} } = this.state

    this.setState({
      filterQuery: {
        ...filterQuery,
        [key]: ['classificationIdList', 'brandIdList', 'seriesIdList'].includes(key) ? (+value > 0 ? [value] : []) : value
      }
    }, () => {
      this.getCloudLibraryProductList()
    })
  }

  // 设置库存详细数据格式
  setSupplierListRowSpan = (supplierList = []) => {
    const _arr = supplierList.reduce((result, item) => {
      if (result.indexOf(item.platformPrice) < 0) {
        result.push(item.platformPrice)
      }
      return result
    }, []).reduce((result, platformPrice) => {
      // 将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
      const children = supplierList.filter(item => item.platformPrice === platformPrice)
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          rowSpan: index === 0 ? children.length : 0 // 将第一行数据添加rowSpan字段
        }))
      )
      return result
    }, [])

    return _arr
  }

  // 点击勾选
  onSelectChange = (keys, rows) => {
    this.setState({
      selectedRowKeys: keys,
      selectedRows: rows
    })
  }

  // 点击加入购物车
  handleAddToCart = (rows = []) => {
    if (rows.length === 0) { return message.warning('请选择要加入购物车的产品~~') }

    const cartList = $lodash.map(rows, (record) => {
      const { commoditySkuId, itemNumber, skuGoodsType, minOrder, supplierList = [] } = record

      /** 加入购物车- 默认选择库存不为零 且价格最低
       *  1- 现货产品过滤掉库存为零，找出价格最低的
       *  2- 期货产品找出价格最低的
      */
      const obj = ['SPOT', 'CLOUD_SPOT'].includes(skuGoodsType) ? this.findSupplierIdByMinPrice(supplierList) : $lodash.minBy(supplierList, (o) => { return +o.platformPrice })

      return {
        commoditySkuId: commoditySkuId,
        itemNumber: itemNumber,
        goodsType: skuGoodsType,
        supplierId: obj ? obj.supplierId : 0,
        quantity: +minOrder > 0 ? +minOrder : 1,
        stock: obj ? obj.stock : 0
      }
    })

    const warndatas = $lodash.filter(cartList, (o) => { return o.goodsType === 'CLOUD_SPOT' && +o.stock === 0 })

    if (warndatas.length > 0) {
      Modal.warning({
        title: <>以下商品现货库存不足</>,
        content: <>
          {
            warndatas.map((o) => (
              <p key={`warnCol-${o.commoditySkuId}`}><label className='label'>订货号</label><span dangerouslySetInnerHTML={{ __html: o.itemNumber }}/></p>
            ))
          }
        </>,
        maskClosable: true
      })

      return false
    }

    this.saveAddToCart({ cartList: cartList })
  }

  // 点击跳转到询价提交
  handleLinkToCreateEnquireOrder = () => {
    this.props.history.push({ pathname: '/enquire/createEnquireOrder' })
  }

  // 点击一键询价
  handleEnquiry = () => {
    const { selectedRows = [] } = this.state
    if (selectedRows.length === 0) { return message.warning('请选择要询价的产品~~') }

    const newdatas = $lodash.map(selectedRows, (record) => {
      return {
        ...record,
        quantity: 1,
        matchingState: 'MATCHED',
        dataType: 'SEARCH'
      }
    })

    const params = {
      enquiryOrderDetails: newdatas,
      enquiryType: 'MERCHANT_QUICK_ORDER',
      enquiryState: 'NO',
      quotationNumber: 1
    }

    this.saveAddEnquireOrder(params)
  }

  findSupplierIdByMinPrice = (supplierList) => {
    const newdatas = $lodash.filter(supplierList, (o) => { return +o.stock > 0 })
    const obj = newdatas.length > 0 ? $lodash.minBy(newdatas, (o) => { return +o.platformPrice }) : null

    return obj
  }

  // 点击展开收起
  handleCollapse = (bool) => {
    this.setState({
      collapseOpen: !bool
    })
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getCloudLibraryProductList()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getCloudLibraryProductList()
    })
  }

  render () {
    const { history } = this.props
    const { pages, searchData, tableData = [], aggregationResult = '', selectedRowKeys = [], selectedRows = [], collapseOpen = false } = this.state

    // 分页配置
    const pagination = {
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    const rowSelection = {
      columnWidth: 44,
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} bordered={false}>
          <SearchLand history={history}
            onConfirm={(values) => this.handleSearch(values)}
            onSelect={(values) => this.handleSearch(values)}
            onReset={() => this.handleReset()}
          />

          {
            aggregationResult !== ''
              ? <div style={{ marginBottom: '20px' }}>
                <Alert type='info' message={<div style={{ textAlign: 'center' }}>
                  <span>{ aggregationResult }</span><a onClick={() => this.handleLinkToCreateEnquireOrder()}>点击跳转</a>
                </div>} />
              </div>
              : null
          }

          <div className={`${styles.filterLand} ${collapseOpen ? styles.collapseOpen : ''}`}>
            <Card title='高级筛选' bodyStyle={{ padding: '15px 15px 15px 15px' }}>
              <SearchForm {...this.props}
                dataSource={searchData}
                btns={[]}
              />
            </Card>
            <a className={`${styles.collapseButton}`} onClick={() => this.handleCollapse(collapseOpen)}>
            {
              collapseOpen
                ? <>
                点击收起<span style={{ marginLeft: '4px' }}><Icon type="up" style={{ marginTop: '2px' }} /></span>
              </>
                : <>
                点击展开<span style={{ marginLeft: '4px' }}><Icon type="down" style={{ marginTop: '4px' }} /></span>
              </>
            }
            </a>
          </div>

          <Card bodyStyle={{ padding: '0px' }} bordered={false}>
            <div style={{ marginBottom: '15px' }}>
              <Button type='primary' onClick={() => this.handleAddToCart(selectedRows)}><Icon type="select" />批量加入购物车</Button>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={() => this.handleEnquiry()}><Icon type="interaction" />一键询价</Button>
            </div>

            <Table rowKey={(record, i) => { return `${record.commoditySkuId}-${record.skuGoodsType}` }} bordered
              columns={this.columns}
              dataSource={tableData}
              rowSelection={rowSelection}
              scroll={{ x: 1800 }}
              pagination={false}
            />

            <div style={{ margin: '16px 0px', textAlign: 'right' }}>
              <Pagination {...pagination} />
            </div>
          </Card>
          <AffixMenu history={history} />
        </Card>
      </>
    )
  }
}

const CloudLibProducts = Form.create()(CloudLibProductsForm)

export default CloudLibProducts

import Ajax from '../utils/ajax'

export default {
  // 快速下单
  getPurChaseBatchSearch (params) {
    return Ajax.post('/api/just/purchase/search', params)
  },

  // 产品纠错
  getpurChasecorrect (params) {
    return Ajax.post('/api/just/purchase/search/correct', params)
  },

  // 获取云产品详情信息
  getCloudProductDetail (params) {
    return Ajax.get('/api/cloud/procurement/skuPage', { params })
  },

  // 添加到购物车
  saveAddToCart (params) {
    return Ajax.post('/api/just/purchase/shopping/cart/add', params)
  },
  // 获取购物车列表
  getCartList (params) {
    return Ajax.post('/api/just/purchase/shopping/cart/list', params)
  },
  // 获取购物车列表产品
  getCartListremove (params) {
    return Ajax.post('/api/just/purchase/shopping/cart/remove', params)
  },
  // 订单结算
  getCartListpreview (params) {
    return Ajax.post('/api/just/purchase/order/preview', params)
  },

  // 提交订单
  submitOrder (params) {
    return Ajax.post('/api/just/purchase/order/generate', params)
  },
  // 企业账户余额
  getEnterpriseBalance (params) {
    return Ajax.get('/api/finance/get/distributor/balance', { params })
  },

  // 查询是否设置了支付密码
  getHasPayPassword (params) {
    return Ajax.get('/api/finance/pay/hasPayPassword', { params })
  },

  // 查询是否有合同生成
  getHasCloudPurchContract (params) {
    return Ajax.get('/api/merchant/contract', { params })
  },

  // 自定义合同
  submitCustractNumber (params) {
    return Ajax.post('/api/merchant/contract/create', params)
  },

  // 云采购订单支付
  balancePayPurchOrder (params) {
    return Ajax.post('/api/finance/pay/balancePay', params)
  },

  // 获取线下支付信息
  getOfflinePayInfo (params) {
    return Ajax.get('/api/finance/pay/offlinePayInfo', { params })
  },

  // 云采购订单列表-财务
  getCloudPurchOrderList (params) {
    return Ajax.post('/api/cloud/procurementOrder/pageQuery', params)
  },

  // 获取云采购订单详情
  getCloudPurchOrderDetail (params) {
    return Ajax.post('/api/cloud/procurementOrder/detailQuery', params)
  },

  // 拣货单信息
  getlistPickingOrderBatchInfo (params) {
    return Ajax.post('/api/just/purchase/cloud/order/manage/listPickingOrderBatchInfo', params)
  },
  // 确认收货
  getCloudOrderConfirm (params) {
    return Ajax.post('/api/just/purchase/cloud/order/manage/confirm/complete ', params)
  }
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Table } from 'antd'

import Api from '@/common/api/index'

import consts from '@/common/utils/consts'

const { SERIALWIDTH } = consts

function DetailsModal (props) {
  const [tableData, setTableData] = useState([])
  const { detailInfo = {}, onCancel } = props

  // 获取产品明细
  const getStoreOutPickingDetail = async () => {
    const params = {
      pickingId: detailInfo.pickingId,
      batchNum: detailInfo.batchNum,
      pageNo: 1,
      pageSize: 999
    }

    const res = await Api.getStoreOutPickingDetail(params)
    const { code, data } = res
    if (+code === 10000) {
      setTableData(data.list || [])
    }
  }

  const columns = [
    {
      title: '序号',
      key: 'detailCol-0',
      width: SERIALWIDTH,
      align: 'center',
      render: (text, record, index) => (`${index + 1}`)
    },
    {
      title: '品牌',
      key: 'detailCol-1',
      dataIndex: 'brandName'
    },
    {
      title: '型号',
      key: 'detailCol-2',
      dataIndex: 'model'
    },
    {
      title: '订货号',
      key: 'detailCol-3',
      dataIndex: 'itemNumber'
    },
    {
      title: '产品',
      key: 'detailCol-4',
      width: 180,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '数量',
      key: 'detailCol-5',
      dataIndex: 'quantity'
    }
  ]

  useEffect(() => {
    getStoreOutPickingDetail()
  }, [])

  return (
    <>
      <Modal title='货物明细' width={900} bodyStyle={{ padding: '15px', minHeight: '300px' }}
        visible
        maskClosable={false}
        onCancel={() => onCancel()}
        footer={false}
      >
        <Table rowKey='commoditySkuId' bordered
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={{ y: 500 }}
        />
      </Modal>
    </>
  )
}

DetailsModal.propTypes = {
  detailInfo: PropTypes.object,
  onCancel: PropTypes.func
}

export default DetailsModal

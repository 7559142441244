import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, Steps, Row, Col, Select, Button, Icon, Pagination, Modal } from 'antd'
import $lodash from 'lodash'

import HandleModal from '@/components/handleModal/index'

import Api from '@/common/api/index'
import { sheetSourchMaps } from './localdatas'
import { pageSizeOptions } from '@/common/utils/filter'
import styles from './index.module.scss'

const { Step } = Steps
const Option = Select.Option

const stepMaps = [
  { title: '确认数据', code: '1' },
  { title: '匹配数据', code: '2' },
  { title: '生成列表', code: '3' }
]

class WorkSheets extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func
  }

  state = {
    pages: { total: 0, pageNo: 1, pageSize: 20 },
    handleModal: false,
    excelName: '',
    sheetName: null,
    config: {}, // 页面参数: pageSourch: 页面来源, sheetSourch: 导入来源, endUserId: 客户ID(非必要), groupId: 价格组ID(非必要)
    tableData: [], // EXCEL源数据
    curSheetIndex: 0, // 当前选择的sheet项下标
    curSheetData: [], // 当前sheet数据源数据
    showData: [], // 页面展示数据
    importId: -1
  }

  componentDidMount () {
    const { state } = this.props.history.location
    const { config } = state
    const _selectedPartIndex = []

    const i = sheetSourchMaps.findIndex(item => { return item.type === state.config.sheetSourch })
    const _showData = i > -1 ? sheetSourchMaps[i].sourch : []

    _showData.forEach((item, n) => {
      _selectedPartIndex.push(n)
    })

    this.setState({
      importId: state.data.importId,
      query: state.data || {},
      showData: _showData,
      config: state.config || {},
      selectedPartIndex: _selectedPartIndex,
      pages: { total: 0, pageNo: 1, pageSize: config && config.pageSourch === 'cloudLibrarySetting' ? 200 : 20 },
      handleModal: false
    }, () => {
      this.getUploadExcelAnalysisdatas()
    })
  }

  // 获取分页数据
  getUploadExcelAnalysisdatas = async () => {
    const { pages, importId, query = {} } = this.state

    const res = await Api.getUploadExcelAnalysisdatas({ ...query, importId: importId, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      this.setState({
        excelName: data.data.excelName,
        tableData: data.data.sheetDTOList || [],
        pages: {
          ...pages,
          total: data.total || 0
        }
      }, () => {
        this.handleChangeSheetItem(0)
      })
    }
  }

  // 提交匹配
  saveUploadExcelAnalysisMatch = () => {
    const { showData = [], config, importId } = this.state
    const params = {
      importId: importId,
      importType: config.sheetSourch
    }

    if (config.endUserId) {
      params.endUserId = config.endUserId
    }

    if (config.commonGuestType) {
      params.commonGuestType = config.commonGuestType
      params.priceGroupId = config.priceGroupId
    }

    showData.forEach(item => {
      params[item.type] = item.data.columnIndex
    })

    Api.saveUploadExcelAnalysisMatch(params).then(res => {
      const { code } = res
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false
          }, () => {
            this.props.history.push({ pathname: '/matchs/matchReport', state: { importId: importId, config: config } })
          })
        }, 3000)
      } else {
        this.setState({ handleModal: false })
      }
    })
  }

  // EXCEL解析预报价数据匹配
  saveUploadPrepareQuotaionExcelAnalysisMatch = async (params) => {
    const res = await Api.saveUploadPrepareQuotaionExcelAnalysisMatch(params)
    const { code } = res
    this.setState({ handleModal: false })

    if (+code === 10000) {
      this.countDown()
    }
  }

  // 选择EXCEL Sheet
  handleChangeSheetItem = (i) => {
    const { tableData = [], showData } = this.state
    const _arr = tableData[i].columnDTOList || []

    const unselectObj = {
      columnName: '不选',
      cellArray: this.setNotselectCellArray(_arr[0].cellArray.length)
    }

    if (_arr.length > 0) {
      _arr.forEach((item, n) => {
        showData.forEach((row, k) => {
          if (item.columnIndex === row.selectedPartIndex) {
            showData[k].data = item || {}
          }
        })
      })
    }

    this.setState({
      curSheetIndex: i,
      curSheetData: $lodash.concat(tableData[i].columnDTOList, unselectObj),
      showData
    })
  }

  // 选择替换该列数据
  handleChangeSheetCol = (key, i) => {
    const { curSheetData = [], showData = [] } = this.state

    const n = curSheetData.findIndex(item => { return item.columnIndex === key })

    if (n > -1) {
      showData[i].data = curSheetData[n] || {}
      showData[i].selectedPartIndex = n
    }

    this.setState({
      showData: [...showData]
    })
  }

  // 设置不选的数据
  setNotselectCellArray = (len) => {
    const _arr = []

    for (let i = 0; i < len; i++) {
      _arr.push('')
    }

    return _arr
  }

  // 点击开始匹配
  handleSubmit = () => {
    const { history } = this.props
    const { config = {}, importId, showData = [] } = this.state
    this.setState({ handleModal: true })

    if (config.pageSourch === 'cloudLibrarySetting') {
      const list = showData[1].data.cellArray || []
      const brandNameList = showData[0].data.cellArray || []

      setTimeout(() => {
        this.setState({ handleModal: false })
        history.push({ pathname: '/purchase/cloudLibProducts', state: { keywordList: list, brandNameList: brandNameList, pageSource: 'UPLOAD_SEARCH' } })
      }, 1000)
    } else if (config.pageSourch === 'prepareQuotionSetting') {
      const params = {
        importId: importId,
        importType: config.sheetSourch,
        matchNoColNum: 0
      }

      showData.forEach(item => {
        params[item.type] = item.data.columnIndex
      })

      this.saveUploadPrepareQuotaionExcelAnalysisMatch(params)
    } else {
      this.saveUploadExcelAnalysisMatch()
    }
  }

  // 点击取消
  handleGoBack = () => {
    this.props.history.go(-1)
  }

  // 倒计时
  countDown = () => {
    const { history } = this.props
    let secondsToGo = 5

    const modal = Modal.success({
      title: '执行成功',
      content: <>
        <div>若长时间未报价成功，请到列表页报价进度查看</div>
        <div>{secondsToGo}s后将返回列表页</div>
      </>,
      onOk: () => {
        clearInterval(timer)
        clearTimeout(outTimer)
        modal.destroy()

        history.push({ pathname: '/enquire/prepareQuotations' })
      }
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
      modal.update({
        content: <>
        <div>若长时间未报价成功，请到列表页报价进度查看</div>
        <div>{secondsToGo}s后将返回列表页</div>
      </>
      })
    }, 1000)

    const outTimer = setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
      history.push({ pathname: '/enquire/prepareQuotations' })
    }, secondsToGo * 1000)
  }

  // 翻页
  pageChange = (page, pageSize) => {
    const { pages } = this.state
    this.setState({
      pages: {
        ...pages,
        pageNo: page
      }
    }, () => {
      this.getUploadExcelAnalysisdatas()
    })
  }

  // 改变每页条数
  pageSizeChange = (current, size) => {
    const { pages } = this.state

    this.setState({
      pages: {
        ...pages,
        pageNo: 1,
        pageSize: size
      }
    }, () => {
      this.getUploadExcelAnalysisdatas()
    })
  }

  render () {
    const { pages, excelName = '', tableData = [], curSheetData = [], showData = [], handleModal = false } = this.state

    let colSpan = 6
    if (showData && showData.length > 4) {
      colSpan = Math.floor(24 / showData.length)
    }

    const pagination = {
      showSizeChanger: true,
      showQuickJumper: true,
      total: pages.total,
      showTotal: (total) => { return `共 ${total} 条` },
      current: pages.pageNo,
      pageSize: pages.pageSize,
      pageSizeOptions: pageSizeOptions,
      onChange: this.pageChange,
      onShowSizeChange: this.pageSizeChange
    }

    return (
      <>
        <Card bodyStyle={{ padding: '15px' }} bordered={false}>
          <div style={{ marginBottom: '20px' }}>
            <Card bodyStyle={{ padding: '15px' }}>
              <Row gutter={24}>
                <Col span={8}>
                  <Steps size='small'>
                  {
                    stepMaps.map((item, i) => (
                      <Step title={item.title} key={`step-${i}`} />
                    ))
                  }
                  </Steps>
                </Col>
                <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                  <Button style={{ marginRight: '12px' }} onClick={this.handleGoBack}>取消匹配</Button>

                  <Button type='primary' onClick={this.handleSubmit}>开始匹配<Icon type='arrow-right' /></Button>
                </Col>
              </Row>
            </Card>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <Card bodyStyle={{ padding: '15px' }}>
              <Row style={{ marginBottom: '24px' }} gutter={18}>
                <Col span={6}>
                  <div style={{ marginBottom: '6px' }}>EXCEL名称</div>
                  <div className={`${styles.name}`}>{excelName}</div>
                </Col>
                <Col span={6}>
                  <div style={{ marginBottom: '6px' }}>选择列表</div>
                  <div className={`${styles.name}`}>{ tableData.length > 0 ? tableData[0].sheetName : null }</div>
                </Col>
              </Row>
            </Card>
          </div>

          <Card bodyStyle={{ padding: '15px' }}>
            <Row gutter={12}>
            {
              showData.map((col, j) => (
                <Col span={colSpan} key={`col-${j}`}>
                  <div className={`${styles.colTitle}`}>
                    <div className={`${styles.titleLabel}`}>{col.name}</div>

                    <div className={`${styles.titleControl}`}>
                      <Select style={{ width: '100%' }}
                        value={col.selectedPartIndex}
                        key={`selection-${j}`}
                        size='small'
                        placeholder='换一列'
                        onChange={(val) => this.handleChangeSheetCol(val, j)}
                      >
                        {
                          curSheetData.length > 0 && curSheetData.map((item, n) => (
                            <Option value={item.columnIndex} key={`opt-${j}-${n}`}>{item.columnName}</Option>
                          ))
                        }
                      </Select>
                    </div>
                  </div>

                  {
                    col.data && col.data.cellArray && col.data.cellArray.length > 0
                      ? <ul className={`${styles.listWrapper}`}>
                          {
                            col.data.cellArray.map((sheetCol, i) => (
                              <li className={`${styles.listItem}`} key={`brandCol-${j}-${i}`}>
                                {
                                  sheetCol && sheetCol !== ''
                                    ? <span>{sheetCol}</span>
                                    : <span>--</span>
                                }
                              </li>
                            ))
                          }
                        </ul>
                      : null
                    }

                  </Col>
              ))
              }
              </Row>

              <Row style={{ marginTop: '24px' }}>
                <Pagination {...pagination} />
              </Row>
            </Card>
          </Card>

          {
            handleModal
              ? <HandleModal letterArr={['数', '据', '导', '入', '中']} />
              : null
          }
      </>
    )
  }
}

export default connect()(WorkSheets)

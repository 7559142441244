import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form, Input, Button, message, Radio, AutoComplete, Divider } from 'antd'
import $lodash from 'lodash'

import UploadModal from '../uploadModal/index'
import UploadSheetModal from '@/components/uploadSheetModal/index'
import HandleModal from '@/components/handleModal/index'

import Api from '@/common/api/index'
import { importConfig } from '@/common/utils/filter'
import { repLineBreak } from '@/common/utils/mUtils'

const { TextArea } = Input
const radioMaps = [
  { id: 'SINGLE_VALUES', name: '单个模糊' },
  { id: 'EXACT_VALUES', name: '批量精确' }
]

class SearchForm extends Component {
  constructor (props) {
    super(props)
    this.getCloudLibraryProductList = $lodash.debounce(this.getCloudLibraryProductList, 300)
  }

  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    dispatch: PropTypes.func,
    onConfirm: PropTypes.func,
    onReset: PropTypes.func
  }

  state = {
    activeKey: 'SINGLE_VALUES',
    uploadModal: false,
    handleModal: false,
    tableData: [],
    selectedKey: null,
    uploadSheetModal: false,
    uploadSheetDetails: {}
  }

  // 联想模糊搜索
  getCloudLibraryProductList = async (value) => {
    const res = await Api.getCloudLibraryProductList({ keyword: value, pageNo: 1, pageSize: 10 })
    const { code, data } = res
    if (+code === 10000) {
      const newdatas = data && data.list && data.list.length > 0 ? $lodash.uniqBy(data.list, 'commoditySkuId') : []

      this.setState({
        tableData: newdatas
      })
    }
  }

  // 上传EXCEL智能解析
  saveUploadExcelMatchSmartparse = async (params) => {
    const res = await Api.saveUploadExcelMatchSmartparse(params)
    const { code, data } = res
    if (+code === 10000) {
      this.closeModal()
      this.changeUploadSuccess(data)
    }
  }

  // 点击搜索
  handleSubmit = () => {
    const { form, onConfirm } = this.props
    const { activeKey } = this.state
    const value = form.getFieldValue(activeKey)

    if (!value) { return message.warning('请输入搜索内容~~') }
    const obj = {}

    if (activeKey === 'EXACT_VALUES') {
      const _values = repLineBreak(value, '<br>').split('<br>')
      const keywordList = $lodash.filter(_values, (o) => { return o !== '' })

      obj.keywordList = keywordList
    } else {
      obj.keyword = value
    }

    onConfirm(obj)
  }

  // 监听文件上传
  handleChangeUploadExcel = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ handleModal: true })

      return false
    }

    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          this.setState({
            handleModal: false,
            uploadSheetDetails: {
              name: data.tableName || '',
              list: data.sheetNames || [],
              file: info.file.originFileObj
            },
            uploadSheetModal: true
          })
        }, 2000)
      } else {
        this.closeModal()
        message.error(info.file.response.message)
      }
    } else if (info.file.status === 'error') {
      this.closeModal()
      message.error('上传失败')
    }
  }

  // 点击确定选择导入EXCEL的sheet
  handleConfirmUploadSheet = (values) => {
    const formData = new FormData()

    $lodash.forEach(values, (value, key) => {
      formData.append(key, value)
    })

    this.saveUploadExcelMatchSmartparse(formData)
  }

  changeUploadSuccess = (params) => {
    const configs = {
      pageSourch: 'cloudLibrarySetting',
      sheetSourch: importConfig.cloudLibrarySetting.sheetSourch
    }

    this.props.history.push({ pathname: '/match/sheetConfirm', state: { config: configs, data: { importId: params } } })
  }

  // 点击重置
  handleReset = () => {
    const { form, onReset } = this.props
    form.resetFields()
    onReset()
  }

  // 点击切换
  handleChangeRadio = (value) => {
    this.setState({
      activeKey: value
    })
  }

  // 点击选择联想推荐
  handleSelectAutoComplete = (value) => {
    const { onConfirm } = this.props
    onConfirm({ commoditySkuIdList: [value] })
  }

  // 点击上传按钮
  showUploadModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  closeModal = () => {
    this.setState({
      uploadModal: false,
      uploadSheetModal: false,
      handleModal: false,
      uploadSheetDetails: {}
    })
  }

  render () {
    const { form } = this.props
    const { getFieldDecorator } = form
    const { activeKey = 'SINGLE_VALUES', uploadModal = false, tableData = [], handleModal = false, uploadSheetModal = false, uploadSheetDetails = {} } = this.state

    return (
      <>
        <div style={{ marginBottom: '24px' }}>

            <div style={{ width: '500px', margin: ' 0px auto 8px auto', textAlign: 'center' }}>
              <Radio.Group buttonStyle="solid" value={activeKey} onChange={(e) => this.handleChangeRadio(e.target.value)}>
                {
                  radioMaps.map((item, n) => (
                    <Radio.Button value={item.id} key={item.id}>{ item.name }</Radio.Button>
                  ))
                }
              </Radio.Group>
            </div>

            <Form layout="inline">
              <div style={{ textAlign: 'center' }}>
                <Form.Item>
                  <Button type='primary' size='large' onClick={() => this.showUploadModal()}>点击上传</Button>
                </Form.Item>

                {
                  activeKey === 'EXACT_VALUES'
                    ? <>
                      <Form.Item>
                        {
                          getFieldDecorator('EXACT_VALUES', {

                          })(
                            <TextArea placeholder="请输入型号或订货号..."
                              autoComplete='off' rows={4} style={{ width: '500px' }}
                            />
                          )
                        }
                      </Form.Item>
                    </>
                    : <>
                        <Form.Item>
                          {
                            getFieldDecorator('SINGLE_VALUES', {

                            })(
                              <AutoComplete placeholder='请输入型号、订货号、产品名称、系列、品牌...' autoComplete='off' size='large' style={{ width: '500px' }}
                                optionLabelProp='name'
                                onSearch={(value) => this.getCloudLibraryProductList(value)}
                                onSelect={(value) => this.handleSelectAutoComplete(value)}
                              >
                                {
                                  tableData.map((item, k) => (
                                    <AutoComplete.Option key={item.commoditySkuId}>
                                      <span dangerouslySetInnerHTML={{ __html: item.brandName }} /><Divider type='vertical' />
                                      <span dangerouslySetInnerHTML={{ __html: item.name }}/><Divider type='vertical' />
                                      <span dangerouslySetInnerHTML={{ __html: item.model }}/><Divider type='vertical' />
                                      <span dangerouslySetInnerHTML={{ __html: item.itemNumber }}/>
                                    </AutoComplete.Option>
                                  ))
                                }
                              </AutoComplete>
                            )
                          }
                        </Form.Item>
                      </>
                }

                <Form.Item>
                  <Button type='primary' onClick={() => this.handleSubmit()} size='large' style={{ marginRight: '16px' }}>搜索</Button>
                  <Button onClick={() => this.handleReset()} size='large'>重置</Button>
                </Form.Item>
              </div>
            </Form>
        </div>

        {
          uploadModal
            ? <UploadModal
                onUpload={(info) => this.handleChangeUploadExcel(info)}
                onCancel={() => this.closeModal()}
              />
            : null
        }

        {
          uploadSheetModal
            ? <UploadSheetModal
              name={uploadSheetDetails.name}
              list={uploadSheetDetails.list}
              file={uploadSheetDetails.file}
              onConfirm={(values) => this.handleConfirmUploadSheet(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }

        {
          handleModal
            ? <HandleModal
              letterArr={['EXCEL', '分', '析', '中', '...']}
            />
            : null
        }
      </>
    )
  }
}

const SearchLand = Form.create()(SearchForm)

export default connect()(SearchLand)

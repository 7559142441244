import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Card, Button, Icon } from 'antd'
import AddressList from '@/components/addressList/index'

import Api from '@/common/api/index'

function CustomerAddress (props) {
  const location = useLocation()
  const { endUserId } = location.state

  // 获取EU客户收件地址列表
  const getEndUserReceivedAddressList = (params) => {
    return Api.getEndUserReceivedAddressList(params)
  }

  // 保存新增EU客户地址
  const saveAddEndUserReceiveAddress = (params) => {
    return Api.saveAddEndUserReceiveAddress(params)
  }

  const saveEditEndUserReceiveAddress = (params) => {
    return Api.saveEditEndUserReceiveAddress(params)
  }

  const deleteEndUserReceivedAddress = (params) => {
    return Api.deleteEndUserReceivedAddress(params)
  }

  const handleBack = () => {
    props.history.go(-1)
  }

  return (
    <Card bodyStyle={{ padding: '16px' }} bordered={false}>
      <div style={{ marginBottom: '20px' }}>
        <Button onClick={() => handleBack()}><Icon type="left" />返回</Button>
      </div>

      <AddressList
        ident={location.pathname === '/customer/customerAddress' ? 'EU' : 'ALL'}
        endUserId={endUserId || 0}
        getList={(params) => getEndUserReceivedAddressList(params)}
        onSaveAddress={(params) => saveAddEndUserReceiveAddress(params)}
        onEditAddress={(params) => saveEditEndUserReceiveAddress(params)}
        onDeleteAddress={(params) => deleteEndUserReceivedAddress(params)}
      />
    </Card>
  )
}

CustomerAddress.propTypes = {
  history: PropTypes.object
}

export default CustomerAddress

import React, { useState } from 'react'
import { Card, Tabs } from 'antd'

import DepponExpressBill from './depponExpressBill/index'

const { TabPane } = Tabs
const tabPaneMaps = [
  { id: 'SAAS-DBL', name: '运费账单（德邦）' }
]

export default function ExpressBill (props) {
  const [activeKey, setActiveKey] = useState('SAAS-DBL')

  return (
    <Card bodyStyle={{ padding: '0px 16px 16px 16px' }} bordered={false}>
      <Tabs onChange={(key) => setActiveKey(key)}>
        {
          tabPaneMaps.map((item) => (
            <TabPane tab={item.name} key={item.id} />
          ))
        }
      </Tabs>

      {
        activeKey === 'SAAS-DBL'
          ? <DepponExpressBill />
          : null
      }
    </Card>
  )
}

import React, { Component } from 'react'
import { Card, Form, Row, Col, Input, Select, Button, Modal } from 'antd'
import PropTypes from 'prop-types'
import Api from '@/common/api/index'
import { renderAddressLabel } from '@/common/utils/mUtils'
import $lodash from 'lodash'
import styles from './index.module.scss'
import AddressModal from '@/components/saddress-modal/index'
const FormItem = Form.Item
const Option = Select.Option
class SalesUserForm extends Component {
  static propTypes = {
    history: PropTypes.object,
    form: PropTypes.object,
    onSelectAddress: PropTypes.func,
    onSelectCustomer: PropTypes.func
  }

  state = {
    customerList: [], // 客户列表
    addressList: [], // 收货地址列表
    selectedAddressInfo: {} // 选择的收货地址
  }

  componentDidMount () {
    this.getGuestListBySeller()
    this.getOutboundOrderNum()
  }

  // 获取出库单号
  getOutboundOrderNum = () => {
    Api.getOutboundOrderNum({}).then(res => {
      const { code, data } = res
      if (+code === 10000 && data) {
        setTimeout(() => {
          this.props.form.setFieldsValue({ distributorOrderNo: data })
        }, 20)
      }
    })
  }

  // 获取客户列表
  getGuestListBySeller = () => {
    Api.getGuestListBySeller({ pageNo: 1, pageSize: 999 }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        this.setState({
          customerList: data.list || []
        })
      }
    })
  }

  // 获取客户收货地址列表
  getEndUserReceivedAddressList = async (endUserId) => {
    const res = await Api.getEndUserReceivedAddressList({ endUserId: endUserId, category: 0 })
    const { code, data } = res
    if (+code === 10000) {
      const { selectedAddressInfo } = this.state
      this.setState({
        addressList: data || []
      }, () => { this.handleChangeReceiver(selectedAddressInfo.id) })
    }
  }

  // 新增EU订单收货地址
  saveAddEndUserReceiveAddress = async (params) => {
    const res = await Api.saveAddEndUserReceiveAddress(params)
    const { code } = res
    if (+code === 10000) {
      const { selectedCustomerDetail = {} } = this.state
      this.countDown()

      this.closeModal()
      this.getEndUserReceivedAddressList(selectedCustomerDetail.euId)
    }
  }

  // 更新EU订单收货地址
  saveEditEndUserReceiveAddress = async (params) => {
    const res = await Api.saveEditEndUserReceiveAddress(params)
    const { code } = res
    if (+code === 10000) {
      const { selectedCustomerDetail = {} } = this.state
      this.countDown()

      this.closeModal()

      this.getEndUserReceivedAddressList(selectedCustomerDetail.euId)
    }
  }

  // 点击选择客户
  handleSelectCustomer = (endUserId) => {
    const { customerList = [] } = this.state
    const obj = $lodash.find(customerList, (o) => { return o.euId === endUserId })

    if (obj !== undefined) {
      this.props.onSelectCustomer(obj)
    }

    this.setState({
      selectedCustomerDetail: obj !== undefined ? obj : {},
      addressList: []
    }, () => {
      this.props.form.setFieldsValue({ receiverAddressId: null })
      this.getEndUserReceivedAddressList(endUserId)
    })
  }

  // 点击选择收货地址
  handleChangeReceiver = (addressId) => {
    const { addressList = [] } = this.state
    const obj = $lodash.find(addressList, (o) => { return o.id === addressId })

    if (obj !== undefined) {
      this.props.onSelectAddress(obj)
    }

    this.setState({
      selectedAddressInfo: obj !== undefined ? obj : {}
    })
  }

  // 点击修改收货地址&添加客户弹窗
  handleShowModal = (record, type, ident) => {
    record.name = record.receiver
    record.mobile = record.phoneNumber
    record.status = +(record.category) === 2 ? 'ENABLE' : 'DISABLE'
    this.setState({
      [ident]: true,
      type: type,
      rowDetail: record
    })
  }

  // 点击保存收货地址
  handleConfirmModifyAddress = (values) => {
    values.category = values.status === 'ENABLE' ? 2 : 0
    values.isDefault = values.status === 'ENABLE'
    values.receiver = values.name
    values.phoneNumber = values.mobile
    const { type = 1 } = this.state
    if (type === 1) {
      this.saveAddEndUserReceiveAddress(values)
    } else {
      this.saveEditEndUserReceiveAddress(values)
    }
  }

  // 点击添加新客户
  handleLinkToEndUserDetail = (record, type) => {
    this.props.history.push({ pathname: '/customer/edit', state: { detailInfo: record, type: type } })
  }

  // 展示客户名称
  renderCustomerName = (record = {}) => {
    let name = record.realName || ''

    if (+record.enterpriseId > 0 && record.euEnterprise) { // 企业用户
      name = `${record.euEnterprise.name}/${record.realName || ''}`
    }

    return name
  }

  // 倒计时关闭成功提示弹窗
  countDown = () => {
    let secondsToGo = 5
    const modal = Modal.success({
      title: '操作成功'
    })

    const timer = setInterval(() => {
      secondsToGo -= 1
    }, 1000)

    setTimeout(() => {
      clearInterval(timer)
      modal.destroy()
    }, secondsToGo * 1000)
  }

  closeModal = () => {
    this.setState({
      type: 1,
      addressModal: false,
      customerModal: false,
      rowDetail: {}
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { customerList = [], addressList = [], selectedCustomerDetail = {}, selectedAddressInfo = {}, type = 1, addressModal = false, rowDetail = {} } = this.state

    return (
      <>
        <Card title='单据信息' bodyStyle={{ padding: '16px' }} bordered={false}>
          <Form>
            <Row className={`${styles.formitemRow}`}>
              <FormItem style={{ display: 'none' }}>
                {
                  getFieldDecorator('distributorOrderNo', {

                  })(
                    <Input disabled autoComplete='off' />
                  )
                }
              </FormItem>
              <FormItem label={<label className={`${styles.required}`}>出库单号</label>} style={{ marginBottom: '8px' }}>
                {
                  this.props.form.getFieldValue('distributorOrderNo') !== undefined
                    ? <>{this.props.form.getFieldValue('distributorOrderNo')}</>
                    : null
                }
              </FormItem>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <div className={`${styles.formitemRow}`} style={{ display: 'inline-block', width: 'calc(100% - 120px)' }}>
                  <FormItem label='客户名称'>
                    {
                      getFieldDecorator('endUserId', {
                        rules: [
                          { required: true, message: '请选择客户' }
                        ]
                      })(
                      <Select showSearch optionFilterProp='children' onChange={(value) => this.handleSelectCustomer(value)}>
                        {
                          customerList.map((ctem, i) => (
                            <Option value={ctem.euId} key={ctem.euId}>{ this.renderCustomerName(ctem) }</Option>
                          ))
                        }
                      </Select>
                      )
                    }
                  </FormItem>
                </div>
                <div className={`${styles.formitemRow}`} style={{ display: 'inline-block', width: '120px' }}>
                  <FormItem>
                    <Button type='link' onClick={() => this.handleLinkToEndUserDetail({}, 'ADD')}>添加客户</Button>
                  </FormItem>
                </div>
              </Col>

              <div style={{ display: 'none' }}>
                <FormItem>
                  {
                    getFieldDecorator('customerName', {
                      initialValue: selectedCustomerDetail.company || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
                <FormItem style={{ display: 'none' }}>
                  {
                    getFieldDecorator('receiverName', {
                      initialValue: selectedAddressInfo.receiver || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
                <FormItem style={{ display: 'none' }}>
                  {
                    getFieldDecorator('receiverMobile', {
                      initialValue: selectedAddressInfo.phoneNumber || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
              </div>
              <Col span={8}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='收货人'>{ selectedAddressInfo.receiver }</FormItem>
                </div>
              </Col>
              <Col span={8}>
                <div className={`${styles.formitemRow}`}>
                  <FormItem label='联系方式'>{ selectedAddressInfo.phoneNumber }</FormItem>
                </div>
              </Col>
            </Row>

            <Row>
              <div className={`${styles.formitemRow}`} style={{ display: 'inline-block' }}>
                <FormItem label='收货地址' style={{ width: '420px' }}>
                  {
                    getFieldDecorator('receiverAddressId', {
                      rules: [
                        { required: true, message: '请选择收货地址' }
                      ]
                    })(
                        <Select onChange={(value) => this.handleChangeReceiver(value)}>
                          {
                            addressList.map((item, index) => (
                              <Option value={item.id} key={`addressItem-${item.id}`}>{renderAddressLabel(item.province, item.city, item.county)}{item.address}</Option>
                            ))
                          }
                        </Select>
                    )
                  }
                </FormItem>
              </div>

              <div className={`${styles.formitemRow}`} style={{ display: 'inline-block' }}>
                <FormItem >
                {
                  this.props.form.getFieldValue('endUserId') !== undefined
                    ? <Button type='link' onClick={() => this.handleShowModal({}, 1, 'addressModal')}>添加收货信息</Button>
                    : null
                }

                {
                  this.props.form.getFieldValue('receiverAddressId') !== undefined && JSON.stringify(selectedAddressInfo) !== '{}'
                    ? <Button type='link' onClick={() => this.handleShowModal(selectedAddressInfo, 2, 'addressModal')}>
                        修改收货信息
                      </Button>
                    : null
                }
                </FormItem>
              </div>

              <div style={{ display: 'none' }}>
                <FormItem>
                  {
                    getFieldDecorator('receiverProvince', {
                      initialValue: selectedAddressInfo.province || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
                <FormItem>
                  {
                    getFieldDecorator('receiverCity', {
                      initialValue: selectedAddressInfo.city || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
                <FormItem>
                  {
                    getFieldDecorator('receiverArea', {
                      initialValue: selectedAddressInfo.county || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
                <FormItem>
                  {
                    getFieldDecorator('receiverAddress', {
                      initialValue: selectedAddressInfo.address || ''
                    })(
                      <Input disabled autoComplete='off' />
                    )
                  }
                </FormItem>
              </div>
            </Row>
          </Form>
        </Card>

        {
          addressModal
            ? <AddressModal
              type={type}
              detailInfo={rowDetail}
              endUserId={this.props.form.getFieldValue('endUserId')}
              onConfirm={(values) => this.handleConfirmModifyAddress(values)}
              onCancel={() => this.closeModal()}
            />
            : null
        }
      </>
    )
  }
}

const SalesUserInfo = Form.create()(SalesUserForm)

export default SalesUserInfo

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { Layout, Breadcrumb } from 'antd'

import HeaderContent from '../header/index'
import SiderMenu from '../siderMenu/index'

import { routes } from '@/routes/index'
import styles from './index.module.scss'

const { Header, Content, Footer } = Layout

class BasicLayout extends Component {
  static propTypes = {
    history: PropTypes.object
  }

  render () {
    return (
      <>
        <Layout className={`${styles.basicLayout}`}>
          <SiderMenu history={this.props.history} />

          <Layout style={{ backgroundColor: '#FBFAFA' }}>
            <div className={`${styles.header}`} />
            <Header className={`${styles.fixedHeader}`}>
              <HeaderContent history={this.props.history} />
            </Header>
            <Content className={`${styles.layoutContent}`}>
              <div style={{ padding: '12px 2px', backgroundColor: '#FBFAFA' }}>
                <Breadcrumb>
                  <Breadcrumb.Item>首页</Breadcrumb.Item>
                  {
                    routes.map((route, i) => {
                      if (route.path && location.pathname.match(route.path)) {
                        return (<Breadcrumb.Item key={i}>{route.name}</Breadcrumb.Item>)
                      }
                      return false
                    })
                  }
                </Breadcrumb>
              </div>
              <Switch>
                {
                  routes.map((route, i) => (
                    <Route
                      exact
                      key={i}
                      name={route.name}
                      path={route.path}
                      render={props => (
                        <route.component {...props} />
                      )}
                    />
                  ))
                }
              </Switch>
            </Content>
            <Footer className={`${styles.basicLayoutFooter}`} />
          </Layout>
        </Layout>
      </>
    )
  }
}

export default BasicLayout

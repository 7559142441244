import React, { useState, useEffect } from 'react'
import { Modal, Icon } from 'antd'
import moment from 'moment'

import SearchForm from '@/components/tool-form/index'
import BillTable from '../billTable/index'
import BillDetails from '../billDetails/index'

import Api from '@/common/api/index'
import DownloadApi from '@/common/api/downloadApi'

import { billStatusMaps } from '@/common/utils/filter'
const { downIIASaaSDepponExpressBillByMonth } = DownloadApi
const searchData = [
  {
    name: 'dueMonth',
    formType: 'MonthPicker',
    itemParams: {
      label: '账单日期'
    },
    cptParams: {

    },
    options: []
  },
  {
    name: 'status',
    formType: 'Select',
    itemParams: {
      label: '账单状态'
    },
    rules: {
      initialValue: 'ALL'
    },
    cptParams: {
      placeholder: '请选择...'
    },
    options: [
      { id: 'ALL', name: '全部' },
      ...billStatusMaps
    ]
  }
]

function DepponExpressBill (props) {
  const [pages, setPages] = useState({ pageNo: 1, pageSize: 20, total: 0 })
  const [query, setQuery] = useState({})
  const [billdatas, setBilldatas] = useState([])
  const [detaildatas, setDetaildatas] = useState([])
  const [dueMonthList, setDueMonthList] = useState([])

  const { dueMonth, status } = query

  // 获取IIASaaS快递服务运费对账单
  const getTransportBilldatas = async () => {
    const res = await Api.getTransportBill({ ...query, pageNo: 1, pageSize: 999 })
    const { code, data = {} } = res
    if (+code === 10000) {
      const list = data.list || []
      const dueMonthList = []

      if (list.length > 0) {
        list.forEach((item) => {
          if (item.dueMonth) {
            dueMonthList.push(item.dueMonth)
          }
        })
      }

      setBilldatas(list)

      if (dueMonthList.length > 0) {
        setDueMonthList(dueMonthList)
        getTransportBillDetail()
      }
    }
  }

  // 获取运费流水
  const getTransportBillDetail = async () => {
    const res = await Api.getTransportBillDetail({ dueMonthList, pageNo: pages.pageNo, pageSize: pages.pageSize })
    const { code, data } = res
    if (+code === 10000) {
      setDetaildatas(data.list || [])
      setPages({ ...pages, total: data.total || 0 })
    }
  }

  // 点击搜索
  const handleSearch = (search = {}) => {
    search.dueMonth = search.dueMonth ? moment(search.dueMonth).format('YYYYMM') : undefined
    setQuery(search)
    setPages({ ...pages, pageNo: 1, total: 0 })
  }

  // 点击导出月度账单
  const handleDownloadBill = () => {
    if (!dueMonth || dueMonth === '') {
      Modal.warning({
        icon: <Icon type='exclamation-circle' style={{ color: '#FF3434' }} />,
        title: <span style={{ color: '#FF3434' }}>请选择您想导出的月份</span>,
        content: <span>请在搜索栏——账单日期中<br />选择您想导出的日期<br />然后点击搜索</span>
      })

      return false
    }

    downIIASaaSDepponExpressBillByMonth({ dueMonth: query.dueMonth })
  }

  // 翻页
  const pageChange = (page, size) => {
    setPages({ ...pages, pageNo: page })

    getTransportBillDetail()
  }

  // 改变页码
  const pageSizeChange = (current, size) => {
    setPages({ ...pages, pageNo: 1, pageSize: size })

    getTransportBillDetail()
  }

  useEffect(() => {
    getTransportBilldatas()
  }, [dueMonth, status])

  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ marginBottom: '20px' }}>
          <SearchForm dataSource={searchData}
            handleSubmit={(obj) => handleSearch(obj)}
          />
        </div>

        <BillTable tableData={billdatas} />
      </div>

      <BillDetails
        tableData={detaildatas}
        pages={pages}
        pageChange={(page, size) => pageChange(page, size)}
        pageSizeChange={(current, size) => pageSizeChange(current, size)}
        onDownload={() => handleDownloadBill()}
      />
    </>
  )
}

export default DepponExpressBill

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Input, Upload, Button, Icon, message } from 'antd'

import UploadThumbnail from '@/components/uploadThumbnail/index'

import UploadApi from '@/common/api/uploadApi'
import Api from '@/common/api/index'

import { getStore, formatBankAccount, renderAddressLabel } from '@/common/utils/mUtils'
import consts from '@/common/utils/consts'
import styles from './index.module.scss'

const { uploadOrdinaryImageUrl } = UploadApi
const { IMG_LT2M } = consts
const FormItem = Form.Item

function AccountBasicForm (props) {
  const [info, setMerchantBaseInfo] = useState({})
  const [mallConfigInfo, setMallConfigInfo] = useState({})
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form

  // 获取商户基本信息
  const getMerchantBaseInfo = async () => {
    const res = await Api.getMerchantBaseInfo({})
    const { code, data } = res
    if (+code === 10000) {
      setMerchantBaseInfo(data)
    }
  }

  // 获取商户商城配置信息
  const getMallConfig = async () => {
    const res = await Api.getMallConfig({})
    const { code, data = {} } = res
    if (+code === 10000) {
      setMallConfigInfo(data || {})
    }
  }

  // 保存配置
  const saveMallConfig = async (params) => {
    const res = await Api.saveMallConfig(params)
    const { code } = res
    if (+code === 10000) {
      message.success('操作成功')
      getMallConfig()
    }
  }

  // 点击保存
  const handleSubmit = () => {
    const url = getFieldValue('commonSealImageUrl')

    if (url === undefined || url === '') {
      return message.warning('请上传公司合同章！')
    }

    const params = {
      commonSealImageUrl: url,
      name: 'IIASaaS采购商',
      textAdvertisement: 'IIASaaS采购商',
      customerPhoneNumber: '15713935339',
      logoImageUrl: url,
      contacts: []
    }

    saveMallConfig(params)
  }

  // 上传前的检验
  const beforeUpload = (file) => {
    const isLt1M = file.size / 1024 / 1024 < IMG_LT2M
    if (!isLt1M) {
      message.warning(`图片大小不可超过 ${IMG_LT2M}MB！`)
    }

    return isLt1M
  }

  // 上传图片
  const handleChangeUploadImage = (info) => {
    if (info.file.status === 'done') {
      const { code, data } = info.file.response
      if (+code === 10000) {
        setTimeout(() => {
          setFieldsValue({ commonSealImageUrl: data.imageUrl })
        }, 20)
      } else {
        message.error(info.file.response.message)
      }
    }
  }

  // 删除图片
  const handleRemoveImage = () => {
    setTimeout(() => {
      setFieldsValue({ commonSealImageUrl: undefined })
    }, 20)
  }

  useEffect(() => {
    getMerchantBaseInfo()
    getMallConfig()
  }, [])

  return (
    <>
      <Form>
        <Row>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='公司名称' style={{ marginBottom: '0px' }}>{ info.merchantName }</FormItem>
          </Col>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='开票电话' style={{ marginBottom: '0px' }}>{ info.mobile }</FormItem>
          </Col>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='统一社会信用代码' style={{ marginBottom: '0px' }}>{ info.merchantCreditNo }</FormItem>
          </Col>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='开户银行' style={{ marginBottom: '0px' }}>{ info.accountBank }</FormItem>
          </Col>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='地区' style={{ marginBottom: '0px' }}>{ renderAddressLabel(info.province, info.city, info.county) }</FormItem>
          </Col>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='银行账号' style={{ marginBottom: '0px' }}>{ info.account ? formatBankAccount(info.account) : null }</FormItem>
          </Col>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='注册地址' style={{ marginBottom: '0px' }}>{ info.address }</FormItem>
          </Col>
          <Col span={12} className={`${styles.bottomBordered}`}>
            <FormItem label='商户权限' style={{ marginBottom: '0px' }}>采购商</FormItem>
          </Col>
          <Col span={12}>
            <FormItem label='营业执照电子版'>
              <a className={`${styles.imgBox}`} href={info.businessLicenseUrl} target='_blank' rel="noopener noreferer noreferrer">
                <img className={`${styles.pic}`} src={info.businessLicenseUrl} />
              </a>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label='公司合同章' style={{ display: 'none' }}>
              {
                getFieldDecorator('commonSealImageUrl', {
                  initialValue: mallConfigInfo.commonSealImageUrl
                })(<Input disabled autoComplete='off' />)
              }
            </FormItem>

            <FormItem label={<span className={'required'}>公司合同章</span>}>
              {
                getFieldValue('commonSealImageUrl') !== undefined && getFieldValue('commonSealImageUrl') !== ''
                  ? <UploadThumbnail
                      imageUrl={getFieldValue('commonSealImageUrl')}
                      onDelete={() => handleRemoveImage()}
                    />
                  : <Upload showUploadList={false}
                      listType='picture-card'
                      accept='image/png, image/jpg, image/jpeg'
                      headers={{ Token: getStore('token') }}
                      action={uploadOrdinaryImageUrl}
                      beforeUpload={(file) => beforeUpload(file)}
                      onChange={(info) => handleChangeUploadImage(info)}
                    >
                      <Icon type='plus' />
                  </Upload>
              }
            </FormItem>
          </Col>
        </Row>
        <div className={`${styles.btns}`}>
          <Button type='primary' onClick={() => handleSubmit()}>保存</Button>
        </div>
      </Form>
    </>
  )
}

AccountBasicForm.propTypes = {
  form: PropTypes.object
}

const AccountBasic = Form.create()(AccountBasicForm)

export default AccountBasic

console.log(PATH_ENV)

const BASEURL = {
  prod: 'https://manage.ebuy.china-iia.com',
  test: 'http://manage-ebuy.iiasaas.cn:8080',
  dev: 'http://manage-ebuy.iiasaas.cn:8080'
}

// 测试环境域名：
// 测试环境穿透域名：ebuy.nanjingsaas.cn:8080

// 测试api BASEURL: http://manage.test-iia-ebuy.com
// 测试穿透api BASEURL: http://manage-ebuy.iiasaas.cn:8080

// websoket链接
const SOCKETWS = {
  prod: 'ws://ws.iiasaas.com:7999',
  test: 'ws://192.168.1.11:7999',
  dev: 'ws://192.168.1.11:7999'
}

const host = BASEURL[PATH_ENV]
const socketWs = SOCKETWS[PATH_ENV]

export default {
  host,
  socketWs
}

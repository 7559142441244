import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Icon, Popover, Checkbox, Button, InputNumber, Modal, message, Descriptions } from 'antd'
import Swiper from 'swiper/dist/js/swiper.min.js'
import $lodash from 'lodash'

import PreviewSwiper from './previewSwiper'
import placeImg from '@/assets/images/place.jpg'

import Api from '@/common/api/index'
import { skuStopFlagMaps } from '@/common/utils/filter'
import { getUrlParams, renderSkuGoodsTypeLabel } from '@/common/utils/mUtils'

import styles from './detail.module.scss'
import 'swiper/dist/css/swiper.min.css'

class CloudproductDetail extends Component {
  static propTypes = {

  }

  state = {
    query: {},
    info: {},
    attrList: [],
    supplyInfoList: [],
    productImageList: [],
    detailImageList: [],
    detailPdfList: [], // PDF列表
    curimgIndex: 0,
    curtabkey: 1, // 当前选择的tab
    popover: true, // 提示弹窗
    fail: []
  }

  componentDidMount () {
    const _query = getUrlParams()
    this.setState({
      query: _query
    }, () => {
      this.getCloudProductDetail()
    })
  }

  // 初始化swiper
  initSwiper = () => {
    /* eslint-disable */
    let galleryThumbs = new Swiper('#gallertThumbs', {
      slidesPerView: 5,
      spaceBetween: 6,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    /* eslint-enable */
  }

  // 获取产品详情
  getCloudProductDetail = () => {
    const { query } = this.state

    Api.getCloudProductDetail({ ...query }).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        let productImageList = []
        let detailPdfList = []
        let detailImageList = []
        let supplyInfoList = []

        if (data) {
          productImageList = data.listImages || []
          detailPdfList = data.detailsPdfs || []
          detailImageList = data.detailsImages || []
          supplyInfoList = data.supplyInfoList || []
        }

        this.setState({
          info: data || {},
          attrList: data.attributeList || [],
          productImageList: productImageList,
          detailImageList: detailImageList,
          detailPdfList: detailPdfList,
          supplyInfoList: supplyInfoList
        })
      }
    })
  }

  // 点击产品缩略图
  handleSelectedImg = (i) => {
    this.setState({
      curimgIndex: i
    })
  }

  // 点击切换tab
  handleChangeTabs = (key) => {
    this.setState({
      curtabkey: +key
    })
  }

  closePopover = () => {
    this.setState({
      popover: false
    })
  }

  // 选择产品
  handleGoodsCk = (e, i) => {
    const { supplyInfoList } = this.state
    supplyInfoList[i].checked = e.target.checked
    supplyInfoList[i].quantity = 1
    this.setState({
      supplyInfoList
    })
  }

  // 选择数量
  handleGetNum= (e, i) => {
    const { supplyInfoList } = this.state
    supplyInfoList[i].quantity = e
    this.setState({
      supplyInfoList
    })
  }

  // 加入购物车·
  handleAddCarts = () => {
    const { supplyInfoList, info = {} } = this.state
    const newArr = $lodash.filter(supplyInfoList, item => { return item.checked === true })

    if (newArr.length === 0) {
      Modal.warning({
        title: '至少选择一个'
      })

      return false
    }

    const fail = []

    supplyInfoList.forEach(item => {
      if (['SPOT', 'CLOUD_SPOT'].includes(info.skuGoodsType) && item.quantity > item.stock && item.checked === true) {
        fail.push(item.price)
      }
    })

    if (fail.length > 0) {
      Modal.warning({
        title: this.Getfailprice(fail)
      })

      return false
    }

    const getList = []
    supplyInfoList.forEach(item => {
      if (item.checked === true) {
        const obj = {
          commoditySkuId: info.commoditySkuId,
          goodsType: info.skuGoodsType,
          supplierId: item.supplierId,
          quantity: item.quantity
        }
        getList.push(obj)
      }
    })

    if (getList.length > 0) {
      this.saveAddToCart({ cartList: getList })
    }
  }

  // 渲染失败详情
  Getfailprice = (fail) => {
    return (
      <div>
        {`单价为${fail.join('和')}的库存不足`}
      </div>
    )
  }

  // 添加到购物车
  saveAddToCart = (param) => {
    Api.saveAddToCart(param).then(res => {
      const { code } = res
      if (+code === 10000) {
        Modal.success({
          title: '加入购物车成功~'
        })
      } else {
        message.warning(res.message)
      }
    })
  }

  renderSkuStopFlagLabel = (status) => {
    const obj = skuStopFlagMaps.find((item) => { return item.id === status })
    const label = obj !== undefined ? obj.name : null

    return label
  }

  render () {
    const { info = {}, productImageList = [], detailImageList = [], detailPdfList = [], curtabkey = 1, popover = true, supplyInfoList = [] } = this.state
    let pdfUrl = ''

    if (detailPdfList.length > 0) {
      pdfUrl = detailPdfList[0] || ''
    }

    return (
      <>
        <div className={`${styles.w1200}`}>
          {/* 产品基本信息 */}
          <div className={`${styles.floor} ${styles.productIntro}`}>
            {
              productImageList && productImageList.length > 0
                ? <PreviewSwiper list={productImageList} />
                : <img src={placeImg} width={300} />
            }

            <div className={`${styles.infoZoom}`}>
              <div className={`${styles.name}`} style={{ marginBottom: '10px' }}>
                <div className={styles.branchsty}>{ info.englishBrandName && info.englishBrandName !== '' ? `${info.brandName}/${info.englishBrandName}` : info.brandName }</div>{info.name}
              </div>
              <div className={`${styles.chooseWrap}`}>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                    订<em className={styles.hiddlen}>货</em>货<em className={styles.hiddlen}>货</em>号
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    { info.itemNumber ? info.itemNumber : '---' }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                    型<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>号
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    { info.model ? info.model : '----' }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                    品<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>牌
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    { info.brandName }{ info.englishBrandName && info.englishBrandName !== '' ? `/${info.englishBrandName}` : null }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                    系<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>列
                  </div>
                  <div className={`${styles.chooseValue}`}>
                    { info.series }
                  </div>
                </div>

                <div className={`${styles.chooseItem} clearfix`}>
                  <div className={`${styles.chooseLabel}`}>
                   类<em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em><em className={styles.hiddlen}>货</em>型
                  </div>
                  {
                    info.skuGoodsType
                      ? <span className={`${styles.branchsty} ${['FUTURES', 'CLOUD_FUTURES'].includes(info.skuGoodsType) ? styles.futuresTag : ''}`}>
                        { renderSkuGoodsTypeLabel(info.skuGoodsType) }
                      </span>
                      : '----'
                  }
                </div>

                <div className={styles.productprice}>
                  {
                    supplyInfoList && supplyInfoList.length > 0
                      ? <>
                        {
                          supplyInfoList.map((item, i) => (
                            <div key={i} className={styles.Pul}>
                              <span><Checkbox checked={item.checked} onChange={(e) => { this.handleGoodsCk(e, i) }} /></span>
                              <span className={styles.Pprice}>{+item.price}</span>
                              <span className={styles.PInfo}>
                                { <span className={`${styles.branchsty} ${['FUTURES', 'CLOUD_FUTURES'].includes(info.skuGoodsType) ? styles.futuresTag : ''}`}>{ renderSkuGoodsTypeLabel(info.skuGoodsType) }</span> }
                                {
                                  ['FUTURES', 'CLOUD_FUTURES'].includes(info.skuGoodsType)
                                    ? <span className={styles.Pnum}>货期:{ item.deliveryPeriodDays }工作日</span>
                                    : <span className={styles.Pnum}>库存:{ item.stock }</span>
                                }
                              </span>

                              <span className={styles.GoodsInber}>
                                {
                                  item.checked
                                    ? <InputNumber onChange={(e) => { this.handleGetNum(e, i) }} value={item.quantity} size='small' min={0} />
                                    : null
                                }
                              </span>
                              <span>
                                {
                                  item.checked
                                    ? <>&yen;{(item.quantity * item.price).toFixed(2)}</>
                                    : null
                                }
                              </span>
                              <span className={styles.lastli}><span>最小订货量: {item.minOrder}{item.unit}</span></span>
                            </div>
                          ))
                        }
                      </>
                      : null
                  }
                </div>

                <div>
                  <Button type='primary' onClick={() => { this.handleAddCarts() }}>加入购物车</Button>
                </div>
              </div>
            </div>
          </div>

          {/* 产品详情图片&PDF */
            <div className={`${styles.floor}`}>
              <div style={{ height: '100%' }}>
                <div className={`${styles.tabBar}`}>
                  <div className={`${styles.tabsNav}`}>
                    <div className={`${styles.tabItem} ${+curtabkey === 1 ? styles.tabActive : ''}`} onClick={() => this.handleChangeTabs('1')}>商品介绍</div>
                      {
                        detailPdfList.length > 0
                          ? <Popover content={<div className={`${styles.popoverContent}`}>
                                <span style={{ marginRight: '10px' }}>点击PDF，查看更多信息</span>
                                <a className={`${styles.popoverClose}`} onClick={() => this.closePopover()}><Icon type='close-circle' /></a>
                              </div>}
                              visible={popover}
                            >
                              <div className={`${styles.tabItem} ${+curtabkey === 2 ? styles.tabActive : ''}`} onClick={() => this.handleChangeTabs('2')}>PDF</div>
                            </Popover>
                          : null
                      }
                    </div>
                  </div>

                  {
                    +curtabkey === 1
                      ? <div className={`${styles.detailWrap}`}>
                          <div style={{ margin: '0px 20px', padding: '16px 0px', borderBottom: '1px solid #E8E8E8' }}>
                            <Descriptions>
                              <Descriptions.Item label='品牌'>{ info.brandName }</Descriptions.Item>
                              <Descriptions.Item label='系列'>{ info.series }</Descriptions.Item>
                              <Descriptions.Item label='型号'>{ info.model }</Descriptions.Item>
                              <Descriptions.Item label='订货号'>{ info.itemNumber }</Descriptions.Item>
                              <Descriptions.Item label='产品名称'>{ info.name }</Descriptions.Item>
                              <Descriptions.Item label='最小包装'>{ info.minPackQuantity }</Descriptions.Item>
                              <Descriptions.Item label='最小订货量'>{ info.minOrder }</Descriptions.Item>
                              <Descriptions.Item label='单位'>{ info.unit }</Descriptions.Item>
                              <Descriptions.Item label='是否停产'>{ this.renderSkuStopFlagLabel(info.stopFlag) }</Descriptions.Item>
                              <Descriptions.Item label='商品描述'>{ info.introduce }</Descriptions.Item>
                            </Descriptions>
                          </div>

                        {
                          detailImageList.length > 0
                            ? <ul className={`${styles.picList}`}>
                              {
                                detailImageList.map((item, i) => (
                                  <li className={`${styles.picItem}`} key={`detailImg-${i}`}>
                                    <img className={`${styles.detailimg}`} src={item} />
                                  </li>
                                ))
                              }
                            </ul>
                            : null
                        }
                      </div>
                      : <>
                        {detailPdfList && detailPdfList.length > 0
                          ? <div style={{ height: '100%' }}>
                            <iframe src={pdfUrl} width='100%' height='600px' />
                          </div>
                          : null
                        }
                      </>

                  }
                </div>
              </div>
          }
        </div>
      </ >
    )
  }
}

export default withRouter(CloudproductDetail)

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Menu, Dropdown, Icon } from 'antd'

import Api from '@/common/api/index'
import { setUserInfo } from '@/reducers/action'
import { removeStore } from '@/common/utils/mUtils'
import LOGO_PRIMARY from '@/assets/images/logo/logo_primary.png'
import styles from './index.module.scss'

function BasicHeader (props) {
  const userInfo = useSelector(state => state.userInfo)
  const dispatch = useDispatch()

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={{ pathname: '/account/basic' }}><Icon type="user" /><span style={{ marginLeft: '8px' }}>个人中心</span></Link>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => handleLoginOut()}><Icon type="logout" /><span style={{ marginLeft: '8px' }}>退出登录</span></a>
      </Menu.Item>
    </Menu>
  )

  // 获取登录用户信息
  const getLoginUserInfo = () => {
    Api.getLoginUserInfo({}).then(res => {
      const { code, data } = res
      if (+code === 10000) {
        dispatch(setUserInfo({ userInfo: data || {} }))
      }
    })
  }

  // 退出登录
  const handleLoginOut = () => {
    dispatch(setUserInfo({ userInfo: {} }))
    removeStore('token')
    props.history.push({ pathname: '/login' })
  }

  useEffect(() => {
    getLoginUserInfo()
  }, [])

  return (
    <>
      <div className={`${styles.globalHeader}`}>
        <img className={`${styles.logo}`} width={180} src={LOGO_PRIMARY} />
        <div style={{ flex: '1 1 0%', height: '100%' }} />
        <div className={`${styles.spaceContent}`}>
          <Dropdown overlay={menu}>
            <a className={`${styles.spaceItem}`}>
              <span>{ userInfo && userInfo.username }</span><Icon type="down" style={{ marginLeft: '6px' }} />
            </a>
          </Dropdown>
        </div>
      </div>
    </>
  )
}

BasicHeader.propTypes = {
  history: PropTypes.object
}

export default BasicHeader

import React from 'react'
import PropTypes from 'prop-types'

import { hideMobile } from '@/common/utils/mUtils'
import styles from './index.module.scss'

function AccountView (props) {
  const { userInfo = {}, onModifyPassword } = props

  return (
    <ul className={`${styles.accountList}`}>
      <li className={`${styles.listItem}`}>
        <div className={`${styles.listItemMeta}`}>
          <h4 className={`${styles.listItemTitle}`}>登录名</h4>
          <div className={`${styles.listItemdescription}`}>{ userInfo.username }</div>
        </div>
        <div className={`${styles.listItemAction}`} />
      </li>
      <li className={`${styles.listItem}`}>
        <div className={`${styles.listItemMeta}`}>
          <h4 className={`${styles.listItemTitle}`}>已绑定手机号</h4>
          <div className={`${styles.listItemdescription}`}>{ userInfo.loginUserPhone && userInfo.loginUserPhone !== '' ? hideMobile(userInfo.loginUserPhone) : null}</div>
        </div>
        <div className={`${styles.listItemAction}`} />
      </li>
      <li className={`${styles.listItem}`}>
        <div className={`${styles.listItemMeta}`}>
          <h4 className={`${styles.listItemTitle}`}>登录密码</h4>
          <div className={`${styles.listItemdescription}`}>******</div>
        </div>
        <div className={`${styles.listItemAction}`}>
          <a className={`${styles.btn}`} onClick={() => onModifyPassword()}>修改密码</a>
        </div>
      </li>
    </ul>
  )
}

AccountView.propTypes = {
  userInfo: PropTypes.object,
  onModifyPassword: PropTypes.func
}

export default AccountView
